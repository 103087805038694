import axiosInstance from "../../Services/axiosCommon";

const logout = () => {
  let userData = JSON.parse(localStorage.getItem("userDetails"))
  let id = userData?.id;
  console.log("");

  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }

  var datetime =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    now.getHours() +
    ":" +
    now.getMinutes() +
    ":" +
    now.getSeconds();

  let value = {
    datetime: datetime,
    userId: id,
  };

  axiosInstance.post(`Auth/logout`, value).then(res=>{
    if(res.data.status==true){
      window.localStorage.removeItem("NeKoTrEsUoRpNu");
      window.localStorage.removeItem("SlIaTeDrEsUoRpNu");
      window.localStorage.removeItem("SsEcCaReSuOrPnU");
      window.location.replace("/auth/login");
    }
  }).catch(err=>{
    console.log(err);
  })

  };
  
  export default logout;
  