import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import ProjectReportMain from '../Pages/Web/Reports/Project/ProjectReportMain'
import DailyProgressReport from '../Pages/Web/Reports/Project/DailyProgressReport'
import { Wrapper } from '../Components/Utils/roles'

const ReportsMain =lazy(()=>import('../Pages/Web/Reports/Main')) 
const ReportIndex =lazy(()=>import('../Pages/Web/Reports/')) 
const ProjectReport =lazy(()=>import('../Pages/Web/Reports/Project')) 
const TaskReport =lazy(()=>import('../Pages/Web/Reports/Tasks')) 
const ClientsReport =lazy(()=>import('../Pages/Web/Reports/Clients')) 
const MembersReport =lazy(()=>import('../Pages/Web/Reports/Members')) 

function ReportsRoutes() {
  return (
    <Route path="reports" element={<ReportsMain/>}>
    <Route index element={< Wrapper accessKey={true}  Component={ReportIndex} />} />
    <Route path="projects" element={< Wrapper accessKey={true}  Component={ProjectReport} />}/>
    <Route path="tasks" element={< Wrapper accessKey={true}  Component={TaskReport} />}/>
    <Route path="clients" element={< Wrapper accessKey={true}  Component={ClientsReport} />}/>
    <Route path="members" element={< Wrapper accessKey={true}  Component={MembersReport} />}/>
    <Route path='project' element={< Wrapper accessKey={true}  Component={ProjectReportMain} />}>
      <Route path="daily-progress-report" element={< Wrapper accessKey={true}  Component={DailyProgressReport} />}/> 
    </Route>
  </Route>
  )
}

export default ReportsRoutes