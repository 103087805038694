// NumberContext.js
import React, { createContext, useContext, useState } from 'react';

const NumberContext = createContext();

export const NumberProvider = ({ children }) => {
  const [number, setNumber] = useState(0);
  const [estimationId, setEstimationId] = useState(0)

  const updateNumber = (newNumber) => {
    setNumber(newNumber);
  };

  const updateEstimation = (newEstimationId) =>{
    setEstimationId(newEstimationId)
  }

  return (
    <NumberContext.Provider value={{ number, updateNumber,estimationId,updateEstimation }}>
      {children}
    </NumberContext.Provider>
  );
};

export const useNumber = () => {
  const context = useContext(NumberContext);
  if (!context) {
    throw new Error('useNumber must be used within a NumberProvider');
  } 
  return context;
};
