import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import App from "./App";
import PrivateRoute from "./Services/PrivateRouter";
import ProjectRoutes from "./Routes/project.routes";
import TeamRoutes from "./Routes/team.routes";
// import TaskplanRoutes from "./Routes/taskplan.routes";
import TaskRoutes from "./Routes/task.routes";
import AuthRoutes from "./Routes/auth.routes";
import ReportsRoutes from "./Routes/reports.routes";
import Notifications from "./Pages/Mobile/Notifications/Notifications";
import UserDetailsRoutes from "./Routes/userDetails.routes";
import SettingsRoutes from "./Routes/settings.routes";
import NoteRoutes from "./Routes/note.routes";
// import TaskScheduleRoutes from "./Routes/taskSchedule.routes";
import MainChat from "./Pages/chat/chatMain";
import ChatIndex from "./Pages/chat";
import ChatProfile from "./Pages/chat/chatProfile";
import ContactsRoutes from "./Routes/contacts.routes";
import ProgressRoutes from "./Routes/progress.routes";
import CostingRoutes from "./Routes/costing.routes";
import PaymentsRoutes from "./Routes/payments.routes";
import PageNotFound from "./Components/Utils/PageNotFound";
import BiddingRoutes from "./Routes/bidding.routes";
import PettyCashRoutes from "./Routes/pettycash.routes";
import TimeRoutes from "./Routes/timesheet.routes";
import ItemRoutes from "./Routes/item.routes";
import { GetAccess, GetRole, RendorPage, Wrapper } from "./Components/Utils/roles";

const MainDashbord = lazy(() => import("./Pages/Dashboard/DashboardMain"));
const Dasahboard = lazy(() => import("./Pages/Dashboard"));
const SingleProject = lazy(() =>
  import("./Pages/Dashboard/Admin/SingleProject")
);

const Testroutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "") {
      window.location.replace("/dashboard");
    }
    if (location.pathname === "/auth") {
      window.location.replace("/auth/login");
    }
    if (location.pathname === "/accounts") {
      navigate("/accounts/income");
    }
  }, [location]);
  return (
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
                {/* <Suspense fallback={<div>Loading...</div>}> */}
                <App />
                {/* </Suspense> */}
            </PrivateRoute>
          }
        >
         <Route path="notifications" element={<Notifications/>} />
          <Route path="dashboard" element={
          <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
            <MainDashbord />
          </Suspense>}>
            <Route index element={
            <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
                <Dasahboard />
            </Suspense>
            } />
            <Route path=":proDashboardId" element={
                        <Suspense fallback={<div className=" w-full h-full flex justify-center items-center">Loading...</div>}>
                        <SingleProject />
                    </Suspense>
            } />
          </Route>
          {TeamRoutes()}
          {ItemRoutes()}
          {ProjectRoutes()}
          {ContactsRoutes()}
          {NoteRoutes()}
          {TaskRoutes()}
          {ReportsRoutes()}
          {UserDetailsRoutes()}
          {SettingsRoutes()}
          {ProgressRoutes()}
          {CostingRoutes()}
          {PaymentsRoutes()}
          {BiddingRoutes()}
          {PettyCashRoutes()}
          {TimeRoutes()}
          <Route path="chat" element={<MainChat/>}>
           <Route path="view" element={<ChatIndex />} >
           <Route path="chatProfile/:id" element={<ChatProfile />} />
          </Route>
        </Route>
        </Route>
        {AuthRoutes()}
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
  );
};

export default Testroutes;
