import React, { useState } from 'react';
import ExpandableTask from './TaskData';
import ToggleDown from '../../../../Components/Icons/ToggleDown';
import ToggleUp from '../../../../Components/Icons/ToggleUp';
import ViewMoreTitle from './viewMoreTitle';
import Plus from '../../../../Components/Icons/Plus';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';
import NuDate from '../../../../Components/Utils/NuDate';
import { GetAccess } from '../../../../Components/Utils/roles';

const ExpandableTitle = ({ rowData,getData,projectId,projectList,taskList,taskSelect,titleList,titleSelect,subTaskList,subTaskSelect,StartDate,EndDate }) => {
  const [isExpanded, setIsExpanded] = useState(JSON.parse(sessionStorage.getItem(`${projectId}-title-${rowData?.titleId}`))||false);
  console.log("Row Data",rowData);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    sessionStorage.setItem(`${projectId}-title-${rowData?.titleId}`,!isExpanded);
  };

  return (
    <>
      <tr className=' sticky top-[32px] w-[100%] z-[49]  bg-[#EFF6FF]' style={{borderTop:'2px solid white',borderBottom:'2px solid white'}}>
        <td className=' w-[3%] py-2 pl-1 text-center '>
          <div className=' w-full flex'>
          <input 
            type="checkbox" 
            checked={titleList.some(id=>id===rowData?.titleId)}
            onChange={()=>titleSelect(rowData?.titleId)}
            />
        { 
        rowData?.taskDetails?.length>0 && <div className='  w-3 h-7 rounded-md cursor-pointer  ' onClick={handleToggle}>{isExpanded ?<ToggleUp/>:<ToggleDown/>}</div>
        }
        
          </div>
        </td>
        <td colspan="8" className=' w-full py-1  text-xs font-medium text-mainColor-400'>
          <div className=' w-full flex gap-2 justify-between items-center '>
           <div className=' w-[55%] flex gap-2 items-center '>
           {rowData.title_name||"-"}
           <div className=" w-[13px] h-[13px] flex justify-center items-center">
           </div>
           </div>

          <div className='  w-[45%] ml-6 flex flex-col text-left gap-1 font-normal'>
            <p className='  text-[11px] text-red-400 '> Sch (Start : {rowData?.startDate && rowData?.startDate !="None" &&<NuDate value={rowData?.startDate} format="DD-MMM-YYYY"/>} | Due : {rowData?.endDate && rowData?.endDate !="None" && <NuDate value={rowData?.endDate} format="DD-MMM-YYYY"/>} | Total : {rowData?.totalDays||0} {rowData?.totalDays&& rowData?.totalDays>1?"days":"day"})</p>
            <p className='  text-[11px] text-red-400 '> Task (Total : {rowData?.totalTasks||0} | Open : {rowData?.taskOpened||0} | Close : {rowData?.taskClosed||0})</p>
          </div>
          </div>
        </td>
                <td className=' w-[7%] text-left pl-4 py-2'>
          <div className=' w-full flex justify-center  items-center'>
          <Progress
              type="circle"
              size={30}
              strokeWidth={12}
              format={(percent) => (
                <p
                  className={` ${
                    rowData?.titlePercentages?.percentage > 95
                      ? "text-[#87d068]"
                      : rowData?.titlePercentages?.percentage > 50
                      ? "text-[#108ee9]"
                      : "text-[#D2BD00]"
                  } text-xs font-semibold`}
                >
                  {percent}
                </p>
              )}
              percent={Math.floor(rowData?.titlePercentages?.percentage) || 0}
              strokeColor={
                rowData?.titlePercentages?.percentage > 95
                  ? {
                      "0%": "#87d068",
                      "100%": "#87d068",
                    }
                  : rowData?.titlePercentages?.percentage > 50
                  ? {
                      "0%": "#108ee9",
                      "100%": "#108ee9",
                    }
                  : {
                      "0%": "#D2BD00",
                      "100%": "#D2BD00",
                    }
              }
            />
          <div className=" w-[13px] h-[13px] flex justify-center items-center"></div>
          </div>
        </td>
        <td className=' w-[5%] text-center px-3  py-0'>
          <div className='w-full h-full flex items-center gap-2 '>
            <div className=' w-[48%] h-10 flex items-center'>
            <ViewMoreTitle getTitle={getData} projectId={projectId} data={rowData} />
            </div>
            <div className=' w-[48%] h-10 flex items-center'>
            {GetAccess("activities_create") && <Link to="/tasks/create" state={{data:rowData,Start:StartDate,End:EndDate}} ><Plus color='#3267FF' /></Link>}
            </div>
          </div>
            
        </td >
       
      </tr>
              {isExpanded && rowData?.taskDetails.map((item,i)=>(
                <ExpandableTask taskData={item||""} key={i} getData={getData} projectId={projectId} taskList={taskList} taskSelect={taskSelect} subTaskList={subTaskList} subTaskSelect={subTaskSelect} />

              ))}
      </>
  );
};

export default ExpandableTitle;

