import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import {
  NuDatePicker,
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuSelect,
  NuTextArea,
  NuTimePicker,
} from "../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
// import Attachement from "./Attachement";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import { Button, Modal, notification } from "antd";
import Attachements from "./Attachements";
import DeleteIcon from "../../../../Components/Icons/Delete";
import ViewMore from "../../../../Components/Icons/ViewMore";
import { ExclamationCircleFilled } from "@ant-design/icons";
import NuAmount from "../../../../Components/Utils/NuAmount";
const filetype = [
  "docx",
  "doc",
  "xlsx",
  "xls",
  "pdf",
  "txt",
  "pptx",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "flv",
  "mkv",
];

// export const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];

const { confirm } = Modal;

export default function CreateEstimation({ data }) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [addActivityOpen, setAddActivityOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [status, setStatus] = useState([]);
  const [createNew, setCreateNew] = useState({
    projectName: "",
    projectValue: "",
    clientId: "",
    referenceNo: "",
    submissionDueDate: "",
    submittedOn: "",
    submittedBy: null,
    submittedTo: null,
    acknowledgement: "",
    biddingStartDate: "",
    comments: "",

    materialDiscription: "",
    materialunit: "",
    materialQuantity: "",
    materialUnitPrice: "",
    materialTotal: "",
    materialTotalAmount: 0,

    manPowerActivity: "",
    manPowerNoOfDay: "",
    manPowerTotalAmount: "",
    manPowerUser: "",
    manPowerjobRole: "",
    manPowerunit: "",
    manPowerqty: "",
    manPowerdays: "",
    manPowerhours: "",
    manPoweramount: "",
    manPowerJobRoleTotalAmount: "",
    manPowerJobRoleTotalDays: "",
    manPowerJobRoleTotalHours: "",

    profitMarginMaterialPercentage: "",
    profitMarginMaterialAmount: "",
    profitMarginMaterialTotal: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList, setFilePathList] = useState([]);
  const [removefilePathList, setRemoveFilePathList] = useState([]);
  const [proClient, setProClient] = useState([]);

  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = [];
    files.map((item) => {
      if (item.size < maxFileSize) {
        fileArr.push(item);
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    });
    let tempArr = [];
    setSelectedFile(fileArr);
    files.map((item) => {
      if (item.size < maxFileSize) {
        let fileName = item?.name;
        let fileExtention = fileName.split(".").reverse()[0];
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName);
        } else {
          let x = URL.createObjectURL(item);
          tempArr.push(x);
        }
      }
    });
    setImage(tempArr);
  }

  const getClients = async () => {
    await axios
      .get("/DropDown/client")
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = { value: item.clientId, label: item.companyName };
          FinalData.push(tempObject);
        });
        response.data?.data && setProClient(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getStatus();
    getClients();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
      projectName: yup.string().required("Project Name is Required"),
      // clientId: yup.string().required("Client "),
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("details", JSON.stringify(value));
      // formData.append("attachments", image.raw);
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }

      data
        ? axios
            .put(`/bidding/update_bidding/${data?.biddingId}`, formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage({ preview: "", raw: "" });
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/bidding/create_bidding", formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage([]);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });
  const [materialList, setMaterialList] = useState([]);

  const [activityList, setActivityList] = useState([]);

  const [tempListofJobRole, setTempListofJobRole] = useState({});

  const [jobRoleList, setJobRoleList] = useState([]);

  const percentageOptions = Array.from({ length: 100 }, (v, i) => ({
    value: i + 1,
    label: `${i + 1}%`,
  }));

  useMemo(() => {
    if (formik.values.materialQuantity && formik.values.materialUnitPrice) {
      formik.values.materialTotal =
        formik.values.materialQuantity * formik.values.materialUnitPrice;
    } else {
      formik.values.materialTotal = " ";
    }
  }, [formik.values.materialQuantity, formik.values.materialUnitPrice]);

  function AddMaterial() {
    if (
      formik.values.materialDiscription &&
      formik.values.materialQuantity &&
      formik.values.materialUnitPrice
    ) {
      let tempValue = {
        description: formik.values.materialDiscription || "",
        unit: formik.values.materialunit || "",
        qty: formik.values.materialQuantity || "",
        unit_price: formik.values.materialUnitPrice || "",
        total_price: formik.values.materialTotal || "",
      };
      formik.values.materialTotalAmount =
        formik.values.materialTotalAmount + formik.values.materialTotal;
      setMaterialList([...materialList, tempValue]);
      formik.values.materialDiscription = "";
      formik.values.materialQuantity = "";
      formik.values.materialunit = "";
      formik.values.materialUnitPrice = "";
      formik.values.materialTotal = "";
    } else {
      api.error({
        message: `Unsuccessfully`,
        description: "Fill all Feilds",
        placement: "bottomRight",
      });
    }
  }

  async function handleRemoveMaterialItem(value, amount) {
    confirm({
      title: `Do you want to Delete ${value}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: ``,
      onOk() {
        if (materialList.some((material) => material.description === value)) {
          formik.values.materialTotalAmount =
            formik.values.materialTotalAmount - amount;
          let arrayValue = materialList.filter(
            (item) => item.description !== value
          );
          setMaterialList(arrayValue);
        }
      },
      onCancel() {},
    });
  }

  function addManPower() {
    if (formik.values.manPowerActivity) {
      let tempdata = {
        activity: formik.values.manPowerActivity,
        noOfDays: 0,
        amount: 0,
        total: 0,
        users: [],
      };
      setActivityList([...activityList, tempdata]);
      formik.values.manPowerActivity = "";
    }
  }

  async function handleRemoveManPowerItem(value) {
    confirm({
      title: `Do you want to Delete ${value}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: ``,
      onOk() {
        if (activityList.some((item) => item.activity === value)) {
          // formik.values.materialTotalAmount = formik.values.materialTotalAmount - amount
          let arrayValue = activityList.filter(
            (item) => item.activity !== value
          );
          setActivityList(arrayValue);
        }
      },
      onCancel() {},
    });
  }

  function OpenActivityRoles(value) {
    setTempListofJobRole(value);
    setJobRoleList(value?.users);
    setAddActivityOpen(true);
  }

  function addJobRole() {
    if (
      formik.values.manPowerjobRole &&
      formik.values.manPowerqty &&
      formik.values.manPowerdays &&
      formik.values.manPowerhours &&
      formik.values.manPoweramount
    ) {
      let tempdata = {
        job_role: formik.values.manPowerjobRole,
        unit: formik.values.manPowerunit,
        qty: formik.values.manPowerqty,
        days: formik.values.manPowerdays,
        hours: formik.values.manPowerhours,
        amount: formik.values.manPoweramount,
      };
      setJobRoleList([...jobRoleList, tempdata]);
      formik.values.manPowerJobRoleTotalAmount =
        Number(formik.values.manPowerJobRoleTotalAmount || 0) +
        Number(formik.values.manPoweramount || 0);
      formik.values.manPowerjobRole = "";
      formik.values.manPowerunit = "";
      formik.values.manPowerqty = "";
      formik.values.manPowerdays = "";
      formik.values.manPowerhours = "";
      formik.values.manPoweramount = "";
    }
  }

  function EditJobRoles() {
    let tempData = tempListofJobRole;
    let value = activityList.map((item) => {
      if (item.activity == tempData.activity) {
        return { ...item, users: jobRoleList };
      }
      return item;
    });
    setActivityList(value);
    setJobRoleList([]);
    setAddActivityOpen(false);
    console.log("test value", value);
  }

  async function handleRemoveJobRolesItem(value) {
    confirm({
      title: `Do you want to Delete ${value}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: ``,
      onOk() {
        if (jobRoleList.some((item) => item.job_role === value)) {
          // formik.values.materialTotalAmount = formik.values.materialTotalAmount - amount
          let arrayValue = jobRoleList.filter(
            (item) => item.job_role !== value
          );
          setJobRoleList(arrayValue);
        }
      },
      onCancel() {},
    });
  }

  useMemo(() => {
    if (formik.values.manPowerJobRoleTotalAmount) {
      formik.values.manPowerTotalAmount =
        Number(formik.values.manPowerTotalAmount || 0) +
        Number(formik.values.manPowerJobRoleTotalAmount || 0);
    }
  }, [formik.values.manPowerJobRoleTotalAmount]);

  const [manPowerTotalAmountValue, setManPowerTotalAmountValue] = useState(0);

  return (
    <div className="w-full h-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/bidding/estimation">
                  <p className=" text-[12px] ">Estimation</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div
        className=" w-full mx-auto h-[calc(100vh-120px)] rounded-[6px] mt-2 overflow-y-scroll"
        style={{ border: "1px solid #C5C5C5" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <NuLabel label="Material / Activity" />
          <div className="w-[95%] flex justify-between mx-auto">
            <NuInputText
              label="Discription"
              isRequired={true}
              placeholder="Discription"
              formik={formik}
              name="materialDiscription"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Unit"
              isRequired={true}
              placeholder="Unit"
              formik={formik}
              name="materialunit"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              label="Quantity"
              isRequired={true}
              placeholder="Quantity"
              formik={formik}
              name="materialQuantity"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              label="Unit Price"
              isRequired={true}
              placeholder="Unit Price"
              formik={formik}
              name="materialUnitPrice"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              label="Total Price"
              isRequired={true}
              placeholder="Total Price"
              formik={formik}
              name="materialTotal"
              width="w-full md:w-1/2 lg:w-1/3"
              disable={true}
            />
            <button
              type="button"
              className="w-14 mt-5 p-1 text-center h-10 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
              onClick={AddMaterial}
            >
              +
            </button>
          </div>
          <div className="w-[95%] max-h-[35vh] bg-white overflow-y-scroll mx-auto relative">
            <table className=" w-full border-collapse">
              <thead className="sticky top-0">
                <tr className="bg-[#1B2838]">
                  <th className=" w-[25%] py-2 text-left pl-1 text-white text-xs font-medium">
                    Description
                  </th>
                  <th className=" w-[15%] py-2 text-left text-white text-xs font-medium">
                    Unit
                  </th>
                  <th className=" w-[15%] py-2 text-left text-white text-xs font-medium">
                    Quantity
                  </th>
                  <th className=" w-[15%] py-2 text-left text-white text-xs font-medium">
                    Unit Price
                  </th>
                  <th className=" w-[15%] py-2 text-left text-white text-xs font-medium">
                    Total Price
                  </th>
                  <th className=" w-[5%]  py-2 text-center text-white text-xs font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {materialList?.map((list, index) => (
                  <tr
                    key={index}
                    className="w-full  odd:bg-white even:bg-slate-50"
                  >
                    <td className="w-[25%] pl-1 py-2 text-left text-black text-xs font-light">
                      {list?.description}
                    </td>
                    <td className="w-[15%] py-2 text-left text-black text-xs font-light">
                      {list?.unit}
                    </td>
                    <td className="w-[15%] py-2 text-left text-black text-xs font-light">
                      {list?.qty}
                    </td>
                    <td className="w-[15%] py-2 text-left text-black text-xs font-light">
                      {list?.unit_price}
                    </td>
                    <td className="w-[15%] py-2 text-left text-black text-xs font-light">
                      {list?.total_price}
                    </td>
                    <td className="w-[5%] ">
                      <div className=" w-full flex justify-center items-center">
                        <button
                          onClick={() =>
                            handleRemoveMaterialItem(
                              list?.description,
                              list?.total_price
                            )
                          }
                          type="button"
                          className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className=" w-full sticky bottom-0">
              <tfoot className=" w-full bg-white">
                <tr className=" flex justify-end items-center">
                  <td className=" w-[10%] py-2 text-right text-xs text-gray-800 font-medium">
                    Total Price :
                  </td>
                  <td className=" w-[15%] py-2 text-right text-[13px] text-gray-800 font-medium">
                    <NuAmount value={formik.values.materialTotalAmount || 0} />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <NuLabel label="Manpower" />
          <div className="w-[95%] flex justify-start items-end mx-auto">
            <NuInputText
              label="Activity"
              isRequired={true}
              placeholder="Activity"
              formik={formik}
              name="manPowerActivity"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <button
              type="button"
              className="w-12 mt-5 p-1 my-3 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
              onClick={addManPower}
            >
              +
            </button>
          </div>
          <div className="w-[95%] max-h-[35vh] bg-white overflow-y-scroll mx-auto">
            <table className=" w-full border-collapse">
              <thead className="sticky top-0">
                <tr className="bg-[#1B2838]">
                  <th className=" w-[50%] py-2 pl-1 text-left text-white text-xs font-medium">
                    Activity
                  </th>
                  <th className=" w-[20%] py-2 text-left text-white text-xs font-medium">
                    No Of Days
                  </th>
                  <th className=" w-[20%] py-2 text-left text-white text-xs font-medium">
                    Amount
                  </th>
                  <th className=" w-[10%] py-2 text-center text-white text-xs font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {activityList?.map((list, index) => (
                  <tr
                    key={index}
                    className="w-full  odd:bg-white even:bg-slate-50"
                  >
                    <td className="w-[50%] py-2">
                      <div className="flex justify-between items-center pl-1 pr-2">
                        <p className="text-left text-black text-xs font-light">
                          {list?.activity}
                        </p>
                        <div
                          className="cursor-pointer"
                          onClick={() => OpenActivityRoles(list)}
                        >
                          <ViewMore />
                        </div>
                      </div>
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.unit || 0}
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.quantity || 0}
                    </td>
                    <td className="w-[10%] py-2 text-center">
                      <div className=" w-full flex justify-center items-center">
                        <button
                          onClick={() =>
                            handleRemoveManPowerItem(list?.activity)
                          }
                          type="button"
                          className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className=" w-full sticky bottom-0">
              <tfoot className=" w-full bg-white">
                <tr className=" flex justify-end items-center">
                  <td className=" w-[10%] py-2 text-right text-xs text-gray-800 font-medium">
                    Total Price :
                  </td>
                  <td className=" w-[15%] py-2 text-right text-[13px] text-gray-800 font-medium">
                    <NuAmount value={formik.values.manPowerTotalAmount || 0} />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <NuLabel label="Profit Margin" />
          <div className=" w-[95%] mx-auto">
            <table className=" w-full border-collapse  text-right">
              <thead className=" text-center">
                <th className=" w-[20%]"></th>
                <th className=" w-[20%]"></th>
                <th className=" w-[20%] text-xs font-semibold">Percentage</th>
                <th className=" w-[20%] text-xs font-semibold">Amount</th>
                <th className=" w-[20%] text-xs font-semibold">Total</th>
              </thead>
              <tbody>
                <tr className=" w-full">
                  <td className=" w-[20%]"></td>
                  <td className=" w-[20%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      Material :{" "}
                    </p>
                  </td>
                  <td className=" w-[20%]">
                    <NuSelect
                      label=""
                      options={percentageOptions}
                      formik={formik}
                      placeholder="Choose"
                      name="clientId"
                      width="w-full md:w-3/4"
                      // isRequired={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <div
                      className=" w-[97%]  h-8 rounded-md px-2 flex justify-end items-center "
                      style={{ border: "1px solid #cbd5e1" }}
                    >
                      <NuAmount value={0 || 0} />
                    </div>
                  </td>
                  <td className=" w-[20%]">
                    <div
                      className=" w-[97%]  h-8 rounded-md px-2 flex justify-end items-center "
                      style={{ border: "1px solid #cbd5e1" }}
                    >
                      <NuAmount value={0 || 0} />
                    </div>
                  </td>
                </tr>

                <tr className=" w-full">
                  <td className=" w-[20%]"></td>
                  <td className=" w-[20%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      Manpower :{" "}
                    </p>
                  </td>
                  <td className=" w-[20%]">
                    <NuSelect
                      label=""
                      options={percentageOptions}
                      formik={formik}
                      placeholder="Choose"
                      name="clientId"
                      width="w-full md:w-3/4"
                      // isRequired={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Amount"
                      name="amount"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Total"
                      name="totalPrice"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                </tr>

                <tr className=" w-full">
                  <td colSpan={4} className="  w-[80%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      Project Value without Overall Profit and VAT :{" "}
                    </p>
                  </td>

                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Total"
                      name="totalPrice"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                </tr>

                <tr className=" w-full">
                  <td className=" w-[20%]"></td>
                  <td className=" w-[20%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      Overhead :{" "}
                    </p>
                  </td>
                  <td className=" w-[20%]">
                    <NuSelect
                      label=""
                      options={percentageOptions}
                      formik={formik}
                      placeholder="Choose"
                      name="clientId"
                      width="w-full md:w-3/4"
                      // isRequired={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Amount"
                      name="amount"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Total"
                      name="totalPrice"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                </tr>

                <tr className=" w-full">
                  <td className=" w-[20%]"></td>
                  <td className=" w-[20%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      Profit :{" "}
                    </p>
                  </td>
                  <td className=" w-[20%]">
                    <NuSelect
                      label=""
                      options={percentageOptions}
                      formik={formik}
                      placeholder="Choose"
                      name="clientId"
                      width="w-full md:w-3/4"
                      // isRequired={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Amount"
                      name="amount"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Total"
                      name="totalPrice"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                </tr>

                <tr className=" w-full">
                  <td colSpan={4} className="  w-[80%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      Total Estimated value including Profit (VAT not included)
                      :{" "}
                    </p>
                  </td>

                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Total"
                      name="totalPrice"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                </tr>

                <tr className=" w-full">
                  <td className=" w-[20%]"></td>
                  <td className=" w-[20%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      VAT :{" "}
                    </p>
                  </td>
                  <td className=" w-[20%]">
                    <NuSelect
                      label=""
                      options={percentageOptions}
                      formik={formik}
                      placeholder="Choose"
                      name="clientId"
                      width="w-full md:w-3/4"
                      // isRequired={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Amount"
                      name="amount"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Total"
                      name="totalPrice"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                </tr>

                <tr className=" w-full">
                  <td colSpan={4} className="  w-[80%]">
                    <p className="text-xs  text-gray-800 font-medium py-5">
                      Total Amount :{" "}
                    </p>
                  </td>

                  <td className=" w-[20%]">
                    <NuInputNumber
                      label=""
                      formik={formik}
                      placeholder="Total"
                      name="totalPrice"
                      width="w-full "
                      disable={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
            <CancelButton handleClick={() => navigate(-1)} />
            <SubmitButton isLoading={isLoading} />
          </div>
        </form>
      </div>
      <Modal
        open={addActivityOpen}
        title={"Add Activity"}
        width={"75%"}
        className="my-modal"
        centered
        onCancel={() => setAddActivityOpen(false)}
        footer={[]}
      >
        {/* <form > */}
        <div className=" w-full flex justify-between">
          <NuInputText
            label="Job Role"
            isRequired={true}
            placeholder="Discription"
            formik={formik}
            name="manPowerjobRole"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputText
            label="Unit"
            isRequired={true}
            placeholder="Unit"
            formik={formik}
            name="manPowerunit"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Quantity"
            isRequired={true}
            placeholder="Quantity"
            formik={formik}
            name="manPowerqty"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Days"
            isRequired={true}
            placeholder="Unit Price"
            formik={formik}
            name="manPowerdays"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Hours"
            isRequired={true}
            placeholder="Total Price"
            formik={formik}
            name="manPowerhours"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Amount"
            isRequired={true}
            placeholder="Unit Price"
            formik={formik}
            name="manPoweramount"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <button
            type="button"
            className="w-14 mt-5 p-1 text-center h-10 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
            onClick={addJobRole}
          >
            +
          </button>
        </div>
        <div className="w-full h-40 bg-white overflow-y-scroll mx-auto">
          <div className="w-full py-3  bg-[#1B2838] flex justify-between rounded-lg px-6">
            <p className=" w-[10%] text-left text-white text-xs font-medium">
              Job Role
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Unit
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Qty
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Days
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Hours
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Amount
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Action
            </p>
          </div>
          {jobRoleList?.map((list, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
              >
                <p className="w-[10%] text-left text-black text-xs font-light">
                  {list?.job_role || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.unit || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.qty || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.days}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.hours}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.amount}
                </p>
                <div className="w-[15%] text-center">
                  <button
                    onClick={() => handleRemoveJobRolesItem(list?.job_role)}
                    type="button"
                    className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                  >
                    <DeleteIcon color="red" height={4} />
                  </button>
                </div>
              </div>
            );
          })}
          <div className=" w-full flex justify-end gap-2">
            <p className="w-[15%] text-center text-black text-xs font-light">
              {formik.values.manPowerJobRoleTotalDays}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-light">
              {formik.values.manPowerJobRoleTotalHours}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-light">
              {formik.values.manPowerJobRoleTotalAmount}
            </p>
          </div>
        </div>
        <div className="flex justify-end item-end mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
          <CancelButton handleClick={() => setAddActivityOpen(false)} />
          <button onClick={EditJobRoles}>Ok</button>
          {/* <SubmitButton isLoading={isLoading} /> */}
        </div>
        {/* </form> */}
      </Modal>
    </div>
  );
}
