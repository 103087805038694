import React from "react";

function Team({ color = "white", width = 14, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94329 0.0344923C5.88742 0.236805 6.6461 0.880836 6.95968 1.75078C7.32385 2.7556 7.08781 3.83798 6.33925 4.58654C5.12875 5.79704 3.11573 5.58462 2.17835 4.14482C1.87825 3.68624 1.75686 3.2715 1.75686 2.68479C1.75686 2.09809 1.87825 1.67997 2.17498 1.22814C2.55937 0.641431 3.09213 0.257037 3.79685 0.0648391C4.05986 -0.00597027 4.67354 -0.0228297 4.94329 0.0344923ZM4.04974 1.11687C3.46978 1.2686 3.02132 1.72044 2.86958 2.30377C2.65041 3.14337 3.18654 4.0268 4.04974 4.25272C4.89609 4.47189 5.77952 3.93576 6.00544 3.07256C6.30554 1.91938 5.20293 0.81677 4.04974 1.11687Z"
        fill={color}
      />
      <path
        d="M10.0683 3.27151C11.0124 3.47382 11.7711 4.11785 12.0847 4.9878C12.4488 5.99262 12.2128 7.07499 11.4643 7.82355C10.2537 9.03406 8.24073 8.82163 7.30335 7.38184C7.00325 6.92326 6.88186 6.50852 6.88186 5.92181C6.88186 5.3351 7.00325 4.91699 7.29998 4.46515C7.68437 3.87845 8.21713 3.49405 8.92185 3.30185C9.18486 3.23104 9.79854 3.21419 10.0683 3.27151ZM9.17474 4.35388C8.59478 4.50562 8.14632 4.95745 7.99458 5.54079C7.77541 6.38039 8.31154 7.26382 9.17474 7.48974C10.0211 7.70891 10.9045 7.17278 11.1304 6.30958C11.4305 5.15639 10.3279 4.05378 9.17474 4.35388Z"
        fill={color}
      />
      <path
        d="M2.24502 5.45649C2.43722 5.58799 2.49791 5.69927 2.49791 5.91844C2.49791 6.09715 2.48779 6.1275 2.35292 6.29609C1.81342 6.96709 1.53692 7.64147 1.48297 8.40015C1.46274 8.68676 1.46611 8.72385 1.53355 8.83512C1.67517 9.06441 2.16409 9.32067 2.78115 9.49264C3.12508 9.58705 3.64098 9.66123 4.17711 9.69158C4.67615 9.72193 4.73684 9.74216 4.88858 9.94784C4.98636 10.0793 4.98636 10.3963 4.88858 10.5278C4.72673 10.7436 4.67952 10.7605 4.15688 10.7571C3.13182 10.7504 2.05282 10.484 1.34472 10.0625C1.01428 9.87029 0.677091 9.51961 0.532099 9.22963L0.40734 8.97674L0.410712 8.51816C0.414084 7.85053 0.548959 7.28068 0.849057 6.64677C1.06823 6.17807 1.50657 5.57451 1.719 5.44637C1.86737 5.35533 2.1034 5.36208 2.24502 5.45649Z"
        fill={color}
      />
      <path
        d="M12.2093 8.66989C12.5297 8.78116 13.0624 9.56344 13.3355 10.3289C13.5075 10.7976 13.5851 11.2393 13.5884 11.7552L13.5918 12.2137L13.467 12.4666C13.322 12.7566 12.9849 13.1073 12.6544 13.2995C12.1689 13.5895 11.4776 13.8154 10.7055 13.9368C10.1693 14.0211 8.95545 14.0211 8.41932 13.9368C7.64716 13.8154 6.95593 13.5895 6.47037 13.2995C6.13993 13.1073 5.80274 12.7566 5.65775 12.4666L5.53299 12.2137L5.53636 11.7552C5.54311 10.8852 5.78588 10.0962 6.28492 9.3409C6.54793 8.94301 6.77047 8.70024 6.91884 8.65303C7.18184 8.56536 7.46171 8.68675 7.57635 8.93627C7.691 9.18916 7.64042 9.34427 7.32009 9.74889C6.93232 10.2344 6.65583 10.9695 6.60862 11.6372C6.58839 11.9238 6.59176 11.9609 6.6592 12.0721C6.85477 12.3857 7.59321 12.6993 8.50025 12.8578C9.0094 12.9454 10.1896 12.9353 10.7088 12.8409C11.5687 12.6858 12.2768 12.3722 12.4656 12.0688C12.533 11.9609 12.5364 11.9238 12.5162 11.6405C12.469 10.9324 12.1823 10.2007 11.744 9.66123C11.5451 9.41508 11.5181 9.35776 11.5181 9.16556C11.5181 8.80477 11.8721 8.5485 12.2093 8.66989Z"
        fill={color}
      />
    </svg>
  );
}

export default Team;
