import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../Services/axiosCommon';
import moment from 'moment';
import NuDate from '../../../Components/Utils/NuDate';

function Notifications() {
  const [noti, setNoti] = useState([]);
  const [count, setCount] = useState(0);


  const mutate = () =>{
    axiosInstance.get(`/notification/user`).then(res=>{
      res?.data?.data && setCount(res?.data?.data?.count);
      res?.data?.data?.notifications && setNoti(res?.data?.data?.notifications);
    }).catch(err=>console.log(err))
  }

  const handleRead = async (id) => {
    axiosInstance
      .put(`/notification/update/is_read/${id}`, {
        is_read: 1,
      })
      .then((res) => {
        mutate();
        console.log(res.data);
      });
  };

  function ClearAll() {
    axiosInstance
      .delete(`/notification/delete`)
      .then((res) => {
        mutate();
      })
      .catch((err) => {});
  }

  function MarkAll() {
    axiosInstance
      .get(`/notification/update/mark_all_as_read`)
      .then((res) => {
        mutate();
      })
      .catch((err) => {});
  }

  useEffect(()=>{
   mutate()
  },[])

  return (
    <div className="w-full h-full  bg-white shadow-lg overflow-hidden rounded-md">
    <div className=" w-[95%] py-2 px-2 flex justify-between items-center">
      <p className="font-medium ">Notification</p>
      <div className=" flex items-center gap-2">
        {count > 0 ? (
          <button
            className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-[#119DEE] text-white cursor-pointer"
            onClick={MarkAll}
          >
            Mark as read
          </button>
        ) : null}
        {noti.length > 0 ? (
          <button
            className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-[#119DEE] text-white cursor-pointer"
            onClick={ClearAll}
          >
            Clear all
          </button>
        ) : null}
      </div>
    </div>
    <div className="w-full max-h-[calc(100vh-40px)] overflow-y-auto  overflow-x-hidden">
      {noti?.map((notification) => {
        return (
          <>
            <div
              className={`${
                notification?.is_read ? "bg-white" : " bg-slate-100"
              } w-[95%] m-1 p-1 rounded flex justify-between items-start cursor-pointer`}
              onClick={notification?.is_read ?()=>{} :() => handleRead(notification?.notificationId)}
            >
              <div className="w-[15%]">
                {/* <NuAvatarGroup userdetails={[{name:`${notification?.sender_fName}`,imageUrl:`${notification?.sender_imageUrl}`}]} />    */}
                <div className=" w-8 h-8 bg-[#119DEE]  flex justify-center items-center text-white font-medium text-xs rounded-full overflow-hidden">
                  {notification.sender_imageUrl &&
                  notification.sender_imageUrl != "" &&
                  notification.sender_imageUrl != null ? (
                    <img
                      src={
                        notification.sender_imageUrl
                          ? notification.sender_imageUrl
                          : null
                      }
                      width="50px"
                      alt={`${notification.sender_imageUrl}`}
                    />
                  ) : (
                    `${notification?.sender_fName.charAt(0)}`
                  )}
                </div>
              </div>
              <div
                className={`w-[85%] ${
                  notification?.is_read
                    ? " font-normal "
                    : "font-medium  "
                }`}
              >
                {/* <p className='font-semibold'>Admin <span className='font-normal'>to</span> Login Person</p> */}
                <p className="pb-1 text-ellipsis leading-5 line-clamp-2 text-xs">
                  {notification?.msg}
                </p>
                <p className="text-xs">
                  {moment(notification?.date).format("DD-MM-YYYY")} at{" "}
                  <span className="text-[10px]">
                    <NuDate
                      value={`${notification?.date} ${notification?.time}`}
                      format="hh:mm a"
                    />
                  </span>
                </p>
              </div>
            </div>
            {/* <p className='py-2 leading-5 cursor-pointer' onClick={()=> handleRead(notification?.notificationId
)}>{notification?.msg}</p>
      <div className='w-full h-[0.5px] bg-black'></div> */}
          </>
        );
      })}
    </div>
  </div>
  )
}

export default Notifications