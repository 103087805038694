import React from 'react'

function Dasboard({color="white", width=14, height=14}) {
  return (
<svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 3.51749C1 2.71966 1 2.32075 1.1338 2.00748C1.30144 1.61497 1.61336 1.30223 2.00484 1.13415C2.31729 1 2.71516 1 3.5109 1C4.30663 1 4.7045 1 5.01696 1.13415C5.40844 1.30223 5.72035 1.61497 5.88799 2.00748C6.0218 2.32075 6.0218 2.71966 6.0218 3.51749C6.0218 4.31531 6.0218 4.71423 5.88799 5.0275C5.72035 5.42001 5.40844 5.73275 5.01696 5.90083C4.7045 6.03498 4.30663 6.03498 3.5109 6.03498C2.71516 6.03498 2.31729 6.03498 2.00484 5.90083C1.61336 5.73275 1.30144 5.42001 1.1338 5.0275C1 4.71423 1 4.31531 1 3.51749Z" stroke={color} stroke-width="1.5"/>
<path d="M7.9782 3.51749C7.9782 2.71966 7.9782 2.32075 8.112 2.00748C8.27965 1.61497 8.59156 1.30223 8.98304 1.13415C9.2955 1 9.69337 1 10.4891 1C11.2848 1 11.6827 1 11.9952 1.13415C12.3866 1.30223 12.6986 1.61497 12.8662 2.00748C13 2.32075 13 2.71966 13 3.51749C13 4.31531 13 4.71423 12.8662 5.0275C12.6986 5.42001 12.3866 5.73275 11.9952 5.90083C11.6827 6.03498 11.2848 6.03498 10.4891 6.03498C9.69337 6.03498 9.2955 6.03498 8.98304 5.90083C8.59156 5.73275 8.27965 5.42001 8.112 5.0275C7.9782 4.71423 7.9782 4.31531 7.9782 3.51749Z" stroke={color} stroke-width="1.5"/>
<path d="M7.9782 10.4825C7.9782 9.68468 7.9782 9.28577 8.112 8.9725C8.27965 8.57999 8.59156 8.26725 8.98304 8.09917C9.2955 7.96502 9.69337 7.96502 10.4891 7.96502C11.2848 7.96502 11.6827 7.96502 11.9952 8.09917C12.3866 8.26725 12.6986 8.57999 12.8662 8.9725C13 9.28577 13 9.68468 13 10.4825C13 11.2803 13 11.6792 12.8662 11.9925C12.6986 12.385 12.3866 12.6978 11.9952 12.8658C11.6827 13 11.2848 13 10.4891 13C9.69337 13 9.2955 13 8.98304 12.8658C8.59156 12.6978 8.27965 12.385 8.112 11.9925C7.9782 11.6792 7.9782 11.2803 7.9782 10.4825Z" stroke={color} stroke-width="1.5"/>
<path d="M1 10.4825C1 9.68468 1 9.28577 1.1338 8.9725C1.30144 8.57999 1.61336 8.26725 2.00484 8.09917C2.31729 7.96502 2.71516 7.96502 3.5109 7.96502C4.30663 7.96502 4.7045 7.96502 5.01696 8.09917C5.40844 8.26725 5.72035 8.57999 5.88799 8.9725C6.0218 9.28577 6.0218 9.68468 6.0218 10.4825C6.0218 11.2803 6.0218 11.6792 5.88799 11.9925C5.72035 12.385 5.40844 12.6978 5.01696 12.8658C4.7045 13 4.30663 13 3.5109 13C2.71516 13 2.31729 13 2.00484 12.8658C1.61336 12.6978 1.30144 12.385 1.1338 11.9925C1 11.6792 1 11.2803 1 10.4825Z" stroke={color} stroke-width="1.5"/>
</svg>
  )
}

export default Dasboard;