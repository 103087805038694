import { Modal, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react'
import * as yup from "yup";
import axios from "../../../../../Services/axiosCommon";
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled } from "@ant-design/icons";
import DeleteIcon from '../../../../../Components/Icons/Delete';
import { NuInputNumber, NuInputText, NuSelect } from '../../../../../Components/Utils/NuInput';
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';
import NuAmount from '../../../../../Components/Utils/NuAmount';
import ViewMore from '../../../../../Components/Icons/ViewMore';
import axiosInstance from '../../../../../Services/axiosCommon';



const { confirm } = Modal;


function Manpower() {
  const navigate = useNavigate();
  const [addActivityOpen, setAddActivityOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [manPowerList,setManPowerList] = useState([]);
  // const [createNew, setCreateNew] = useState({
  //   projectName: "",
  //   projectValue: "",
  //   clientId: "",
  //   referenceNo: "",
  //   submissionDueDate: "",
  //   submittedOn: "",
  //   submittedBy: null,
  //   submittedTo: null,
  //   acknowledgement: "",
  //   biddingStartDate: "",
  //   comments: "",

  //   materialDiscription: "",
  //   materialunit: "",
  //   materialQuantity: "",
  //   materialUnitPrice: "",
  //   materialTotal: "",
  //   materialTotalAmount: 0,

  //   manPowerActivity: "",
  //   manPowerNoOfDay: "",
  //   manPowerTotalAmount: "",
  //   manPowerUser: "",
  //   manPowerjobRole: "",
  //   manPowerunit: "",
  //   manPowerqty: "",
  //   manPowerdays: "",
  //   manPowerhours: "",
  //   manPoweramount: "",
  //   manPowerJobRoleTotalAmount: "",
  //   manPowerJobRoleTotalDays: "",
  //   manPowerJobRoleTotalHours: "",

  //   profitMarginMaterialPercentage: "",
  //   profitMarginMaterialAmount: "",
  //   profitMarginMaterialTotal: "",
  // });

  const [createNew, setCreateNew] = useState({
    estimationId: "",
    itemId: "",
    mp_items: []
  })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: true,
        validationSchema: yup.object({
          projectName: yup.string().required("Project Name is Required"),
          // clientId: yup.string().required("Client "),
        }),
        onSubmit: async (value) => {
          setIsLoading(true);
          let formData = new FormData();
          formData.append("details", JSON.stringify(value));
          // formData.append("attachments", image.raw);
          if (selectedFile !== null) {
            selectedFile.forEach((file) => {
              formData.append("attachments", file);
            });
          } else {
            formData.append("attachments", []);
          }
    
          // data
          //   ? axios
          //       .put(`/bidding/update_bidding/${data?.biddingId}`, formData)
          //       .then((res) => {
          //         if (res.status == 201 || res.data.status == true) {
          //           setIsLoading(false);
          //           setImage({ preview: "", raw: "" });
          //           api.success({
          //             message: `Add successfully`,
          //             description: res?.data?.msg || " created",
          //             placement: "bottomRight",
          //           });
          //           navigate(-1);
          //         } else {
          //           setIsLoading(false);
          //           api.error({
          //             message: `Unsuccessfully`,
          //             description: res?.data?.msg || "creation failed",
          //             placement: "bottomRight",
          //           });
          //         }
          //       })
          //       .catch((err) => {
          //         setIsLoading(false);
          //         api.error({
          //           message: `Unsuccessfully`,
          //           description: err?.response?.data?.msg || "creation failed",
          //           placement: "bottomRight",
          //         });
          //       })
          //   : 
            axios
                .post("/bidding/create_bidding", formData)
                .then((res) => {
                  if (res.status == 201 || res.data.status == true) {
                    setIsLoading(false);
                    setImage([]);
                    api.success({
                      message: `Add successfully`,
                      description: res?.data?.msg || " created",
                      placement: "bottomRight",
                    });
                    navigate(-1);
                  } else {
                    setIsLoading(false);
                    api.error({
                      message: `Unsuccessfully`,
                      description: res?.data?.msg || "creation failed",
                      placement: "bottomRight",
                    });
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  api.error({
                    message: `Unsuccessfully`,
                    description: err?.response?.data?.msg || "creation failed",
                    placement: "bottomRight",
                  });
                });
        },
      });
      // const [materialList, setMaterialList] = useState([]);
      // useMemo(() => {
      //   if (formik.values.materialQuantity && formik.values.materialUnitPrice) {
      //     formik.values.materialTotal =
      //       formik.values.materialQuantity * formik.values.materialUnitPrice;
      //   } else {
      //     formik.values.materialTotal = " ";
      //   }
      // }, [formik.values.materialQuantity, formik.values.materialUnitPrice]);
    
      // function AddMaterial() {
      //   if (
      //     formik.values.materialDiscription &&
      //     formik.values.materialQuantity &&
      //     formik.values.materialUnitPrice
      //   ) {
      //     let tempValue = {
      //       description: formik.values.materialDiscription || "",
      //       unit: formik.values.materialunit || "",
      //       qty: formik.values.materialQuantity || "",
      //       unit_price: formik.values.materialUnitPrice || "",
      //       total_price: formik.values.materialTotal || "",
      //     };
      //     formik.values.materialTotalAmount =
      //       formik.values.materialTotalAmount + formik.values.materialTotal;
      //     setMaterialList([...materialList, tempValue]);
      //     formik.values.materialDiscription = "";
      //     formik.values.materialQuantity = "";
      //     formik.values.materialunit = "";
      //     formik.values.materialUnitPrice = "";
      //     formik.values.materialTotal = "";
      //   } else {
      //     api.error({
      //       message: `Unsuccessfully`,
      //       description: "Fill all Feilds",
      //       placement: "bottomRight",
      //     });
      //   }
      // }
    
      // async function handleRemoveMaterialItem(value, amount) {
      //   confirm({
      //     title: `Do you want to Delete ${value}?`,
      //     centered: true,
      //     icon: <ExclamationCircleFilled />,
      //     content: ``,
      //     onOk() {
      //       if (materialList.some((material) => material.description === value)) {
      //         formik.values.materialTotalAmount =
      //           formik.values.materialTotalAmount - amount;
      //         let arrayValue = materialList.filter(
      //           (item) => item.description !== value
      //         );
      //         setMaterialList(arrayValue);
      //       }
      //     },
      //     onCancel() {},
      //   });
      // }

    const [activityList, setActivityList] = useState([]);
    const [tempListofJobRole, setTempListofJobRole] = useState({});
    const [jobRoleList, setJobRoleList] = useState([]);



      function addManPower() {
        if (formik.values.manPowerActivity) {
          let tempdata = {
            activity: formik.values.manPowerActivity,
            noOfDays: 0,
            amount: 0,
            total: 0,
            users: [],
          };
          setActivityList([...activityList, tempdata]);
          formik.values.manPowerActivity = "";
        }
      }

      async function handleRemoveManPowerItem(value) {
        confirm({
          title: `Do you want to Delete ${value}?`,
          centered: true,
          icon: <ExclamationCircleFilled />,
          content: ``,
          onOk() {
            if (activityList.some((item) => item.activity === value)) {
              // formik.values.materialTotalAmount = formik.values.materialTotalAmount - amount
              let arrayValue = activityList.filter(
                (item) => item.activity !== value
              );
              setActivityList(arrayValue);
            }
          },
          onCancel() {},
        });
      }

      function OpenActivityRoles(value) {
        setTempListofJobRole(value);
        setJobRoleList(value?.users);
        setAddActivityOpen(true);
      }

      const addJobRole = () => {
        console.log("Ne",formik.values);
        
        const tempValue = {
            role: formik.values.manPowerjobRole|| "",
            type: formik.values.type || "",
            quantity:formik.manPowerqty|| "",
            days:formik.values.manPowerdays||"",
            hours:formik.values.manPowerhours||"",
            amount:formik.values.manPoweramount|| ""
        };
        setManPowerList([...manPowerList, tempValue]);
      
        formik.setFieldValue("type", "");
        formik.setFieldValue("role", "");
      };
    
      function EditJobRoles() {
        let tempData = tempListofJobRole;
        let value = activityList.map((item) => {
          if (item.activity == tempData.activity) {
            return { ...item, users: jobRoleList };
          }
          return item;
        });
        setActivityList(value);
        setJobRoleList([]);
        setAddActivityOpen(false);
        console.log("test value", value);
      }
    
      async function handleRemoveJobRolesItem(value) {
        confirm({
          title: `Do you want to Delete ${value}?`,
          centered: true,
          icon: <ExclamationCircleFilled />,
          content: ``,
          onOk() {
            if (jobRoleList.some((item) => item.job_role === value)) {
              // formik.values.materialTotalAmount = formik.values.materialTotalAmount - amount
              let arrayValue = jobRoleList.filter(
                (item) => item.job_role !== value
              );
              setJobRoleList(arrayValue);
            }
          },
          onCancel() {},
        });
      }
    
      useMemo(() => {
        if (formik.values.manPowerJobRoleTotalAmount) {
          formik.values.manPowerTotalAmount =
            Number(formik.values.manPowerTotalAmount || 0) +
            Number(formik.values.manPowerJobRoleTotalAmount || 0);
        }
      }, [formik.values.manPowerJobRoleTotalAmount]);

      const activityLists = [
        {
           id: "1",
           activity: "Test1",
        },
        {
          id: "2",
          activity: "Test2",
       }
      ]

      const [data,setData] = useState([]);
      const [jobRole,setJobRole] = useState([]);

      // const filteredData = data.filter(item => item.type === formik.values.type);
      // console.log("filterdata",filteredData);
      // let tempData = []; 
      // filteredData.forEach((item) => {
      //   let tempObject = { value: item.job_role, label: item.job_role };
      //   tempData.push(tempObject);
      // });
      // filteredData && setJobRole(tempData);
      
      async function getJobRoleDetails() {
        await axiosInstance
          .get(`/manpower-role/`)
          .then(async (res) => {
            (await res?.data?.data) && setData(res?.data?.data);
            let data = res.data.data;
            const filteredData = data.filter(item => item.type === formik.values.type);
          console.log(data,'dddd');
          let tempData = [];
          filteredData.forEach((item) => {
              let tempObject = { value: item.job_role, label: item.job_role };
              tempData.push(tempObject);
          });
          res.data.data && setJobRole(tempData);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      useEffect(()=>{
          getJobRoleDetails();
      },[])

      

  return (
    <div className=' w-full h-[calc(100vh-160px)] overflow-hidden' >
        <form onSubmit={formik.handleSubmit}>
        <div className=' w-full flex gap-2 items-center'>
        <NuInputText
              label="Activity"
              isRequired={true}
              placeholder="Discription"
              formik={formik}
              name="materialDiscription"
              width="w-full md:w-1/2 lg:w-[70%]"
            />
              <button
              type="button"
              className="w-12 mt-6 p-1 my-3 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "

            //   className="w-14 my-3 p-1 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
              onClick={addManPower}
            >
              +
            </button>
        </div>
        <div className='w-full h-[calc(100vh-340px)] overflow-y-auto'>
        <table className=" w-full border-collapse">
              <thead className="sticky top-0">
                <tr className="bg-[#1B2838]">
                <th className=" w-[5%] py-2 pl-1 text-left text-white text-xs font-medium">
                    SI.No
                  </th>
                  <th className=" w-[70%] py-2 pl-1 text-left text-white text-xs font-medium">
                    Activity
                  </th>
                  <th className=" w-[10%] py-2 text-left text-white text-xs font-medium">
                    No Of Days
                  </th>
                  <th className=" w-[10%] py-2 text-left text-white text-xs font-medium">
                    Amount
                  </th>
                  <th className=" w-[5%] py-2 text-center text-white text-xs font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {activityLists?.map((list, index) => (
                  <tr
                    key={index}
                    className="w-full  odd:bg-white even:bg-slate-50"
                  >
                    <td className="w-[5%] pl-1 py-2 text-left text-black text-xs font-light">
                      {index + 1}
                    </td>
                    <td className="w-[50%] py-2">
                      <div className="flex justify-between items-center pl-1 pr-2">
                        <p className="text-left text-black text-xs font-light">
                          {list?.activity}
                        </p>
                        <div
                          className="cursor-pointer"
                          onClick={() => OpenActivityRoles(list)}
                        >
                          <ViewMore />
                        </div>
                      </div>
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.unit || 0}
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.quantity || 0}
                    </td>
                    <td className="w-[10%] py-2 text-center">
                      <div className=" w-full flex justify-center items-center">
                        <button
                          onClick={() =>
                            handleRemoveManPowerItem(list?.activity)
                          }
                          type="button"
                          className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
        <div className='w-full h-8 '>
        <table className=" w-full ">
              <tfoot className=" w-full bg-white">
                <tr className=" flex justify-end items-center">
                  <td className=" w-[10%] py-1 text-right text-xs text-gray-800 font-medium">
                    Total Amount :
                  </td>
                  <td className=" w-[15%] py-1 text-right text-[13px] text-gray-800 font-medium">
                    <NuAmount value={formik.values.manPowerTotalAmount || 0} />
                  </td>
                </tr>
              </tfoot>
            </table>
        </div>
        <div className='w-full   flex gap-2 justify-end items-center'>
        <CancelButton handleClick={() => navigate(-1)} />
        <SubmitButton isLoading={isLoading} />
        </div>
        </form>
     
     
     
        <Modal
        open={addActivityOpen}
        title={"Add Activity"}
        width={"75%"}
        className="my-modal"
        centered
        onCancel={() => setAddActivityOpen(false)}
        footer={[]}
      >
        {/* <form > */}
        <div className=" w-full flex justify-between">
          <NuSelect
            label="Type"
            options={[
              {value: 0, label:"Own"},
              {value: 1, label:"Rental"}
            ]}
            isRequired={true}
            placeholder="Type"
            formik={formik}
            name="type"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuSelect
            label="Job Role"
            options={[
              {value:"Worker One",label:"Woker One"},
              {value:"Worker Two",label:"Woker Two"}
            ]}
            isRequired={true}
            placeholder="Discription"
            formik={formik}
            name="manPowerjobRole"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Quantity"
            isRequired={true}
            placeholder="Quantity"
            formik={formik}
            name="manPowerqty"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Days"
            isRequired={true}
            placeholder="Unit Price"
            formik={formik}
            name="manPowerdays"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Hours"
            isRequired={true}
            placeholder="Total Price"
            formik={formik}
            name="manPowerhours"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Amount"
            isRequired={true}
            placeholder="Unit Price"
            formik={formik}
            name="manPoweramount"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <button
            type="button"
            className="w-14 mt-5 p-1 text-center h-10 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
            onClick={addJobRole}
          >
            +
          </button>
        </div>
        <div className="w-full h-40 bg-white overflow-y-scroll mx-auto">
          <div className="w-full py-3  bg-[#1B2838] flex justify-between rounded-lg px-6">
            <p className=" w-[10%] text-left text-white text-xs font-medium">
              Job Role
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Unit
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Qty
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Days
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Hours
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Amount
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Action
            </p>
          </div>
          {manPowerList?.map((list, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
              >
                <p className="w-[10%] text-left text-black text-xs font-light">
                  {list?.job_role || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.unit || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.qty || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.days}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.hours}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.amount}
                </p>
                <div className="w-[15%] text-center">
                  <button
                    onClick={() => handleRemoveJobRolesItem(list?.job_role)}
                    type="button"
                    className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                  >
                    <DeleteIcon color="red" height={4} />
                  </button>
                </div>
              </div>
            );
          })}
          <div className=" w-full flex justify-end gap-2">
            <p className="w-[15%] text-center text-black text-xs font-light">
              {formik.values.manPowerJobRoleTotalDays}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-light">
              {formik.values.manPowerJobRoleTotalHours}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-light">
              {formik.values.manPowerJobRoleTotalAmount}
            </p>
          </div>
        </div>
        <div className="flex justify-end item-end mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
          <CancelButton handleClick={() => setAddActivityOpen(false)} />
          <button className='bg-[#119DEE] px-5 py-[9px] cursor-pointer  outline-none border-none text-white rounded-md flex items-center' onClick={EditJobRoles} >Add</button>
        </div>
        {/* </form> */}
      </Modal>
    </div>
  )
}

export default Manpower