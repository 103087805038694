import React, { lazy } from "react";
import { Route } from "react-router-dom";
import MainMaterialExpense from "../Pages/Web/Progress/MaterialExpense/MainMaterialExpense";
import MaterialExpense from "../Pages/Web/Progress/MaterialExpense";
import CreateMaterialExpense from "../Pages/Web/Progress/MaterialExpense/CreateMaterialExpense";
import EditMaterialExpense from "../Pages/Web/Progress/MaterialExpense/EditMaterialExpense";
import ViewMaterialExpense from "../Pages/Web/Progress/MaterialExpense/ViewMaterialExpense";
import MainPattyCashExpense from "../Pages/Web/Progress/PattyCashExpense/MainPattyCashExpense";
import PattyCashExpense from "../Pages/Web/Progress/PattyCashExpense";
import CreatePattyCashExpense from "../Pages/Web/Progress/PattyCashExpense/CreatePattyCashExpense";
import EditPattyCashExpense from "../Pages/Web/Progress/PattyCashExpense/EditPattyCashExpense";
import ViewPattyCashExpense from "../Pages/Web/Progress/PattyCashExpense/ViewPattyCashExpense";
import { Wrapper } from "../Components/Utils/roles";


const MainProgress = lazy(() => import("../Pages/Web/Progress/MainProgress"));
const MainInstallation = lazy(() => import("../Pages/Web/Progress/Installation/MainInstallation"));
const MainManHour = lazy(() => import("../Pages/Web/Progress/ManHour/MainManHour"));
const Installation = lazy(() => import("../Pages/Web/Progress/Installation"));
const CreateManHour = lazy(() => import("../Pages/Web/Progress/ManHour/CreateManHour"));
const CreateInstallation = lazy(() => import("../Pages/Web/Progress/Installation/CreateInstallation"));
const EditInstalltion = lazy(() => import("../Pages/Web/Progress/Installation/EditInstalltion"));
const ViewInstallation = lazy(() => import("../Pages/Web/Progress/Installation/ViewInstallation"));
const ManHour = lazy(() => import("../Pages/Web/Progress/ManHour"));
const EditManHour = lazy(() => import("../Pages/Web/Progress/ManHour/EditManHour"));
const ViewManHour = lazy(() => import("../Pages/Web/Progress/ManHour/ViewManHour"));


function ProgressRoutes() {
  return (
    <Route path="progress" element={<MainProgress />}>
      <Route path="installation" element={<MainInstallation />}>
       <Route index element={< Wrapper accessKey={true}  Component={Installation} />}/>
       <Route path="create" element={< Wrapper accessKey={true}  Component={CreateInstallation} />} />
       <Route path="edit/:editInstallationId" element={< Wrapper accessKey={true}  Component={EditInstalltion} />} />
       <Route path="view/:viewInstallationId" element={< Wrapper accessKey={true}  Component={ViewInstallation} />} />
      </Route>
      <Route path="man-hour" element={<MainManHour />}>
      <Route index element={< Wrapper accessKey={true}  Component={ManHour} />}/>
       <Route path="create" element={< Wrapper accessKey={true}  Component={CreateManHour} />} />
       <Route path="edit/:editManHourId" element={< Wrapper accessKey={true}  Component={EditManHour} />} />
       <Route path="view/:viewManHourId" element={< Wrapper accessKey={true}  Component={ViewManHour} />} />
      </Route>
      <Route path="material-expense" element={<MainMaterialExpense />}>
      <Route index element={< Wrapper accessKey={true}  Component={MaterialExpense} />}/>
       <Route path="create" element={< Wrapper accessKey={true}  Component={CreateMaterialExpense} />} />
       <Route path="edit/:editMaterialExpenseId" element={< Wrapper accessKey={true}  Component={EditMaterialExpense} />} />
       <Route path="view/:viewMaterialExpenseId" element={< Wrapper accessKey={true}  Component={ViewMaterialExpense} />} />
      </Route>
      <Route path="petty-cash-expense" element={<MainPattyCashExpense />}>
      <Route index element={< Wrapper accessKey={true}  Component={PattyCashExpense} />}/>
       <Route path="create" element={< Wrapper accessKey={true}  Component={CreatePattyCashExpense} />} />
       <Route path="edit/:editPettyCashExpenseId" element={< Wrapper accessKey={true}  Component={EditPattyCashExpense} />} />
       <Route path="view/:viewPettyCashExpenseId" element={< Wrapper accessKey={true}  Component={ViewPattyCashExpense} />} />
      </Route>
    </Route>
  );
}

export default ProgressRoutes;
