import React from "react";

function Payments({ color="#ffffff", width = 14, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 -4 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          {" "}
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-420.000000, -2923.000000)"
            fill={color}
          >
            {" "}
            <g id="icons" transform="translate(56.000000, 160.000000)">
              {" "}
              <path
                d="M372,2769 C372,2767.895 372.895,2767 374,2767 C375.105,2767 376,2767.895 376,2769 C376,2770.105 375.105,2771 374,2771 C372.895,2771 372,2770.105 372,2769 M381,2773 L367,2773 C366.448,2773 366,2772.552 366,2772 L366,2766 C366,2765.448 366.448,2765 367,2765 L381,2765 C381.552,2765 382,2765.448 382,2766 L382,2772 C382,2772.552 381.552,2773 381,2773 M364,2765 L364,2773 C364,2774.105 364.895,2775 366,2775 L382,2775 C383.105,2775 384,2774.105 384,2773 L384,2765 C384,2763.895 383.105,2763 382,2763 L366,2763 C364.895,2763 364,2763.895 364,2765"
                id="money_round-[#1180]"
              >
                {" "}
              </path>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}

export default Payments;
