import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import Dasboard from '../../../../Components/Icons/Dasboard'
import { BackButton } from '../../../../Components/Utils/Buttons'
import { Tab, Tabs } from '../../../../Components/Layouts/Tabs'
import MaterialActivity from './Components/MaterialActivity'
import Manpower from './Components/Manpower'
import ProfitMargin from './Components/ProfitMargin'
import TearmsAndCondition from './Components/TearmsAndCondition'
import EstimateAttachments from './Components/Attachments'
import axiosInstance from '../../../../Services/axiosCommon'
import { useNumber } from '../../../../Context/ProjectIdContext'
import { Select } from 'antd'
import ManPowerTesting from './Components/ManPowerTesting'
import EditMaterialActivity from './EditComponents/MaterialActivity'
import EditManPower from './EditComponents/ManPower'

function ViewEstimationLayout() {
  const { number, updateNumber } = useNumber();
  const [firstProject, setFirstProject] = useState("");
  const location = useLocation();
  const { estimationViewId } = useParams();
  const projectTask = location?.state?.projectTask || "";
  const [projectList, setProjectList] = useState([]);
  // async function getProject() {
  //   await axiosInstance
  //     .get(`/Team/getUserProjectId`)
  //     .then(async (res) => {
  //       if (number != 0) {
  //         setFirstProject(number);
  //       } else if (projectTask) {
  //         console.log("DD", projectTask);
  //         setFirstProject(projectTask);
  //         updateNumber(projectTask);
  //       } else {
  //         res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
  //         updateNumber(res?.data?.data[0]?.ProjectId);
  //         console.log("dd", res?.data?.data[0]?.ProjectId);
  //       }
  //       let list = res?.data?.data || [];
  //       let finalData = [];
  //       (await res?.data?.data) &&
  //         list.map((item, idx) => {
  //           finalData.push({
  //             value: item.ProjectId,
  //             label: item.ProjectName,
  //           });
  //         });
  //       setProjectList(finalData);

  //       // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
  //       // console.log('====',res?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // function handleProjectChange(e) {
  //   setFirstProject(e);
  //   updateNumber(e);
  // }

  // console.log("pro",firstProject);

  // useEffect(()=>{
  //    getProject();
  // },[])

  let {pathname} = useLocation()
  const [module,setModule] = useState("")
  

  useEffect(() =>{
      if(pathname == "/bidding/bids"){
          setModule("Bids")
      }else if(pathname == "/bidding/estimation"){
          setModule("Estimation")
      }
      
  },[pathname])

  console.log("path",pathname);
  

  let LinkItem = [
      {
          label:"Material / Activity",
          link:`/bidding/estimation/view/material/${estimationViewId}`,
          access:true,
      },
      {
          label:"Manpower",
          link:`/bidding/estimation/view/manpower/${estimationViewId}`,
          access:true,
      },
      {
        label:"Profit / Margin",
        link:`/bidding/estimation/view/profit-margin/${estimationViewId}`,
        access:true,
    },
    {
        label:"Terms & Conditions",
        link:`/bidding/estimation/view/terms-conditions/${estimationViewId}`,
        access:true,
    },
    {
        label:"Attachments",
        link:`/bidding/estimation/view/attachments/${estimationViewId}`,
        access:true,
    },
  ]

  
  
  
  return (
    <div className="w-full h-full mx-auto py-2 px-4 ">
        <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/bidding/estimation">
                  <p className=" text-[12px] ">Estimation</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">View</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
        </div>
        {/* <div className=" w-full md:w-3/12 flex gap-2 items-center mb-3">
            <div className="hidden md:block font-medium text-xs">Project </div>
            <Select
              style={{ width: "100%" }}
              options={projectList}
              value={projectList.find((i) => i.value == firstProject) || ""}
              disabled={true}
              // allowClear={formik.values[name]?true:false}
              // name={name}
              // placeholder={placeholder}
              onChange={handleProjectChange}
            />
          </div> */}
        <div
        className=" w-full mx-auto h-[calc(100vh-160px)] rounded-[6px] mt-2 overflow-hidden p-2"
        style={{ border: "1px solid #C5C5C5" }}
      >
            <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
           
            <div className=" w-full h-[calc(100vh-140px)]">
               <Outlet/> 
            </div>
    </div>
    </div>
  )
}

export default ViewEstimationLayout;