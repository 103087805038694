import { notification } from "antd";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  NuDatePicker,
  NuInputEmail,
  // NuInputNumber,
  NuInputText,
  NuLabel,
  NuMobileNumber,
  NuMultiSelect,
  NuSelect,
} from "../../../../Components/Utils/NuInput";
import axios from "../../../../Services/axiosCommon";
import { NuSelectwithSearch } from "../../../../Components/Utils/NuInput";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import Dasboard from "../../../../Components/Icons/Dasboard";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import ProfileImgUpload from "../../../../Components/Utils/ProfileImg";
import NuDate from "../../../../Components/Utils/NuDate";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";

function CreateRentalManpower({ data }) {
  const {value} = useFetchMataData(`/Metadata/clientPosition`)

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  // const [positionList, setpostionList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    email: "",
    mobileNo: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    dateOfJoin:"",
    position: "",
    emergencyName: "",
    emergencyRelationship: "",
    emergencyMobileNo: "",
    location: "",
    state: "",
    city: "",
    pincode: "",
    country: "",
    projectWorked:[],
    currentProject:[],
    type:2,
    category:"Rental",
    nationalId:"",
    nationIdExpiryDate:"",
    passportNumber:"",
    passportExpiryDate:"",
    country_code:""
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  useEffect(() => {
    // memberPosition();
    getAllProject();
  }, []);

  // function memberPosition() {
  //   axios.get(`/Metadata/clientPosition`).then((res) => {
  //     let list = res.data?.data?.keyValues;
  //     let array = [];
  //     list &&
  //       list.forEach((i) => {
  //         let num = { value: i, label: i };
  //         array.push(num);
  //       });
  //     res.data?.data?.keyValues && setpostionList(array);
  //   });
  // }

  function getAllProject() {
    axios.get(`/Team/getUserProjectId`).then((res) => {
      let allProject = res.data?.data;
      let finalData = [];
      res.data?.data &&
        allProject.forEach((i) => {
          let x = {
            value: i.ProjectId,
            label: i.ProjectName,
          };
          finalData.push(x);
        });
      setProjectList(finalData);
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required("Name Required"),
      // dob: yup
      //   .string()
      //   .required("Date of Birth Required"),
        gender: yup.string().required("Gender is Required"),
      mobileNo: yup
        .string()
        .required("Mobile Number is required")
        .matches(
          /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Enter Valid Mobile Number"
        )
        .min(9, "Invalid Mobile Number")
        .max(10, "Invalid Mobile Number"),
      email: yup.string().required("Email is required"),
    }),  
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
      let formData = new FormData();

      formData.append("userData", JSON.stringify(value));
      formData.append("userImage", image.raw);

      data
        ? axios
            .put(`/User/${data?.userId}`, formData)
            .then((res) => {
              setIsLoading(false);
              resetForm();
              navigate(-1);
            })
            .catch((err) => {
              setIsLoading(false);
              console.log(err);
            })
        : axios
            .post("/User/", formData)
            .then((res) => {
              if (res.data?.status) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || "created",
                  placement: "bottomRight",
                });
                resetForm();
                setImage({ preview: "", raw: "" });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });

  function getHOF(){
    let cDate = new Date()
    return cDate.setFullYear(cDate.getFullYear() - 18)
  }

  function handleRemove(){
    if(image.raw){
      setImage({
         preview: "", raw: "" 
      })
    }
  }

  return (
    <div className=" w-full h-full py-2 px-4 overflow-hidden">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Rental Manpower</p> },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />
        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[85vh] pr-2 lg:pr-4">
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex justify-between items-center">
          </div>
          
          <div
            className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3"
            style={{ border: "1px solid #ECEBF3" }}
          >
            <div className=" col-span-3">
            <NuLabel label="Member Details" />
            </div>
            <ProfileImgUpload
              handleChange={handleChangeImg}
              preview={image.preview || ""}
              width=" w-full  row-span-2"
              isRemove={true}
              removeFunction={handleRemove}
            />

            <NuInputText
              label="First Name"
              placeholder="First Name"
              formik={formik}
              name="firstName"
              width=" w-full"
              isRequired={true}
            />

            <NuInputText
              label="Last Name"
              placeholder="Last Name"
              formik={formik}
              name="lastName"
              width=" w-full"
            />
            <NuDatePicker
              label="Date of Birth"
              formik={formik}
              name="dob"
              width=" w-full "
              max={NuDate({
                value: getHOF(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuDatePicker
              label="Date of Join"
              formik={formik}
              name="dateOfJoin"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
            />
            {data && (
              <NuDatePicker
                label="Date of Relieving"
                formik={formik}
                name="dateOfRelieving"
                width=" w-full "
              />
            )}
            <NuSelect
              label="Gender"
              options={[
                { value: "M", label: "Male" },
                { value: "F", label: "Female" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="gender"
              width=" w-full"
              isRequired={true}
            />
            <NuMobileNumber
              nunCode="country_code"
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="mobileNo"
              width=" w-full "
              isRequired={true}
            />
            <NuInputEmail
              label="Email"
              placeholder="example@email.com"
              formik={formik}
              name="email"
              width=" w-full"
              isRequired={true}
            />
            <NuInputText
              label="National ID Number"
              placeholder="National ID Number"
              formik={formik}
              name="nationalId"
              width=" w-full"
            />
            <NuDatePicker
                label="National ID Expiry Date"
                formik={formik}
                name="nationIdExpiryDate"
                width=" w-full "
              />
            <NuInputText
              label="Passport Number"
              placeholder="Passport Number"
              formik={formik}
              name="passportNumber"
              width=" w-full"
            />
            <NuDatePicker
                label="Passport Expiry Date"
                formik={formik}
                name="passportExpiryDate"
                width=" w-full "
              />
            <NuSelectwithSearch
              label="Job Role"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="position"
              width=" w-full"
            />
            <NuMultiSelect
              label="Projects"
              options={projectList}
              formik={formik}
              placeholder="Choose"
              name="currentProject"
              width=" w-full"
            />
            {data && (
              <NuSelect
                label="Status"
                options={[
                  { value: 0, label: "Active" },
                  { value: 1, label: "Inactive" },
                ]}
                formik={formik}
                placeholder="Choose"
                name="isActive"
                width=" w-full"
              />
            )}
          </div>
          <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-8 mt-4 mb-12 md:mr-4">
            <CancelButton handleClick={() => navigate(-1)} />
            <SubmitButton isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateRentalManpower;
