import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import BackupIndex from '../Pages/Settings/Backup'
import ProgressCoating from '../Pages/Settings/Category'
import SettingsPayments from '../Pages/Settings/Payment'
import UnitSetting from '../Pages/Settings/Unit'
import DivisionSettings from '../Pages/Settings/Division'
import BiddingStatus from '../Pages/Settings/BiddingStatus'
import ManPowerRoleIndex from '../Pages/Settings/ManPowerRole'
import CreateManPowerRole from '../Pages/Settings/ManPowerRole/CreateManPowerRole'
import EditManPower from '../Pages/Settings/ManPowerRole/EditManPower'
import { Wrapper } from '../Components/Utils/roles'

const Settings =lazy(()=>import('../Pages/Settings/index')) 
const CompanyInfo =lazy(()=>import('../Pages/Settings/CompanyInfo/companyInfo')) 
const CompanyForm =lazy(()=>import('../Pages/Settings/CompanyInfo/form')) 
const TaskSetting =lazy(()=>import('../Pages/Settings/Tasks')) 
const ProjectSetting =lazy(()=>import('../Pages/Settings/Projects')) 
const AllUsers =lazy(()=>import('../Pages/Settings/AllUsers')) 
const RoleMain =lazy(()=>import('../Pages/Settings/Role/roleMain')) 
const RoleSetting =lazy(()=>import('../Pages/Settings/Role')) 
const CreateRole =lazy(()=>import('../Pages/Settings/Role/createRole')) 
const EditRoleTwo =lazy(()=>import('../Pages/Settings/Role/editRoleTwo')) 
const RoleView =lazy(()=>import('../Pages/Settings/Role/roleView')) 
const NotesSetting =lazy(()=>import('../Pages/Settings/Notes')) 
const ClientSetting =lazy(()=>import('../Pages/Settings/Clients')) 
const NotificationSettings =lazy(()=>import('../Pages/Settings/Notification'))

function SettingsRoutes() {
  return (
    <Route path="settings" element={<Settings />}>
    <Route index element={< Wrapper accessKey={true}  Component={CompanyInfo} />} />
    <Route path="edit" element={< Wrapper accessKey={true}  Component={CompanyForm} />} />
    <Route path="task-settings" element={< Wrapper accessKey={true}  Component={TaskSetting} />} />
    <Route path="project-settings" element={< Wrapper accessKey={true}  Component={ProjectSetting} />} />
    <Route path="all-users" element={< Wrapper accessKey={true}  Component={AllUsers} />} />
    <Route path="roles" element={<RoleMain />}>
      <Route index element={< Wrapper accessKey={true}  Component={RoleSetting} />} />
      <Route path="create" element={< Wrapper accessKey={true}  Component={CreateRole} />} />
      <Route path="edit-role/:roleId" element={< Wrapper accessKey={true}  Component={EditRoleTwo} />} />
      <Route path="role/view/:roleViewId" element={< Wrapper accessKey={true}  Component={RoleView} />} />
    </Route>
    <Route path="man-power-role" element={<RoleMain />}>
       <Route index element={< Wrapper accessKey={true}  Component={ManPowerRoleIndex} />}/>
       <Route path="create" element={< Wrapper accessKey={true}  Component={CreateManPowerRole} />} />
      <Route path="edit/:manPowerRoleEditId" element={< Wrapper accessKey={true}  Component={EditManPower} />} />
      {/* <Route path="role/view/:manPowerRoleViewId" element={<RoleView />} /> */}
    </Route>
    <Route path="notes-settings" element={< Wrapper accessKey={true}  Component={NotesSetting} />} />
    <Route path="client-settings" element={< Wrapper accessKey={true}  Component={ClientSetting} />} />
    <Route path="notification" element={< Wrapper accessKey={true}  Component={NotificationSettings} />} />
    <Route path="backup" element={< Wrapper accessKey={true}  Component={BackupIndex} />} />
    <Route path="payments" element={< Wrapper accessKey={true}  Component={SettingsPayments} />} />
    <Route path="unit" element={< Wrapper accessKey={true}  Component={UnitSetting} />} />
    <Route path="category" element={< Wrapper accessKey={true}  Component={ProgressCoating} />} />
    <Route path="division" element={< Wrapper accessKey={true}  Component={DivisionSettings} />} />
    <Route path="biddingstatus" element={< Wrapper accessKey={true}  Component={BiddingStatus} />} />
  </Route>
  )
}

export default SettingsRoutes