export function MainHeading ({title}){
    return (
        <h1 className=" text-[#231942] capitalize font-bold text-xs lg:text-xs xl:text-xs">{title}</h1>
    )
}

export function SecondHeading ({title}){
    return (
        <h2 className=" text-[#231942] capitalize font-semibold text-xs lg:text-xs xl:text-sm">{title}</h2>
    )
}

export function NormalHeading ({title}){
    return (
        <h3 className=" text-[#231942] capitalize font-bold text-xs lg:text-xs xl:text-xs">{title}</h3>
    )
}

export function SmallHeading ({title}){
    return (
        <h4 className=" text-[#9A95A8] capitalize font-medium text-xs">{title}</h4>
    )
}

export function SmallsubHeading ({title}){
    return (
        <h5 className=" text-[#9A95A8]  capitalize font-normal text-xs">{title}</h5>
    )
}
