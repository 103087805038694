import React from "react";

function Task({ color = "white", width = 12, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41527 5.5007C8.41527 5.35914 8.35904 5.22337 8.25896 5.12326C8.15884 5.02317 8.02308 4.96695 7.88152 4.96695C7.73996 4.96695 7.60419 5.02317 7.50408 5.12326L5.23942 7.38791L4.48401 6.63303C4.38287 6.53818 4.24879 6.48641 4.11016 6.48866C3.97152 6.49091 3.8392 6.54701 3.74119 6.64509C3.64318 6.74316 3.58718 6.87553 3.58503 7.01416C3.58287 7.1528 3.63474 7.28684 3.72966 7.38791L4.86145 8.52077C4.96156 8.62085 5.09733 8.67708 5.23889 8.67708C5.38045 8.67708 5.51622 8.62085 5.61633 8.52077L8.25896 5.87814C8.35904 5.77803 8.41527 5.64226 8.41527 5.5007Z"
        fill={color}
      />
      <path
        d="M1.26489 3.0823V10.5873C1.26489 11.6919 2.16032 12.5873 3.26489 12.5873H5.73506C8.49649 12.5873 10.7351 10.3487 10.7351 7.5873V3.08231C10.7351 1.97774 9.83963 1.08231 8.73506 1.08231H8.70457C8.70457 1.82487 8.1026 2.42683 7.36004 2.42683H4.64141C3.89885 2.42683 3.29688 1.82487 3.29688 1.08231H3.26489C2.16032 1.08231 1.26489 1.97773 1.26489 3.0823Z"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M3.40234 0.628479H8.59791"
        stroke={color}
        stroke-linecap="round"
      />
    </svg>
  );
}

export default Task;
