import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import MainTasksPlan from '../Pages/Web/Tasks/Plan/main'
import TaskPlan from '../Pages/Web/Tasks/Plan'
import CreateTask from '../Pages/Web/Tasks/Components/createTask'
import EditTask from '../Pages/Web/Tasks/Components/editTask'
import CreateSubTask from '../Pages/Web/Tasks/Components/createSubTask'
import EditSubTask from '../Pages/Web/Tasks/Components/editSubTask'






const MainTask =lazy(()=>import("../Pages/Web/Tasks/MainTask"))
const ViewTask  =lazy(()=>import("../Pages/Web/Tasks/Components/viewTask"))
const SubTaskView  =lazy(()=>import("../Pages/Web/Tasks/Components/viewSubTask"))
const MainTaskSchedule = lazy(()=>import('../Pages/Web/Tasks/Schedule/main'))
const TaskSchedule = lazy(()=>import('../Pages/Web/Tasks/Schedule'))
const MainMyTask = lazy(()=>import('../Pages/Web/Tasks/MyTask/MainMyTask'))
const MyTask = lazy(()=>import('../Pages/Web/Tasks/MyTask/index'))
const MainAllTask = lazy(()=>import('../Pages/Web/Tasks/AllTask/MainAllTask'))
const AllTask = lazy(()=>import('../Pages/Web/Tasks/AllTask'))  




function TaskRoutes() {
  return (
    <Route path="tasks" element={<MainTask />}>
    <Route path="view/:taskViewId" element={<ViewTask />} />
    <Route path="subtask/view/:subTaskViewId" element={<SubTaskView />}/>
    <Route path="create" element={<CreateTask />} />
    <Route path="edit/:taskEditId" element={<EditTask />} />    
    <Route path="subtask/create" element={<CreateSubTask />} />
    <Route
      path="subtask/edit/:subTaskEditId"
      element={<EditSubTask />}
    />
    <Route path="task-schedule" element={<MainTaskSchedule />}>
     <Route index element={<TaskSchedule/>} />
    </Route>
    <Route path='my-task' element={<MainMyTask/>}>
     <Route index element={<MyTask/>} />
    </Route>
    <Route path='all-task' element={<MainAllTask/>}>
     <Route index element={<AllTask/>} />
    </Route>
    <Route path='task-plan' element={<MainTasksPlan/>}>
     <Route index element={<TaskPlan/>} />
    </Route>
  </Route>
  )
}

export default TaskRoutes