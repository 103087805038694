import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import NuDate from "../../../Components/Utils/NuDate";
import NuAmount from "../../../Components/Utils/NuAmount";

let DateNow = NuDate({ value: new Date(), format: "DD-MMM-YYYY" });
let TimeNow = NuDate({ value: new Date(), format: "hh:mm:ss a" });

function Date(data) {
  if (data) {
    return NuDate({ value: data, format: "DD-MMM-YYYY" });
  } else {
    return " ";
  }
}

const DownloadPDF = ({
  company = "",
  status,

  data = [],
  Name = "",
  review = "",
  startDate = "",
  endDate = "",
  header = [],
}) => {
  function Header({ companyName, companyImg }) {
    return (
      <View
        style={{
          width: "100%",
          height: "10%",
          flexDirection: "column",
        }}
        fixed
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "35%",
            // backgroundColor:"green"
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: "9px",
                marginBottom: 5,
                textAlign: "center",
                fontWeight: "semibold",
              }}
            >{` Log ${companyName || ""}`}</Text>
          </View>

          <View>
            <Text
              style={{
                fontSize: "10px",
                color: "black",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              {` Company Name ${companyName || ""}`}
            </Text>
          </View>

          <View>
            <Text
              style={{
                fontSize: "7px",
                color: "black",
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              {` ${DateNow} / ${TimeNow}`}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "40%",
            // backgroundColor:"red"
          }}
        >
          <View
            style={{
              width: "20%",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Project : ${100}`}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Status: ${100}`}
            </Text>
          </View>

          <View
            style={{
              width: "20%",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Start Date: ${100}`}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Manpower Count: ${100}`}
            </Text>
          </View>

          <View
            style={{
              width: "20%",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Due Date: ${100}`}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Manhour Used: ${100}`}
            </Text>
          </View>

          <View
            style={{
              width: "20%",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Remaining : ${100}`}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                color: "black",
                fontWeight: "normal",
                textAlign: "left",
                marginVertical: "3px",
              }}
            >
              {`Overall Progress : ${100}`}
            </Text>
          </View>
        </View>

        <View
          style={{
            width: "100%",
            height: "25%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F1E0FF",
          }}
        >
          {header.length > 0 &&
            header.map((item, idx) => {
              return (
                <View
                  key={idx}
                  style={{
                    width: item?.width || "10%",
                    textAlign: item?.rowAlign || "center",
                    paddingVertical: "4px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {item?.title}
                  </Text>
                </View>
              );
            })}
        </View>
      </View>
    );
  }

  function Footer() {
    return (
      <View
        style={{
          width: "100%",
          height: "15%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          position: "absolute",
          bottom: "15px",
          left: "20px",
          // backgroundColor:"red"
        }}
        fixed
      >
        <View style={{
          width: "100%",
          height:"80%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          border:"1px solid black"
          
        }}>
        <View style={{
          width: "50%",
          height:"100%",
          borderRight:"1px solid black",
          padding:"5px"
        }}>
          <Text style={{
            fontSize:"9px",
            fontWeight:"normal"
          }}>Note : </Text>
        </View>
        <View style={{
          width: "50%",
          height:"100%",
          padding:"5px"
        }}>
          <Text style={{
            fontSize:"9px",
            fontWeight:"bold"

          }}>Manpower Detail </Text>
          <View style={{
            display: "flex",
            width: "75%",
            flexDirection:"row",
            justifyContent: "space-between",
            alignItems:"center"
          }}>
            <View>
              <Text 
                style={{ fontSize:"8px",
                         fontWeight:"bold"
                        }}>
                  Designation        
            </Text>
            </View>
            <View>
            <Text 
                style={{ fontSize:"8px",
                         fontWeight:"bold"
                        }}>
                  Direct/In-Direct        
            </Text>
            </View>
            <View>
            <Text 
                style={{ fontSize:"8px",
                         fontWeight:"bold"
                        }}>
                  Manhours        
            </Text>
            </View>
          </View>
        </View>
        </View>

        <View style={{
          width: "100%",
          height:"20%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        
        }}>
                  <View style={{ width: "33.3%" }}>
          <Text
            style={{
              fontSize: "7px",
              color: "grey",
              fontWeight: "bold",
            }}
          >
            {` Prepared by : ${TimeNow}`}
          </Text>
        </View>
        <View
          style={{
            width: "33.3%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "3px",
          }}
        >
          <Text
            style={{
              fontSize: 9,
              textAlign: "center",
              color: "grey",
            }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
        <View
          style={{
            width: "33.3%",
            textAlign: "right",
            gap: "3px",
          }}
        >
          <Text
            style={{
              fontSize: "7px",
              color: "grey",
              fontWeight: "bold",
            }}
          >
            {` ${DateNow} / ${TimeNow}`}
          </Text>
        </View>
        </View>

      </View>
    );
  }

  function getField(row, field, typeValue) {
    let splitValue = field.split(".");
    let value = "";
    if (splitValue.length >= 2) {
      if (splitValue.length == 3) {
        value = row[splitValue[0]][splitValue[1]][splitValue[2]];
      } else {
        value = row[splitValue[0]][splitValue[1]];
      }
    } else {
      value = row[splitValue[0]];
    }

    if (typeValue === "date-time") {
      return (
        (value !== "" && value !== "None" && (
          <NuDate value={value} format="DD-MMM-YYYY hh:mm a" />
        )) ||
        ""
      );
    } else if (typeValue === "date") {
      return (
        (value !== "" && value !== "None" && (
          <NuDate value={value} format="DD-MMM-YYYY" />
        )) ||
        ""
      );
    } else if (typeValue === "time") {
      return (
        (value !== "" && value !== "None" && (
          <NuDate value={value} format="hh:mm a" />
        )) ||
        ""
      );
    } else if (typeValue === "amount") {
      return <NuAmount value={value} /> || "";
    } else {
      return value;
    }
  }

  function MainContent() {
    return (
      <View
        style={{
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderTopWidth: 1,
          borderRightWidth: 1,
          borderBottomWidth: 0,
          borderleftWidth:1
        }}
      >
        {data &&
          data.map((item, idx) => (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                borderStyle: "solid",
                borderWidth: 0.2,
                borderRightWidth: 0,
                borderLeftWidth: 0,
                borderBottomWidth: 0,
              }}
              key={idx}
            >
              {header.map((items, colIndex) => {
                console.log(`width ${colIndex + 1}`, items?.width);
                return (
                  <View
                    style={{
                      width: items?.width || "10%",
                      textAlign: items?.textAlign || "center",
                      paddingVertical: "8px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "8px",
                        color: "black",
                      }}
                    >
                      {items.isCondition
                        ? item[items.field]
                          ? "Yes"
                          : "No"
                        : getField(item, items?.field, items?.type)}
                    </Text>
                  </View>
                );
              })}
            </View>
          ))}
      </View>
    );
  }

  return (
    <Document>
      <Page
        size="A4"
        style={{
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: "15px 20px 40px 20px ",
        }}
        orientation="portrait"
      >
        <Header
          companyName={company?.companyName}
          companyImg={company?.companyLogo}
        />
        <MainContent />
        <Footer startDate={startDate} endDate={endDate} review={review} />
      </Page>
      {/* ))
          } */}
    </Document>
  );
};

export default DownloadPDF;
