import React, { lazy } from "react";
import { Route } from "react-router-dom";
import MainTimesheet from "../Pages/Web/Timesheet/MainTimesheet";
import MainTimesheets from "../Pages/Web/Timesheet/Timesheets/MainTimesheets";
import TimesheetsIndex from "../Pages/Web/Timesheet/Timesheets/TimesheetsIndex";
import MainAttendance from "../Pages/Web/Timesheet/Attendance/MainAttendance";
import AttendanceIndex from "../Pages/Web/Timesheet/Attendance/AttendanceIndex";
import { Wrapper } from "../Components/Utils/roles";

function TimeRoutes() {
  return (
    <Route path="timesheet" element={<MainTimesheet />}>
      <Route path="attendance" element={<MainAttendance />}>
        <Route index element={< Wrapper accessKey={true}  Component={AttendanceIndex} />} />
        {/* <Route path="create" element={<NoteCreate />} />
        <Route path="edit/:attendanceEditId" element={<NoteEdit />} />
        <Route path="view/:attendanceViewId" element={<NotesView />} /> */}
      </Route>
      <Route path="timesheets" element={<MainTimesheets />}>
        <Route index element={< Wrapper accessKey={true}  Component={TimesheetsIndex} />} />
      </Route>
    </Route>
  );
}

export default TimeRoutes;
