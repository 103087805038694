import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from '../../../../Services/axiosCommon';
import NuPills from '../../../../Components/Utils/Nupills';
import NuDate from '../../../../Components/Utils/NuDate';
import { BackButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dasboard from '../../../../Components/Icons/Dasboard';

function UserView() {

  const { id } = useParams();
  const [user, setUser] = useState([]);

  const getUserDetails = async () => {
    await axios
      .get(`/User/${id}`)
      .then(async (res) => {
        (await res?.data?.data) && setUser(res?.data?.data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div className='px-4 py-2'>
      <div className="md:flex justify-between w-full">
                <BreadCrumb
            items={[
              {
                title: (
                  <Link to="/dashboard">
                    <Dasboard color="gray" width={10} height={10} />{" "}
                  </Link>
                ),
              },
              { title: <Link to="/dashboard"><p className=" text-[12px] ">Rental Manpower</p></Link> },
              { title: <p className=" text-[12px] ">Profile View</p> },
            ]}
          />
           <div className=" flex gap-2 items-center mr-6">
              <BackButton />
            </div>
          </div>
      <div className="w-full my-1 flex justify-between items-start">
      <h4 className=" hidden lg:block text-sm font-semibold">Rental Manpower  Profile</h4>
      </div>
      <div className=' w-full py-7 h-5/6 lg:h-3/6 flex rounded-3xl mt-4 border-solid bg-white border-8 border-blue-600 border-r-0 border-y-0 shadow-lg'>
        <div className=' w-2/6 lg:w-[30%] px-4  flex flex-col justify-center border-r-2 border-solid border-l-0 border-y-0  border-r-gray-50'>
        <li className=' flex justify-end my-2 items-center'>
                <div className=' text-center'>
                  <NuPills
                    width=' w-16'
                    bgColor={user.isActive ?'bg-[#E7F9E7]':"bg-[#FFEFF2]"}
                    textColor={user.isActive ?'text-[#39D42B]':"text-[#FF4493]"}
                    fontWight=' font-semibold'
                    fontSize='text-[12px]'
                  >
                    {user.isActive ? 'Active' : 'Inactive'}
                  </NuPills>
                </div>
              </li>
          <div className=' w-36 h-36 rounded-full bg-[#BFD0FF] flex justify-center items-center mx-auto'>
            <div className=' w-[124px] h-[124px] rounded-full bg-[#A1B9FF] flex justify-center items-center'>
            <div className=' w-[110px] h-[110px] rounded-full bg-[#3267FF] font-medium text-white text-3xl capitalize flex justify-center items-center overflow-hidden'>
                  {user.imageUrl!=null?<img src={user.imageUrl} width='140%' className=' rounded-full' alt={'userimage'} />:`${user?.firstName?user?.firstName.charAt(0):"#"}`}
                </div>
            </div>
          </div>
          <div className='text-center'>
            <ul className='list-none pt-5'>
              <li>
                <p
                  className='text-sm text-mainColor-400 font-semibold'
                >
                  {user?.firstName} {user?.lastName}
                </p>
              </li>
              <li>
                <p className=' font-medium text-xs text-mainColor-400'>{user?.position}</p>
              </li>

            </ul>
          </div>
        </div>
        <div className=' h-full w-full px-6 ml-10'>
          <div className='w-full lg:h-1/3 '>
            <div>
              <ul className='list-none grid grid-cols-3 lg:grid-cols-6 gap-3 '>
                <li className=' w-full h-full flex flex-col'>
                <div className=' lg:my-6'>
                <h5 className=' my-1 font-semibold text-maingray-500 text-xs'>Date Of Birth</h5>
                  <p className=' text-maingray-300 text-xs'> {user?.dob?(<NuDate value={user?.dob} format="DD-MMM-YYYY" />):"-"} </p>
                </div>
                </li>

                <li className=' w-full h-full flex flex-col'>
                <div className=' lg:my-6'>
                <h5 className=' my-1 font-semibold text-maingray-500 text-xs'>Date Of Join</h5>
                  <p className=' text-maingray-300 text-xs'> {user?.dateOfJoin?(<NuDate value={user?.dateOfJoin} format="DD-MMM-YYYY" />):"-"} </p>
                </div>
                </li>

                <li className=' w-full h-full flex flex-col'>
                <div className=' lg:my-6'>
                <h5 className=' my-1 font-semibold text-maingray-500 text-xs'>Date Of Releiving</h5>
                  <p className=' text-maingray-300 text-xs'> {user?.dateOfRelieving?(<NuDate value={user?.dateOfRelieving} format="DD-MMM-YYYY" />):"-"} </p>
                </div>
                </li>

                <li className=' w-full h-full flex flex-col'>
                <div className=' lg:my-6'>
                <h5 className=' my-1 font-semibold text-maingray-500 text-xs'>Gender</h5>
                  <p className=' text-maingray-300 text-xs'> {user?.gender==='M'?"Male":"Female"} </p>
                </div>
                </li>

                <li className=' w-full h-full flex flex-col'>
                <div className=' lg:my-6'>
                <h5 className=' my-1 font-semibold text-maingray-500 text-xs'>Age</h5>
                  <p className=' text-maingray-300 text-xs'> {user?.dob?calculateAge(user?.dob):'-'} </p>
                </div>
                </li>
              </ul>
            </div>
          </div>
          <p className='w-[80%] '
           style={{ borderTop: "1px solid #ECEBF3", paddingTop: "5px" }}></p>
          <div className='w-full lg:h-1/3 '>
            <h1 className='my-3 lg:my-0 font-semibold text-mainColor-400 text-sm'>Contact</h1>

            <div>
              <ul className='list-none grid grid-cols-3 lg:grid-cols-6 gap-3'>
              <li className=' w-full h-full flex flex-col'>
                <div className=' lg:my-6'>
                <h5 className=' my-1 font-semibold text-maingray-500 text-xs'>Mobile</h5>
                  <p className=' text-maingray-300 text-xs'> {user?.mobileNo?(`+ ${user?.country_code||""} ${user?.mobileNo}`):'-'} </p>
                </div>
                </li>

                <li className=' w-full h-full flex flex-col'>
                <div className=' lg:my-6'>
                <h5 className=' my-1 font-semibold text-maingray-500 text-xs'>Email</h5>
                  <p className=' text-maingray-300 text-xs'> {user?.email?(user?.email):'-'} </p>
                </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserView;
