import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { Link, useLocation } from 'react-router-dom'
import Dasboard from '../../../../Components/Icons/Dasboard'
import SearchBar from '../../../../Components/Utils/SearchBar'
import { CreateButton } from '../../../../Components/Utils/Buttons'
import axiosInstance from '../../../../Services/axiosCommon'
import { useFormik } from 'formik'
import { useNumber } from '../../../../Context/ProjectIdContext'
import * as yup from "yup";
import Pagination from '../../../../Components/Utils/Pagenation'
import NuDate from '../../../../Components/Utils/NuDate'
import NuAmount from '../../../../Components/Utils/NuAmount'
import ViewMore from './viewMore'
import BiddingLayout from '../Biddinglayout'
import { GetAccess } from '../../../../Components/Utils/roles'


function EstimationIndex() {
    const location = useLocation();
    const projectTask = location?.state?.projectTask || "";
    const { number, updateNumber } = useNumber();
    const [percentOpen, setPercentOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [dataCount, setDataCount] = useState(20);
    const [totalTasks, setTotalProject] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [projectList, setProjectList] = useState([]);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState([]);
    const [priority, setPriority] = useState([]);
    const [firstProject, setFirstProject] = useState("");
    const [isFilter, setIsFilter] = useState(false);
    const [notFirst, setNotFirst] = useState(false);
    const [filterData, setFilterData] = useState({
      SDF: "",
      EDT: "",
      status: "",
      priority: "",
    });
    const [afterFilter, setAfterFilter] = useState({});
    const [screenSize, setScreenSize] = useState({
      width: window.innerWidth,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setScreenSize({
          width: window.innerWidth,
        });
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    async function getProject() {
      await axiosInstance
        .get(`/Team/getUserProjectId`)
        .then(async (res) => {
          if (number != 0) {
            setFirstProject(number);
          } else if (projectTask) {
            setFirstProject(projectTask);
          } else {
            res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
            updateNumber(res?.data?.data[0]?.ProjectId);
          }
          let list = res?.data?.data || [];
          let finalData = [];
          (await res?.data?.data) &&
            list.map((item, idx) => {
              finalData.push({
                value: item.ProjectId,
                label: item.ProjectName,
              });
            });
          setProjectList(finalData);
  
          // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
          // console.log('====',res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    const getStatus = () => {
        axiosInstance
        .get(`/Metadata/taskstatus`)
        .then((res) => {
          let data = res.data?.data?.keyValues;
          let tempData = [];
          data.forEach((item) => {
            let tempObject = { value: item, label: item };
            tempData.push(tempObject);
          });
          res.data?.data?.keyValues && setStatus(tempData);
        })
        .catch((error) => {
          console.log("dddd");
        });
    };
  
    const getPriority = () => {
        axiosInstance
        .get(`/Metadata/taskpriority`)
        .then((res) => {
          let data = res.data?.data?.keyValues;
          let tempData = [];
          data.forEach((item) => {
            let tempObject = { value: item, label: item };
            tempData.push(tempObject);
          });
          res.data?.data?.keyValues && setPriority(tempData);
        })
        .catch((error) => {
          console.log("dddd");
        });
    };
  
    useEffect(() => {
      getStatus();
      getProject();
      getPriority();
    }, []);
  
    function SearchData(e) {
      setNotFirst(true);
      e.preventDefault();
      if (searchQuery != "") {
        axiosInstance
          .get(
            `/estimation/?page=${pageIndex}&per_page=${dataCount}`,
            { params: { search: `${searchQuery}` } }
          )
          .then((res) => {
            setData(res.data?.data);
            setTotalProject(res.data?.total_records);
          })
          .catch((err) => console.log(err));
      } else {
        getTask(firstProject);
      }
    }
  
    function SearchPageDate() {
        axiosInstance
        .get(
          `/mytask/byUser/members_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,
          { params: { search: `${searchQuery}` } }
        )
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.totalRecords);
        })
        .catch((err) => console.log(err));
    }
  
    function handleProjectChange(e) {
      setFirstProject(e);
      updateNumber(e);
    }
  
    function getTask() {
        axiosInstance
        .get(`/estimation/?page=${pageIndex}&per_page=${dataCount}`)
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.total_records);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    useEffect(() => {
      if (searchQuery == "") {
        getTask();
      }
    }, [searchQuery]);
  
    const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    let MyTaskCreate = userAccess
      ? userAccess.some(
          (permission) => permission.permissionName == "MyTask_create"
        )
      : false;
  
    useEffect(() => {
      // console.log("jj",projectNum);
      if (projectTask) {
        setFirstProject(projectTask);
        // console.log("gf", firstProject);
      }
    }, [projectTask]);
  
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterData,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        mobileNo: yup
          .string()
          // .required("Mobile Number is required")
          .matches(
            /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Enter Valid Mobile Number"
          )
          .min(10, "Invalid Mobile Number")
          .max(10, "Invalid Mobile Number"),
        // email: yup.string().required("Email is required"),
      }),
      onSubmit: async (value, { resetForm }) => {
        setAfterFilter(value);
        setIsFilter(true);
        axiosInstance
          .get(
            `/mytask/byUser/members_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,
            {
              params: {
                status: `${value.status}`,
                SDF: `${value.SDF}`,
                EDT: `${value.EDT}`,
                priority: `${value.priority}`,
              },
            }
          )
          .then((res) => {
            setFilterOpen(false);
            setData(res.data?.data);
            setTotalProject(res.data?.total_records);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });
  
    function FilterPage() {
      axiosInstance
        .get(
          `/mytask/byUser/members_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,
          {
            params: {
              status: `${afterFilter.status}`,
              SDF: `${afterFilter.SDF}`,
              SDT: `${afterFilter.SDT}`,
              EDF: `${afterFilter.EDF}`,
              EDT: `${afterFilter.EDT}`,
              priority: `${afterFilter.priority}`,
            },
          }
        )
        .then((res) => {
          setData(res.data?.data);
          setTotalProject(res.data?.total_records);
        })
        .catch((err) => console.log(err));
    }
  
    function ClearFilter() {
      formik.resetForm({});
      getTask(firstProject);
      setIsFilter(false);
    }
  
    useEffect(() => {
      if (isFilter) {
        FilterPage();
      } else if (searchQuery != "") {
        SearchPageDate();
      } else {
        getTask();
      }
    }, [pageIndex, dataCount]);
  
    console.log("fff",data);
  return (
    <>
       <BiddingLayout
                setOpen={setOpen}
                setSearchQuery={setSearchQuery}
                SearchData={SearchData}
                isFilter={isFilter}
                setFilterOpen={setFilterOpen}
                ClearFilter={ClearFilter}
                CreateAccess={GetAccess("estimation_create")}
                ImportAccess={GetAccess("estimation_create")}
    >

<div className=" w-full h-[calc(100vh-150px)] ">
    <div className=" w-full h-[calc(100vh-170px)] overflow-y-auto">
          <table
            className="table-auto w-full"
            style={{ border: "none", borderCollapse: "collapse" }}
          >
            <thead className=" w-full bg-[#2A475E]">
              <tr className="text-xs  font-medium  py-3">
                {header.map((header, i) => (
                  <th
                    className={`py-2 ${header.width} px-1 font-normal ${
                      header?.isLeft ? "text-left" : "text-center"
                    } text-xs text-white`}
                    key={i}
                    style={{ border: "none", borderCollapse: "collapse" }}
                  >
                    {header.headone}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {data &&
                data.map((item, i) => (
                  <tr className=" w-full " key={i}>
                    <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                      {item?.serial_number|| "#"}
                    </td>
                    <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                      {item?.projectName || "-"}
                    </td>
                    <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                      <div className=" w-full flex justify-center items-center">
                        <ViewMore id={item?.id||""} getData={getTask} data={item} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table> 
        </div>

        <div className=" h-6 my-2">
          <Pagination
            total={totalTasks}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            dataCount={dataCount}
            setDataCount={setDataCount}
          />
        </div>
    </div>
    </BiddingLayout>


    
</>
  )
}

export default EstimationIndex



const header = [
    {
      headone: "Sl.NO",
      width: "w-[5%]",
    },
    {
      headone: "Project",
      width: "w-[90%]",
      isLeft: true,
    },
    {
      headone: "Action",
      width: "w-[5%]",
    },
  ];