import { Modal, notification } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import axios from "../../../../../Services/axiosCommon";
import { useNavigate, useParams } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import DeleteIcon from "../../../../../Components/Icons/Delete";
import {
  NuInputNumber,
  NuInputText,
  NuSelectwithSearch,
} from "../../../../../Components/Utils/NuInput";
import { CancelButton, SubmitButton } from "../../../../../Components/Utils/Buttons";
import { useNumber } from "../../../../../Context/ProjectIdContext";

const { confirm } = Modal;

function EditTermsAndCondition({projectId}) {
    const { number, updateNumber } = useNumber();
    const { estimationEditId } = useParams();
    let estId = Number(estimationEditId)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [image,setImage] = useState([]);
    const [createNew, setCreateNew] = useState({
      estimationId: estId|| "",
      items:[],
      projectId: ""
      });

    const [termsAndConditionData, setTermsAndConditionData] = useState({
      description: "",
      item_code: ""
      });

    const [termsAndConditionList,setTermsAndConditionList] = useState([]);
     console.log("bvbvbv",termsAndConditionList);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: true,
        validationSchema: yup.object({
          // projectName: yup.string().required("Project Name is Required"),
          // clientId: yup.string().required("Client "),
        }),
        onSubmit: async (value) => {
          setIsLoading(true);
          const term = {
            ...value,
            items: termsAndConditionList,
            projectId: number
          }
            axios
                .post("/estimation/terms-and-conditions", term)
                .then((res) => {
                  if (res.status == 201 || res.data.status == true) {
                    setIsLoading(false);
                    setImage([]);
                    api.success({
                      message: `Add successfully`,
                      description: res?.data?.msg || " created",
                      placement: "bottomRight",
                    });
                    // navigate(-1);
                    navigate(`/bidding/estimation/edit/attachments/${estId}`);
                  } else {
                    setIsLoading(false);
                    api.error({
                      message: `Unsuccessfully`,
                      description: res?.data?.msg || "creation failed",
                      placement: "bottomRight",
                    });
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  api.error({
                    message: `Unsuccessfully`,
                    description: err?.response?.data?.msg || "creation failed",
                    placement: "bottomRight",
                  });
                });
        },
      });
      const [activity,setActivity] = useState([]);
      const [itemCode,setItemCode] = useState("");
      async function getActivity(disc) {
        axios.get(`/Activity/drop-down`).then(res => {
            // console.log('daataa',res.data?.data);
            let data = res.data.data;
            console.log(data,'dddd');
            let tempData = [];
            data.forEach((item) => {
                let tempObject = { value: item.value, label: item.label };
                tempData.push(tempObject);
            });
            res.data.data && setActivity(tempData);
            const filterData = data.filter(item => item.value === disc)
            .map(item => item.item_code);
            setItemCode(filterData[0] || '')
        }).catch(err => {
            console.log(err, "err");
        })
    }

    useEffect(()=>{
      getActivity(formik.values.description);
    },[formik.values.description])

    function AddTermsAndCondition() {
        const tempValue = {
          description: formik.values.description || "",
          item_code: itemCode || ""
        };
        setTermsAndConditionList([...termsAndConditionList, tempValue]);
        formik.setFieldValue("description", "");
      }

     async function handleDelete(index,value) {
         confirm({
            title: `Do you want to Delete ${value}?`,
            centered: true,
            icon: <ExclamationCircleFilled />,
            content: ``,
            onOk() {
                const updatedList = termsAndConditionList.filter((_, i) => i !== index);
                setTermsAndConditionList(updatedList);
            },
            onCancel(){

            }
        })
      }


      useEffect(()=>{
        getTermsAndConditionList(estId)
      },[estId])

      function getTermsAndConditionList(id){
        axios.get(`/estimation/terms-and-conditions/${id}`).then(res=>{
            setTermsAndConditionList(res.data.data);
        })
      }

  return (
    <div className=" w-full h-[calc(100vh-160px)] overflow-hidden">
      <form onSubmit={formik.handleSubmit}>
        <div className=" w-full flex gap-2 items-center">
        <NuSelectwithSearch
            label="Description"
            options={activity}
            isRequired={true}
            placeholder="Description"
            formik={formik}
            name="description"
            width="w-full md:w-1/2 lg:w-[70%]"
          />
          <button
            type="button"
            className="w-12 mt-6 p-1 my-3 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
            //   className="w-14 my-3 p-1 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
            onClick={AddTermsAndCondition}
          >
            +
          </button>
        </div>
        <div className="w-full h-[calc(100vh-340px)] overflow-y-auto">
        <table className=" w-full border-collapse">
            <thead className="sticky top-0">
              <tr className="bg-[#1B2838]">
               <th className=" w-[5%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Sl.NO
                </th>
                <th className=" w-[10%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Item Code
                </th>
                <th className=" w-[80%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Description
                </th>
                <th className=" w-[5%]  py-2 text-center text-white text-xs font-medium">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {termsAndConditionList?.map((list, index) => (
                <tr
                  key={index}
                  className="w-full  odd:bg-white even:bg-slate-50"
                >
                  <td className="w-[5%] pl-1 py-2 text-left text-black text-xs font-light">
                    {index + 1}
                  </td>
                  <td className="w-[10%] pl-1 py-2 text-left text-black text-xs font-light">
                    {list?.item_code || "-"}
                  </td>
                  <td className="w-[80%] pl-1 py-2 text-left text-black text-xs font-light">
                    {list?.description || "-"}
                  </td>
                  <td className="w-[5%] ">
                    <div className=" w-full flex justify-center items-center">
                      <button
                        onClick={() => handleDelete(index,list?.termsAndCondition)}
                        type="button"
                        className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                      >
                        <DeleteIcon color="red" height={4} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <div className='w-full h-8'>
    {/* Footer or additional content goes here */}
  </div>
  <div className='w-full flex gap-2 justify-end items-center'>
    <CancelButton handleClick={() => navigate(-1)} />
    <SubmitButton isLoading={isLoading} />
  </div>
      </form>
    </div>
  )
}

export default EditTermsAndCondition