import { Button, Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  NuDatePicker,
  NuInputEmail,
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuMobileNumber,
  NuMultiSelect,
  NuSelect,
} from "../../../../Components/Utils/NuInput";
import axios from "../../../../Services/axiosCommon";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dasboard from "../../../../Components/Icons/Dasboard";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import ProfileImgUpload from "../../../../Components/Utils/ProfileImg";
import NuDate from "../../../../Components/Utils/NuDate";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";

function EditRentalManpower({}) {
  const {value} = useFetchMataData(`/Metadata/clientPosition`)
  const location = useLocation();
  const data = location.state?.data;
  console.log("Sss",data);
  
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  // const [positionList, setpostionList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgRemove,setimgRemove] =useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    email: "",
    mobileNo: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    dateOfJoin:"",
    position: "",
    emergencyName: "",
    emergencyRelationship: "",
    emergencyMobileNo: "",
    location: "",
    state: "",
    city: "",
    pincode: "",
    country: "",
    projectWorked:[],
    currentProject:[],
    type:2,
    category:"Rental",
    nationalId:"",
    nationalIdExpiryDate:"",
    passportNumber:"",
    passportExpiryDate:"",
    country_code:""
  });

  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  useEffect(() => {
    data &&
      setCreateNew({
        email: data?.email,
        mobileNo: data?.mobileNo,
        firstName: data?.firstName,
        lastName: data?.lastName,
        gender: data?.gender,
        dob: data?.dob,
        dateOfJoin: data?.dateOfJoin,
        dateOfRelieving: data?.dateOfRelieving,
        roleId: null,
        position: data?.position,
        emergencyName: data?.emergencyName,
        emergencyRelationship: data?.emergencyRelationship,
        emergencyMobileNo: data?.emergencyMobileNo,
        location: data?.location,
        state: data?.state,
        city: data?.city,
        pincode: data?.pincode,
        country: data?.country,
        imageUrl: data?.imageUrl,
        currentProject: data?.currentProject,
        country_code:data?.country_code,
        type:data?.type,
        category:data?.category,
        nationalId:  data?.nationalId,
        nationalIdExpiryDate:data?.nationalIdExpiryDate && NuDate({value:data?.nationalIdExpiryDate,format:"YYYY-MM-DD"}),
        passportNumber:data?.passportNumber,
        passportExpiryDate:data?.passportExpiryDate && NuDate({value:data?.passportExpiryDate,format:"YYYY-MM-DD"}),
      });
    // memberPosition();

    console.log("leo ",data);
    
    getAllProject();
  }, [data]);

  // function memberPosition() {
  //   axios.get(`/Metadata/clientPosition`).then((res) => {
  //     let list = res.data?.data?.keyValues;
  //     let array = [];
  //     list &&
  //       list.forEach((i) => {
  //         let num = { value: i, label: i };
  //         array.push(num);
  //       });
  //     res.data?.data?.keyValues && setpostionList(array);
  //   });
  // }

  function getAllProject() {
    axios.get(`/Team/getUserProjectId`).then((res) => {
      let allProject = res.data?.data;
      let finalData = [];
      res.data?.data &&
        allProject.forEach((i) => {
          let x = {
            value: i.ProjectId,
            label: i.ProjectName,
          };
          finalData.push(x);
        });
      setProjectList(finalData);
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      firstName: yup.string().required("Name Required"),
      // dob: yup.string().required("Date of Birth Required"),
      gender: yup.string().required("Gender is Required"),
      mobileNo: yup
        .string()
        .required("Mobile Number is required")
        .matches(
          /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Enter Valid Mobile Number"
        )
        .min(9, "Invalid Mobile Number")
        .max(10, "Invalid Mobile Number"),
      email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
      if(imgRemove){
        value.imageUrl = ""
      }
      let formData = new FormData();

      formData.append("userData", JSON.stringify(value));
      formData.append("userImage", image.raw);

      data
        ? axios
            .put(`/User/${data?.userId}`, formData)
            .then((res) => {
              if(res.data.status==true){
                setIsLoading(false);
                setImage({ preview: "", raw: "" });
                resetForm();
                api.success({
                  message: `Update successfully`,
                  description: res?.data?.msg || "created",
                  placement: "bottomRight",
                });
                navigate(-1);
              }else{
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/User/", formData)
            .then((res) => {
              if (res.data?.status) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || "created",
                  placement: "bottomRight",
                });
                resetForm();
                setImage({ preview: "", raw: "" });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });

  function getHOF() {
    let cDate = new Date();
    return cDate.setFullYear(cDate.getFullYear() - 18);
  }


  function handleRemove(){
    if(image.raw){
      setImage({
         preview: "", raw: "" 
      })
    }else if(data?.imageUrl){
      setimgRemove(true)
      delete data?.imageUrl
    }
  }

  return (
    <div className=" w-full h-full px-4 py-2 overflow-hidden">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Rental Manpower</p> },
            { title: <p className=" text-[12px] ">Edit</p> },
          ]}
        />
        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div className=" overflow-y-scroll h-[85vh] pr-2 lg:pr-4">
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex justify-between items-center"></div>
          <div
            className=" w-full grid rounded-lg mt-2 mb-6 grid-cols-3"
            style={{ border: "1px solid #ECEBF3" }}
          >
            <div className=" col-span-3">
              <NuLabel label="Member Details" />
            </div>
            <ProfileImgUpload
              handleChange={handleChangeImg}
              preview={image.preview || data?.imageUrl || ""}
              width=" w-full  row-span-2"
              isRemove={true}
              removeFunction={handleRemove}
            />

            <NuInputText
              label="First Name"
              isRequired={true}
              placeholder="First Name"
              formik={formik}
              name="firstName"
              width=" w-full"
            />

            <NuInputText
              label="Last Name"
              placeholder="Last Name"
              formik={formik}
              name="lastName"
              width=" w-full"
            />
            <NuDatePicker
              label="Date of Birth"
              formik={formik}
              name="dob"
              width=" w-full "
              max={NuDate({
                value: getHOF(),
                format: "yyyy-MM-DD",
              })}
              // isRequired={true}
            />
            <NuDatePicker
              label="Date of Join"
              formik={formik}
              name="dateOfJoin"
              width=" w-full "
              max={NuDate({
                value: Date.now(),
                format: "yyyy-MM-DD",
              })}
            />
            {data && (
              <NuDatePicker
                label="Date of Relieving"
                formik={formik}
                name="dateOfRelieving"
                width=" w-full "
              />
            )}
            <NuSelect
              label="Gender"
              options={[
                { value: "M", label: "Male" },
                { value: "F", label: "Female" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="gender"
              width=" w-full"
              isRequired={true}
            />
            <NuMobileNumber
              label="Mobile"
              isRequired={true}
              placeholder="Mobile"
              formik={formik}
              name="mobileNo"
              nunCode="country_code"
              width=" w-full "
            />
            <NuInputEmail
              label="Email"
              isRequired={true}
              placeholder="example@email.com"
              formik={formik}
              name="email"
              width=" w-full"
            />
              <NuInputText
              label="National ID Number"
              placeholder="National ID Number"
              formik={formik}
              name="nationalId"
              width=" w-full"
            />
            <NuDatePicker
                label="National ID Expiry Date"
                formik={formik}
                name="nationalIdExpiryDate"
                width=" w-full "
              />
            <NuInputText
              label="Passport Number"
              placeholder="Passport Number"
              formik={formik}
              name="passportNumber"
              width=" w-full"
            />
            <NuDatePicker
                label="Passport Expiry Date"
                formik={formik}
                name="passportExpiryDate"
                width=" w-full "
              />

            <NuSelect
              label="Job Role"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="position"
              width=" w-full"
            />
            <NuMultiSelect
              label="Projects"
              options={projectList}
              formik={formik}
              placeholder="Choose"
              name="currentProject"
              width=" w-full"
            />
          </div>
          <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-4 mb-4 md:mr-4">
            <CancelButton handleClick={() => navigate(-1)} />
            <SubmitButton isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditRentalManpower;
