import React, { useRef } from 'react'

function CustomImgUpload({preview,handleChange,width,label,isRemove,removeFunction=()=>{}}) {
    const fileInputRef=useRef();
  return (
    <div className={`${width} text-xs`}>
            <div className=' mt-2 px-3 flex items-center'>
              <div>
      {preview ? (
        <div className=" w-16 h-16 rounded-full flex justify-center items-center overflow-hidden">
          <img src={preview} alt="dummy"  width="120%" />
        </div>
        ) : (
          <>
            <div className=" w-16 h-16 rounded-full overflow-hidden border-2 border-[#3267ff] border-dotted bg-[#eff3f9]">
            
            </div>
            
          </>
        )}
      </div>
     <div className=" border-none outline-none px-4 mx-3 py-2 text-white  cursor-pointer rounded-lg bg-[#3267ff] " onClick={()=>fileInputRef.current.click()}>
        {label?label:`Upload Your Image`}
      </div>
      {
        isRemove && preview && <div className=" border-none outline-none px-4 mx-3 py-2 text-white  cursor-pointer rounded-lg bg-[#ff6232] " onClick={removeFunction}>Remove</div>
      }
      <input onChange={handleChange} multiple={false} ref={fileInputRef} type='file' accept='image/*' hidden/>
    </div>
    </div>
  )
}

export default CustomImgUpload