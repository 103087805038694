import { Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { BackButton } from "./Buttons";

function PageNotFound() {
  return (
    <div className=" w-[100vw] h-screen flex justify-center items-center">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <div className=" w-full flex justify-center gap-3 items-center">
            {" "}
            <BackButton />{" "}
            <Link to="/dashboard">
              <button className="bg-mainColor-500 px-5 py-[6px] cursor-pointer  outline-none border-none text-white rounded-md flex items-center">
                Home
              </button>
            </Link>{" "}
          </div>
        }
      />
    </div>
  );
}

export default PageNotFound;
