import { Outlet } from "react-router-dom"
const MainAttendance = () =>{
    return(
        <div className=" w-full h-full">
         <Outlet/>
        </div>
    )
}


export default MainAttendance;