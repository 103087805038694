import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { Wrapper } from "../Components/Utils/roles";

const MainTeam = lazy(() => import("../Pages/Web/Teams/teamMain"));
const Teams = lazy(() => import("../Pages/Web/Teams"));
const AddMember = lazy(() => import("../Pages/Web/Teams/AddMember"));
const EditMemberTeam = lazy(() => import("../Pages/Web/Teams/EditMember"));
const TeamView = lazy(() => import("../Pages/Web/Teams/TeamView"));

function TeamRoutes() {
  return (
    <Route path="teams" element={<MainTeam />}>
      <Route index element={ < Wrapper accessKey={true}  Component={Teams} /> } />
      <Route path="add-members" element={< Wrapper accessKey={true}  Component={AddMember} />} />
      <Route path="edit-members" element={< Wrapper accessKey={true}  Component={EditMemberTeam} />} />
      <Route path="view" element={< Wrapper accessKey={true}  Component={TeamView} />} />
    </Route>
  );
}

export default TeamRoutes;
