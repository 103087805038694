import React, { useState } from 'react'
import ToggleUp from '../../../Components/Icons/ToggleUp';
import ToggleDown from '../../../Components/Icons/ToggleDown';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';
import Plus from '../../../Components/Icons/Plus';
import ViewMoreTask from '../../Web/Tasks/Components/viewMoreTask';
import NuDate from '../../../Components/Utils/NuDate';
import CreatePercentage from '../../Web/Tasks/Components/createTaskPlanPercentage';
import MobileSubTaskData from './SubTaskData';

function MobileTaskData({taskData, getData, projectId}) {
    const [isTaskExpanded, setIsTaskExpanded] = useState(false);
    const [percentOpen, setPercentOpen] = useState(false);
  
    const handleToggle = () => {
      setIsTaskExpanded(!isTaskExpanded);
    };
  
    function DateDiff(createDate, expiryDate) {
      createDate = new Date(createDate);
      expiryDate = new Date(expiryDate);
      let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays == 0 ? diffDays + 1 : diffDays + 1;
    }
  
    const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    let MySubTaskCreate = userAccess
      ? userAccess.some(
          (permission) => permission.permissionName == "MySub_task_create"
        )
      : false;
  
  return (
    <div className=' m-[3px] bg-white rounded-md p-1' style={{border:'.5px solid gray'}}>
      <div className=' w-full flex px-1 bg-slate-100 items-center justify-between'>
        <div className=' w-[2%] '>
        {taskData?.subTaskDetails && taskData?.subTaskDetails.length > 0 && (
            <div className=" w-7 h-7 rounded-md cursor-pointer " onClick={handleToggle}>
              {isTaskExpanded ? <ToggleUp /> : <ToggleDown />}
            </div>
          )}
        </div>
        <div className=' w-[5%] text-center '>
         <span className='px-2 py-[1px] font-semibold my-1  bg-slate-300'>
          {
            taskData?.taskNumber||""
          }
         </span>
         </div>
        <div className=' w-[70%] px-1'>
        <Link
            className="text-sm text-left font-normal text-mainColor-400 line-clamp-1"
            to={`/tasks/view/${taskData?.taskId}`}
            state={{projectId:projectId}}
          >
            {taskData?.taskName || "-"}
          </Link>
        </div>
        <div className=' w-[10%]'>
        <div className=" w-full flex gap-2 justify-end px-3 cursor-pointer items-center">
           <div className=" w-[13px] h-[13px] flex justify-center items-center">
           {MySubTaskCreate && <Link to="/tasks-plan/subtask/create" state={{ id: taskData?.taskId,projectId:projectId }}>
              <Plus color="#3267FF" />
            </Link>}
            </div> 
          </div>
        </div>
        <div className=''>
          <ViewMoreTask data={taskData} id={taskData?.taskId} projectId={projectId} getClients={getData} />
        </div>
      </div>
      <div className=' w-full flex px-1 py-1 items-center text-xs justify-between'>
        <div>
            <p className=' font-semibold'>Start Date</p>
            {taskData?.startDate != "None" || taskData?.startDate != " " ? (
            <NuDate value={taskData?.startDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </div>
        <div>
            <p className=' font-semibold'>Due Date</p>
            {taskData?.endDate != "None" || taskData?.endDate != " " ? (
            <NuDate value={taskData?.endDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </div>
        <div>
            <p className=' font-semibold'>Priority</p>
            {taskData?.priority || "-"}
        </div>
        <div className=' w-[10%]'>
        <Progress
              onClick={() => {
                setPercentOpen(true);
              }}
              type="circle"
              size={30}
              strokeWidth={12}
              format={(percent) => (
                <p
                  className={` ${
                    taskData?.taskCompletion > 95
                      ? "text-[#87d068]"
                      : taskData?.taskCompletion > 50
                      ? "text-[#108ee9]"
                      : "text-[#D2BD00]"
                  } text-xs font-semibold`}
                >
                  {percent}
                </p>
              )}
              percent={Math.floor(taskData?.taskCompletion) || 0}
              strokeColor={
                taskData?.taskCompletion > 95
                  ? {
                      "0%": "#87d068",
                      "100%": "#87d068",
                    }
                  : taskData?.taskCompletion > 50
                  ? {
                      "0%": "#108ee9",
                      "100%": "#108ee9",
                    }
                  : {
                      "0%": "#D2BD00",
                      "100%": "#D2BD00",
                    }
              }
            />
        </div>
      </div>
      {isTaskExpanded &&
        taskData?.subTaskDetails.map((item, i) => (
            <MobileSubTaskData subTaskData={item || ""} key={i} getData={getData} projectId={projectId} />
        //   <ExpandableSubTask subTaskData={item || ""} key={i} getData={getData} projectId={projectId} />
        ))}
      <CreatePercentage
        projectId={projectId}
        open={percentOpen}
        onCancel={() => {
          setPercentOpen(false);
        }}
        taskId={taskData?.taskId}
        getData={getData}
        list={[]}
        data={taskData?.taskCompletion}
      />
    </div>
  )
}

export default MobileTaskData;