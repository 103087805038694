import Dashboard from "../Icons/Dasboard";
import Members from "../Icons/Member";
import Notes from "../Icons/Notes";
import Project from "../Icons/Project";
import Task from "../Icons/Task";
import Team from "../Icons/Team";
import PlanTask from "../Icons/PlanTask";
import Report from "../Icons/Report";
import Costing from "../Icons/Costing";
import Progress from "../Icons/Progress";
import Payments from "../Icons/Payments";
import Bidding from "../Icons/Bidding";
import Items from "../Icons/Items";
import Timesheet from "../Icons/TimeSheet";
import PettyCash from "../Icons/PettyCash";
import { GetAccess } from "./roles";

export const NavListItems = [
  {
    /*dashboard*/
    link: "/dashboard",
    icon: (
      <>
        <Dashboard />
      </>
    ),
    title: "Dashboard",
    access: true,
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link: GetAccess("activity_item_view")?"/item/activity":GetAccess("terms_and_condition_view")?"/item/teamandcondition":"#",
    icon: (
      <>
        <Items />
      </>
    ),
    title: "Item",
    access: GetAccess("item_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link:GetAccess("bids_view")?"/bidding/bids":GetAccess("estimation_view")?"/bidding/estimation":"#" ,
    icon: (
      <>
        <Bidding />
      </>
    ),
    title: "Bidding",
    access: GetAccess("bidding_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link:GetAccess("project_view") ?"/projects":"#",
    icon: (
      <>
        <Project />
      </>
    ),
    title: "Projects",
    access: GetAccess("project_module"),
    hasSubMenu: false,
  },
  {
    link: GetAccess("manpower_view")?"/costing/man-power":GetAccess("material_view")?"/costing/material":GetAccess("others_view")?"/costing/others":"#",
    icon: (
      <>
        <Costing />
      </>
    ),
    title: "Budget",
    access: GetAccess("budget_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link:GetAccess("activities_view")?"/tasks/task-plan":GetAccess("timeline_view")?"/tasks/task-schedule":"#",
    icon: (
      <>
        <PlanTask />
      </>
    ),
    title: "Planning",
    access: GetAccess("planning_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link: GetAccess("my_task_view")?"/tasks/my-task":GetAccess("all_task_view")?"/tasks/all-task":"#",
    icon: (
      <>
        <Task />
      </>
    ),
    title: "Tasks",
    access: GetAccess("task_module"),
    hasSubMenu: false,
    subMenu: [],
  },

  {
    link:GetAccess("man_hour_view")?"/progress/man-hour":GetAccess("installation_view")?"/progress/installation":"#",
    icon: (
      <>
        <Progress />
      </>
    ),
    title: "Progress",
    access: GetAccess("progress_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link:GetAccess("incoming_view")?"/payments/invoice-in":GetAccess("outgoing_view")?"/payments/invoice-out":GetAccess("other_expenses_view")?"/payments/petty-cash":"#",
    icon: (
      <>
        <Payments />
      </>
    ),
    title: "Finance",
    access: GetAccess("finance_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link: GetAccess("received_view")?"/petty-cash/received":GetAccess("issued_view")?"/petty-cash/issued":"#",
    icon: (
      <>
        <PettyCash />
      </>
    ),
    title: "Petty Cash",
    access: GetAccess("petty_cash_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link: GetAccess("my_diary_view")?"/mainNotes":"#",
    icon: (
      <>
        <Notes />
      </>
    ),
    title: "My Diary",
    access: GetAccess("my_diary_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link:GetAccess("employees_view")?"/contacts/members":GetAccess("rental_manpower_view")?"/contacts/rental-man":GetAccess("vendor_view")?"/contacts/vendor":GetAccess("client_view")?"/contacts/clients":GetAccess("sub_contractor_view")?"/contacts/sub-con":"#",
    icon: (
      <>
        <Members />
      </>
    ),
    title: "Contacts",
    access: GetAccess("contacts_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link:GetAccess("attendance_view")?"timesheet/attendance":GetAccess("timesheets_view")?"/timesheet/timesheets":"#",
    icon: (
      <>
        <Timesheet />
      </>
    ),
    title: "Timesheet",
    access: GetAccess("timesheet_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link: GetAccess("teams_module")?"/teams":"#",
    icon: (
      <>
        <Team />
      </>
    ),
    title: "Teams",
    access: GetAccess("teams_module"),
    hasSubMenu: false,
    subMenu: [],
  },
  {
    link: GetAccess("report_module")?"/reports":"#",
    icon: (
      <>
        <Report />
      </>
    ),
    title: "Reports",
    access: GetAccess("report_module"),
    hasSubMenu: false,
    subMenu: [],
  },
];
