import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import Header from './Components/Layouts/Header';
// import Sidebar from './Components/Layouts/Sidebar';
import Dasboard from './Components/Icons/Dasboard';
import { Suspense } from 'react';
import { SidebarItem } from './Components/Layouts/TestSliderBar';
import Sidebar from './Components/Layouts/TestSliderBar';
import { NavListItems } from './Components/Utils/NewNavItems';






function App() {
  const location = useLocation();
  const { Content } = Layout;

  function isActiveFun(x){
    let actualPath = location.pathname.split("/")[1]
    let linkPath = ""
    if(x?.hasSubMenu){
      if(x?.subMenu){
         let y = x.subMenu[0].link;
         let z = y.split("/")[0]
         linkPath = z
      }
    }else{
      linkPath = x?.link;
    }
    if(actualPath == linkPath) {
      return true;
    }else{
      return false;
    }
  }

  return (
    <div className=' w-[100vw] h-screen'>
      <Layout className=' w-full h-screen'>
      <Sidebar>
        {NavListItems &&
          NavListItems.map((item) => {
            return (
              <SidebarItem
                icon={item?.icon}
                text={item?.title}
                hasSubMenu={item?.hasSubMenu}
                subItem={item?.subMenu}
                link={item?.link}
                active={isActiveFun(item)}
                access={item?.access}
                //  alert = "test"
              />
            );
          })}
      </Sidebar>
        <Layout>
          <Header />
          <Content className=' bg-white'>
          <Suspense fallback={<div className=' w-full h-full flex justify-center items-center'>Loading...</div>}>
          <Outlet />
          </Suspense>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
