import React from "react";

function SubCon({ color = "white", width = 12, height = 13 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6721 11.4272C11.6845 11.4069 11.691 11.3835 11.691 11.3597C11.691 11.3359 11.6845 11.3126 11.6721 11.2922L11.2221 10.4222C11.2176 10.4025 11.2176 10.382 11.2221 10.3622V8.11227C11.2221 7.35228 10.4671 6.66229 9.64215 5.9673C9.6282 5.95568 9.61695 5.94116 9.60918 5.92476C9.60141 5.90835 9.5973 5.89045 9.59715 5.8723V5.6123C9.59715 5.51284 9.55764 5.41746 9.48732 5.34714C9.41699 5.27681 9.32161 5.2373 9.22216 5.2373C9.1227 5.2373 9.02732 5.27681 8.95699 5.34714C8.88667 5.41746 8.84716 5.51284 8.84716 5.6123V6.87728C8.8482 6.9104 8.86055 6.94216 8.88216 6.96728L10.1121 8.19727C10.1824 8.26758 10.2218 8.36289 10.2218 8.46226C10.2218 8.56164 10.1824 8.65695 10.1121 8.72726C10.0418 8.79748 9.94652 8.83693 9.84715 8.83693C9.74777 8.83693 9.65246 8.79748 9.58215 8.72726L8.54716 7.68727C8.44289 7.63427 8.32593 7.61134 8.20936 7.62105C8.09279 7.63076 7.98123 7.67274 7.88717 7.74227C7.81547 7.79476 7.75599 7.86217 7.71283 7.93985C7.66967 8.01754 7.64386 8.10365 7.63718 8.19227C7.62929 8.28325 7.64081 8.37486 7.67098 8.46106C7.70114 8.54725 7.74927 8.62606 7.81217 8.69226L9.18716 10.0922C9.20879 10.1154 9.22123 10.1456 9.22216 10.1772V10.8772C9.28831 11.307 9.44881 11.7168 9.69215 12.0772C9.70061 12.0932 9.71271 12.107 9.72748 12.1174C9.74224 12.1278 9.75926 12.1346 9.77715 12.1372C10.1275 12.1944 10.4867 12.159 10.8191 12.0345C11.1516 11.9099 11.4456 11.7006 11.6721 11.4272Z"
        fill={color}
      />
      <path
        d="M0.00763463 1.37234L0.457629 2.24232C0.46213 2.26207 0.46213 2.28258 0.457629 2.30232V4.55229C0.457629 5.35228 1.29262 6.05228 2.16261 6.80727C2.17744 6.81809 2.18933 6.83245 2.19719 6.84904C2.20505 6.86564 2.20863 6.88393 2.20761 6.90226V11.5522C2.20956 11.6507 2.23089 11.7478 2.2704 11.8381C2.3099 11.9283 2.3668 12.0099 2.43784 12.0782C2.50888 12.1464 2.59267 12.2 2.68442 12.2359C2.77618 12.2717 2.8741 12.2892 2.9726 12.2872H8.47253C8.60513 12.2872 8.73231 12.2345 8.82607 12.1408C8.91984 12.047 8.97252 11.9198 8.97252 11.7872C8.97252 11.6546 8.91984 11.5274 8.82607 11.4337C8.73231 11.3399 8.60513 11.2872 8.47253 11.2872H3.47259C3.40629 11.2872 3.3427 11.2609 3.29582 11.214C3.24893 11.1671 3.22259 11.1035 3.22259 11.0372V6.03728C3.16945 6.01879 3.12147 5.98795 3.08259 5.94728L1.70761 4.5673C1.64137 4.49621 1.60531 4.40219 1.60703 4.30504C1.60874 4.20789 1.6481 4.1152 1.7168 4.04649C1.78551 3.97779 1.8782 3.93843 1.97535 3.93672C2.0725 3.935 2.16652 3.97106 2.23761 4.0373L3.23759 5.03729C3.32918 5.06748 3.42652 5.07606 3.52198 5.06237C3.61744 5.04868 3.70844 5.01308 3.78785 4.95836C3.86727 4.90365 3.93294 4.83129 3.97973 4.74697C4.02653 4.66264 4.05317 4.56863 4.05758 4.4723C4.06547 4.38132 4.05395 4.2897 4.02378 4.2035C3.99361 4.11731 3.94549 4.03851 3.88258 3.9723L2.4726 2.55232V1.78733C2.40264 1.36323 2.24236 0.959109 2.00261 0.602346C1.99334 0.587031 1.981 0.573797 1.96637 0.563472C1.95175 0.553148 1.93515 0.545956 1.91761 0.542347C1.56861 0.484381 1.21055 0.517945 0.878404 0.639761C0.54626 0.761576 0.251399 0.967475 0.0226344 1.23734C0.00842726 1.25651 -0.000445617 1.2791 -0.00308051 1.30281C-0.00571541 1.32653 -0.00201818 1.35052 0.00763463 1.37234Z"
        fill={color}
      />
      <path
        d="M7.34761 7.28729C7.34761 7.18783 7.3081 7.09245 7.23778 7.02213C7.16745 6.9518 7.07207 6.91229 6.97262 6.91229H4.22265C4.1232 6.91229 4.02782 6.9518 3.95749 7.02213C3.88716 7.09245 3.84766 7.18783 3.84766 7.28729C3.84766 7.38674 3.88716 7.48212 3.95749 7.55245C4.02782 7.62277 4.1232 7.66228 4.22265 7.66228H6.97262C7.07167 7.66099 7.1663 7.62106 7.23635 7.55102C7.30639 7.48097 7.34632 7.38634 7.34761 7.28729Z"
        fill={color}
      />
      <path
        d="M4.22265 8.92725C4.1232 8.92725 4.02782 8.96675 3.95749 9.03708C3.88716 9.1074 3.84766 9.20279 3.84766 9.30224C3.84766 9.4017 3.88716 9.49708 3.95749 9.5674C4.02782 9.63773 4.1232 9.67724 4.22265 9.67724H5.97263C6.07208 9.67724 6.16747 9.63773 6.23779 9.5674C6.30812 9.49708 6.34762 9.4017 6.34762 9.30224C6.34762 9.20279 6.30812 9.1074 6.23779 9.03708C6.16747 8.96675 6.07208 8.92725 5.97263 8.92725H4.22265Z"
        fill={color}
      />
      <path
        d="M9.34759 2.78732C9.34759 2.29288 9.20097 1.80953 8.92627 1.39841C8.65157 0.987297 8.26113 0.66687 7.80432 0.477653C7.34751 0.288437 6.84485 0.238929 6.35991 0.335391C5.87496 0.431853 5.42951 0.669951 5.07988 1.01958C4.73025 1.3692 4.49216 1.81466 4.39569 2.2996C4.29923 2.78455 4.34874 3.28721 4.53796 3.74402C4.72717 4.20083 5.0476 4.59127 5.45872 4.86597C5.86984 5.14067 6.35318 5.28729 6.84763 5.28729C7.51066 5.28729 8.14653 5.0239 8.61537 4.55507C9.0842 4.08623 9.34759 3.45036 9.34759 2.78732ZM8.27261 2.14733L7.09262 3.72231C7.02924 3.80682 6.94692 3.87528 6.85226 3.92217C6.7576 3.96906 6.65326 3.99309 6.54763 3.99231C6.45495 3.99568 6.36259 3.97963 6.27648 3.94518C6.19037 3.91074 6.11242 3.85867 6.04764 3.79231L5.45764 3.18232C5.4208 3.14799 5.39125 3.10659 5.37075 3.06059C5.35026 3.01459 5.33924 2.96493 5.33835 2.91458C5.33746 2.86423 5.34672 2.81422 5.36558 2.76752C5.38444 2.72083 5.41252 2.67841 5.44812 2.64281C5.48373 2.6072 5.52615 2.57912 5.57284 2.56026C5.61954 2.5414 5.66955 2.53214 5.7199 2.53303C5.77025 2.53392 5.81991 2.54494 5.86591 2.56543C5.91191 2.58593 5.95331 2.61548 5.98764 2.65232L6.43763 3.10232C6.45047 3.11574 6.46604 3.12626 6.48329 3.13316C6.50054 3.14006 6.51907 3.14318 6.53763 3.14232C6.55532 3.14085 6.5725 3.13564 6.58802 3.12701C6.60353 3.11839 6.61704 3.10657 6.62763 3.09232L7.67261 1.69734C7.73229 1.61777 7.82112 1.56517 7.91958 1.55111C8.01803 1.53704 8.11804 1.56266 8.19761 1.62234C8.27717 1.68201 8.32977 1.77085 8.34384 1.8693C8.3579 1.96776 8.33228 2.06777 8.27261 2.14733Z"
        fill={color}
      />
    </svg>
  );
}

export default SubCon;
