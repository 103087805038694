import { Outlet } from "react-router-dom"


const MainPettyCash = () =>{

    return(
        <div className=" w-full h-full">
         <Outlet/>
        </div>
    )
}


export default MainPettyCash;