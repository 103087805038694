import { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import {  notification } from "antd";
import axios from "../../../../Services/axiosCommon";
import * as yup from "yup";
import {
  NuDatePicker,
  NuTimePicker,
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuSelect,
  NuTextArea,
  NuMultiSelect,
} from "../../../../Components/Utils/NuInput";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";

const EditTask = ({}) => {
  const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const location = useLocation();
  const data = location.state.data;
  console.log("data in task", data);
  const ProjectId = location.state.projectId;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { taskEditId } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [priority, setPriority] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [membersAll,setMembersAll] =useState([]);
  const [members, setMembers] = useState([]);
  const [team,setTeam] = useState([]);
  const [isLoadingAllmembers,setLoadingAllmembers] = useState(false);
  const [isLoadingMembers,setLoadingMembers] = useState(false);
  const [isLoadingTeams,setLoadingTeams] = useState(false);
  const [taskStatus, setTaskStatus] = useState([]);
  const [createNew, setCreateNew] = useState({
    projectId: "",
    titleId:"",
    taskName: '',
    assignedTo: [],
    copyTo: [],
    priority: "",
    severity: "",
    taskStatus: "",
    // comment: "",
    startDate:"",
    endDate: "",
    reminderDate: "",
    reminderTime: "",
    attachment: "",
    reAssignedTo:[],
    taskTeam: "",
    taskDesc:"",
    isInstEnabled:false,
    unit:"",
    quantity:null,
    today:null,
    completed:null,
    remaining:null,
    actualCompleteDate:""
  });
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const getMembers =(id)=>{
    // console.log('getmember');
    axios.get(`/Team/getUserByTeamId/${id}`).then((response)=>{
     console.log("hhh",response.data.data)
     let FinalData = []
     let status = response.data.data;
     status.forEach((item) => {
      console.log("ddd",item.memberId);
       let tempObject = { value: item.userId, label: `${item.firstName} ${item.lastName}` };
       FinalData.push(tempObject);
     });
     response.data.data && setMembers(FinalData);
     setLoadingMembers(true)
  
    }).catch((error)=>{
      setLoadingMembers(true)
      console.log(error);
    });
  };


  const getAllMembers =()=>{
    // console.log('getmember');
    axios.get(`/DropDown/team_member/${ProjectId}`).then((response)=>{
    //  console.log("hhh",response.data.data)
     let FinalData = []
     let status = response.data.data;
     status.forEach((item) => {
      console.log("ddd",item.memberId);
       let tempObject = { value: item.userId, label: item.Name };
       FinalData.push(tempObject);
     });
     response.data.data && setMembersAll(FinalData);
     setLoadingAllmembers(true)
  
    }).catch((error)=>{
      console.log(error);
      setLoadingAllmembers(true)
    });
  };


  const getTeam =()=>{
    // console.log('getmember');
    axios.get(`/Team/byProjectId/${ProjectId}`).then((response)=>{
    //  console.log("hhh",response.data.data)
     let FinalData = []
     let status = response.data?.data?.teamsList;
     status.forEach((item) => {
      // console.log("ddd",item.memberId);
       let tempObject = { value: item.teamId, label: item.teamName };
       FinalData.push(tempObject);
     });
     response.data.data && setTeam(FinalData);
     setLoadingTeams(true)
  
    }).catch((error)=>{
     setLoadingTeams(true)
      console.log(error);
    });
  };
  const getTaskPriroty = async () => {
    await axios
      .get("/Metadata/taskpriority")
      .then((response) => {
        console.log(response.data?.data?.keyValues);
        let FinalData = [];
        let status = response.data?.data?.keyValues;
        status.forEach((item) => {
          let tempObject = { value: item, label: item };
          FinalData.push(tempObject);
        });
        response.data?.data && setPriority(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTaskSeverity = async () => {
    await axios
      .get("/Metadata/taskseverity")
      .then((response) => {
        console.log(response.data?.data?.keyValues);
        let FinalData = [];
        let status = response.data?.data?.keyValues;
        status.forEach((item) => {
          let tempObject = { value: item, label: item };
          FinalData.push(tempObject);
        });
        response.data?.data && setSeverity(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTaskStatus = async () => {
    await axios
      .get("/Metadata/taskstatus")
      .then((response) => {
        console.log(response.data?.data?.keyValues);
        let FinalData = [];
        let status = response.data?.data?.keyValues;
        status.forEach((item) => {
          let tempObject = { value: item, label: item };
          FinalData.push(tempObject);
        });
        response.data?.data && setTaskStatus(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // getMembers();
    getTaskPriroty();
    getTaskSeverity();
    getTaskStatus();
    getTeam();
    getAllMembers();
    data &&
      setCreateNew({
        taskName: data?.taskName,
        taskTeam: data?.teamId,
        assignedTo: data?.assignedTo?.map((i, k) => {
          return i?.userId;
        }),
        copyTo: data?.copyTo?.map((i, k) => {
          return (
            // i?.firstName
            i?.userId
          );
        }),
        priority: data?.priority,
        severity: data?.severity,
        taskStatus: data?.taskStatus,
        revisedEndDate: data?.revisedEndDate,
        reasonForDateRevised: data?.reasonForDateRevised,
        reAssignedTo: data?.reAssignedTo,
        comment: data?.comment,
        startDate: data?.startDate,
        endDate: data?.endDate,
        reminderDate: data?.reminderDate,
        reminderTime: data?.reminderTime,
        // attachment: data?.attachment,
        projectId: data?.projectId,
        titleId: data?.titleId,
        taskDesc:data?.taskDesc,
        isInstEnabled:data?.isInstEnabled,
        unit:data?.unit||"",
        quantity:data?.quantity||null,
        today:data?.today||null,
        completed:data?.completed||null,
        remaining:data?.remaining||null,
        actualCompleteDate:data?.actualCompleteDate||""
      });
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      taskName: yup
        .string()
        .required("Task Name is Required"),
      // assignedTo: yup.array()
      //  .of(yup.string()
      //  .required("AssignedTo is Required")),
      // copyTo: yup.array()
      //  .of(yup.string()
      //  .required("AssignedTo is Required")),
      startDate: yup
       .date()
       .required("Start Date is Required"),
      endDate: yup
       .date()
       .required("Due Date is Required"),
      // reminderDate: yup
      //  .string()
      //  .required("reminderDate is Required"),
      // reminderTime: yup
      //  .string()
      //  .required("reminderTime is Required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);
        value.projectId =ProjectId;
        // value.titleId = stateData.titleId;
      let formData = new FormData();
      if(value.reminderDate=="None"){
        value.reminderDate=""
      } 
      if(value.reminderTime=="None"){
        value.reminderTime=""
      }
      value.reAssignedTo =[]

      formData.append("taskData", JSON.stringify(value));
      formData.append("attachment", image.raw);

      // console.log("finding", formData);
      data
        ? axios
            .put(`/Task/${taskEditId}`, formData)
            .then((res) => {
              if (res.status === 201 || res.status === 200) {
                setIsLoading(false);
                navigate(-1)
                api.success({
                  message: `Task  Updated successfully`,
                  // description:'',
                  placement: "bottomRight",
                });
              } else {
                setIsLoading(false);
                api.error({
                  message: `Task Update failed`,
                  description: res.data.msg || "Network error",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              console.log("ll",err.response.data);
              api.error({
                message: `Task Update failed`,
                description: err.response.data.msg || "Network error",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/Task/", formData)
            .then((res) => {
              if (res.status === 201 || res.status === 200) {
                // console.log(res);
                navigate(-1)
                resetForm();
                api.success({
                  message: `Task  Created successfully`,
                  // description:'',
                  placement: "bottomRight",
                });
              } else {
                api.error({
                  message: `Task Created failed`,
                  description: res.data.msg | "Network error",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
    },
  });
  function diff_weeks(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    //  let result = Math.abs(Math.round(diff));
    return Math.abs(Math.round(diff));
  }
  function DateDiff(createDate, expiryDate) {
    // createDate = new Date(createDate);
    // expiryDate = new Date(expiryDate);
    let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays + 1;
  }
  useMemo(() => {
    if (formik.values.startDate && formik.values.endDate) {
      let startDate = new Date(formik.values.startDate);
      let endDate = new Date(formik.values.endDate);
      formik.values.noOFWeeks = diff_weeks(startDate, endDate);
      formik.values.noOFDays = DateDiff(startDate, endDate);
    }
  }, [formik.values.startDate, formik.values.endDate]);

  useMemo(()=>{
    getMembers(formik.values.taskTeam)
    // formik.values.assignedTo = []
  },[formik.values.taskTeam])
  return (
    <div className="px-4 py-2">
      {contextHolder}
      <BreadCrumb
        items={[
          {
            title: (
              <Link to="/dashboard">
                <Dasboard color="gray" width={10} height={10} />{" "}
              </Link>
            ),
          },
          { title: <p className=" text-[12px] ">Tasks</p> },
          { title: <p className=" text-[12px] ">Edit</p> },
        ]}
      />
      {
        (isLoadingAllmembers == true && isLoadingMembers == true && isLoadingTeams) ? (
          <div className="w-full h-[85vh]  overflow-y-scroll ">
          <form onSubmit={formik.handleSubmit}>
            <div
              className=" w-full flex flex-wrap rounded-lg overflow-y-scroll h-full"
              style={{ border: "1px solid #ECEBF3" }}
            >
              <div className=" w-full">
                <NuLabel label="Task Details" />
              </div>
              <NuInputText
                label="Task Name"
                placeholder="Task Name"
                formik={formik}
                name="taskName"
                width=" w-full md:w-6/12"
                isRequired={true}
              />
            <NuSelect
                label='Team'
                options={team}
                formik={formik}
                placeholder='Choose'
                name='taskTeam'
                width='w-full md:w-3/12'
                isRequired={true}
              />
              <NuMultiSelect
                label='Assignee'
                options={members}
                formik={formik}
                placeholder='Choose'
                name='assignedTo'
                width='w-full md:w-3/12'
              />
              <NuMultiSelect
                label="Copy To"
                options={membersAll}
                formik={formik}
                placeholder="Choose"
                name="copyTo"
                width=" w-full md:w-3/12"
              />
              <NuDatePicker
                label="Start Date"
                formik={formik}
                name="startDate"
                width=" w-full md:w-1/4"
                isRequired={true}
              />
              <NuDatePicker
                label="Due Date"
                formik={formik}
                name="endDate"
                width=" w-full md:w-1/4"
                isRequired={true}
              />
              <NuSelect
                label="Priority"
                options={priority}
                formik={formik}
                placeholder="Choose"
                name="priority"
                width=" w-full md:w-3/12"
              />
              {/* <NuSelect
                label="Severity"
                options={severity}
                formik={formik}
                placeholder="Choose"
                name="severity"
                width=" w-full md:w-3/12"
              /> */}
  
              <NuInputNumber
                label="No.Week"
                placeholder="0"
                disable={true}
                formik={formik}
                name="noOFWeeks"
                width=" w-full md:w-3/12"
              />
              <NuInputNumber
                label="No.Days"
                placeholder="0"
                disable={true}
                formik={formik}
                name="noOFDays"
                width=" w-full md:w-3/12"
              />
              <NuSelect
                label="Task Status"
                options={taskStatus}
                formik={formik}
                placeholder="Choose"
                name="taskStatus"
                width=" w-full md:w-3/12"
              />
              {/* <NuDatePicker
                label="Revised End Date"
                formik={formik}
                name="revisedEndDate"
                width=" w-full md:w-3/12"
              /> */}
              <NuDatePicker
                label="Reminder Date"
                formik={formik}
                name="reminderDate"
                width=" w-full md:w-1/4"
              />
              <NuTimePicker
                label="Reminder Time"
                formik={formik}
                name="reminderTime"
                width=" w-full md:w-1/4"
              />

              <NuDatePicker
                label="Actual Complete Date"
                formik={formik}
                name="actualCompleteDate"
                width=" w-full md:w-1/4"
              />

<div className="  flex  items-center my-3 px-3 gap-4 relative">
                  <p className=" text-xs">Installation Enabled</p>
                  <input
                    class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-[#BFD0FF] checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-[#1292DC] checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type="checkbox"
                    checked={formik.values.isInstEnabled}
                    name="isInstEnabled"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    role="switch"
                  />
                </div>

            {
              formik.values.isInstEnabled && <>
              
              <NuSelect
              label='Unit'
              options={Unit}
              formik={formik}
              placeholder='Choose'
              name='unit'
              width='w-full md:w-3/12'
            />
            <NuInputNumber label='Quantity' placeholder='0' formik={formik} name='quantity' width='w-full md:w-3/12' />

              </>
            }
              {/* <Button></Button> */}
              <NuTextArea label="Description" placeholder="Enter Description" formik={formik} name="taskDesc" width=' w-full' />
  
              {/* <NuTextArea
                label="Comment"
                placeholder="Comment"
                formik={formik}
                name="reasonForDateRevised"
                width=" w-full md:w-6/12"
              /> */}
            </div>
  
            {/* <div
              className="w-full flex flex-wrap rounded-lg my-2 "
              style={{ border: "1px solid #ECEBF3" }}
            >
              <div className=" w-full">
                <NuLabel label="Reminder" />
              </div>
  
  
            </div> */}
  
            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
        ) : (<div className="W-full h-full flex justify-center items-center">Loading...</div>)
      }

    </div>
  );
};

export default EditTask;
