import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import MainRentalManpower from '../Pages/Web/Contacts/RentalManpower/main'
import RentalManpower from '../Pages/Web/Contacts/RentalManpower'
import CreateRentalManpower from '../Pages/Web/Contacts/RentalManpower/createRentalManpower'
import EditRentalManpower from '../Pages/Web/Contacts/RentalManpower/editRentalManpower'
import RentalManpowerProfile from '../Pages/Web/Contacts/RentalManpower/rentalManpowerProfile'
import { Wrapper } from '../Components/Utils/roles'


const MainContacts = lazy(()=>import('../Pages/Web/Contacts/MainContacts'))
const MainClient =lazy(()=>import('../Pages/Web/Contacts/Clients/clientMain')) 
const Client =lazy(()=>import('../Pages/Web/Contacts/Clients/index')) 
const CreateClients =lazy(()=>import('../Pages/Web/Contacts/Clients/createClient')) 
const EditClients =lazy(()=>import('../Pages/Web/Contacts/Clients/editClient')) 
const ClientView =lazy(()=>import('../Pages/Web/Contacts/Clients/clientView')) 
const MainVendor =lazy(()=>import('../Pages/Web/Contacts/Vendor/vendorMain')) 
const Vendor =lazy(()=>import('../Pages/Web/Contacts/Vendor/index')) 
const CreateVendor =lazy(()=>import('../Pages/Web/Contacts/Vendor/createVendor')) 
const EditVendor =lazy(()=>import('../Pages/Web/Contacts/Vendor/editVendor')) 
const VendorView =lazy(()=>import('../Pages/Web/Contacts/Vendor/vendorView')) 
const MainSubCon =lazy(()=>import('../Pages/Web/Contacts/SubCon/subConMain')) 
const SubCon =lazy(()=>import('../Pages/Web/Contacts/SubCon')) 
const CreateSubCon =lazy(()=>import('../Pages/Web/Contacts/SubCon/createSubCon')) 
const SubConView =lazy(()=>import('../Pages/Web/Contacts/SubCon/subConView')) 
const EditSubCon = lazy(()=>import('../Pages/Web/Contacts/SubCon/editSubCon'))
const MainMember =lazy(()=>import('../Pages/Web/Contacts/Members/main'))
const Members = lazy(()=>import('../Pages/Web/Contacts/Members/index'))
const CreateMember = lazy(()=>import('../Pages/Web/Contacts/Members/createMember'))
const Profile = lazy(()=>import('../Pages/Web/Contacts/Members/memberProfile'))
const EditMember = lazy(()=>import('../Pages/Web/Contacts/Members/editMember'))

function ContactsRoutes() {
  return (
    <Route path="contacts" element={<MainContacts/>} >
     <Route path="clients" element={<MainClient />}>
      <Route index element={< Wrapper accessKey={true}  Component={Client} /> } />
      <Route path="create" element={< Wrapper accessKey={true}  Component={CreateClients} /> } />
      <Route path="edit/:clientEditId" element={< Wrapper accessKey={true}  Component={EditClients} />} />
      <Route path="view/:clientViewId" element={< Wrapper accessKey={true}  Component={ClientView} /> } />
     </Route>
     <Route path="vendor" element={<MainVendor />}>
    <Route index element={< Wrapper accessKey={true}  Component={Vendor} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateVendor} />} />
    <Route path="edit/:vendorEditId" element={< Wrapper accessKey={true}  Component={EditVendor} />} />
    <Route path="view/:vendorViewId" element={< Wrapper accessKey={true}  Component={VendorView} />} />
  </Route>
  <Route path="sub-con" element={<MainSubCon />}>
    <Route index element={< Wrapper accessKey={true}  Component={SubCon} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateSubCon} />} />
    <Route path="edit/:subConEditId" element={< Wrapper accessKey={true}  Component={EditSubCon} />} />
    <Route path="view/:subConViewId" element={< Wrapper accessKey={true}  Component={SubConView} />} />
  </Route>
  <Route path="members" element={<MainMember />}>
    <Route index element={< Wrapper accessKey={true}  Component={Members} />} />
     <Route path="create" element={< Wrapper accessKey={true}  Component={CreateMember} />} />
    <Route path="profile/:id" element={< Wrapper accessKey={true}  Component={Profile} />} />
    <Route path="edit/:profileEditId" element={< Wrapper accessKey={true}  Component={EditMember} />} />          
  </Route>
  <Route path="rental-man" element={<MainRentalManpower />}>
    <Route index element={< Wrapper accessKey={true}  Component={RentalManpower} />} />
    <Route path="create" element={< Wrapper accessKey={true}  Component={CreateRentalManpower} />} />
    <Route path="profile/:rentalManpowerid" element={< Wrapper accessKey={true}  Component={RentalManpowerProfile} />} />
    <Route path="edit/:rentalManpowerEditId" element={< Wrapper accessKey={true}  Component={EditRentalManpower} />} />          
  </Route>
  </Route>
  )
}

export default ContactsRoutes