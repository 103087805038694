import React from "react";

function Notes({ color = "white", width = 14, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.93043 2.50226L8.17146 2.26123C9.01228 1.42041 9.43269 1 9.95511 1C10.4775 1 10.8979 1.42041 11.7388 2.26123C12.5796 3.10206 13 3.52247 13 4.04489C13 4.56731 12.5796 4.98772 11.7388 5.82855L11.4977 6.06957M7.93043 2.50226L1.74181 8.69088C1.37037 9.06232 1.18465 9.24806 1.08854 9.48465C0.992436 9.72125 0.996035 9.98386 1.00323 10.5091L1.02004 11.7361C1.02798 12.3159 1.03195 12.6058 1.21309 12.7869C1.39422 12.9681 1.68411 12.972 2.26388 12.98L3.49091 12.9968C4.01615 13.004 4.27877 13.0075 4.51537 12.9114C4.75196 12.8153 4.93767 12.6296 5.30911 12.2582L11.4977 6.06957M7.93043 2.50226L11.4977 6.06957M7.94737 12.9968L13 12.9968"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default Notes;
