import React, { lazy } from 'react'
import { Route } from 'react-router-dom'


const Auth =lazy(()=>import('../Auth')) 
const Login =lazy(()=>import('../Pages/Auth/Login')) 
const ChangePassword =lazy(()=>import('../Pages/User/changePassword')) 

function AuthRoutes() {
  return (
    <Route path="/auth" element={<Auth />}>
    <Route path="login" element={<Login />} />
    {/* <Route path="forget-password" element={<ChangePassword />} /> */}
  </Route>
  )
}

export default AuthRoutes