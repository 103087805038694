import axios from "axios";
import DecryptData from "../Components/Utils/Decrypt";



const SERVER_URL = window.WEB_APP_API_URL; 

const auth_key= DecryptData('NeKoTrEsUoRpNu')||"";

const getAuthKey = () => {
  const userToken = auth_key
    if(!userToken){
    }
    return userToken||""
  };

let token=getAuthKey()||"";
  const axiosInstance = axios.create({
    baseURL:SERVER_URL,
    headers:{
      "token":token
    }
})

axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        if(response.data?.message==='Token is invalid'||response.data?.message==="Token is missing"){
          window.localStorage.removeItem("NeKoTrEsUoRpNu");
          window.localStorage.removeItem("SlIaTeDrEsUoRpNu");
          window.localStorage.removeItem("SsEcCaReSuOrPnU");
          window.location.replace("/auth/login");  
        }else{
          resolve(response);
        }
       
      }),
    (error) => {

      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
     
      if (error.response.status === 401 ) {
        window.localStorage.removeItem("NeKoTrEsUoRpNu");
        window.localStorage.removeItem("SlIaTeDrEsUoRpNu");
        window.localStorage.removeItem("SsEcCaReSuOrPnU");
        window.location.replace("/auth/login");     
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  export default axiosInstance;

