import React, { lazy } from "react";
import { Route } from "react-router-dom";

import MainProjectCosting from "../Pages/Web/Costing/ProjectCosting/MainProjectCosting";
import ProjectCosting from "../Pages/Web/Costing/ProjectCosting";
import CreateProjectCosting from "../Pages/Web/Costing/ProjectCosting/CreateProjectCosting";
import EditProjectCosting from "../Pages/Web/Costing/ProjectCosting/EditProjectCosting";
import ViewProjectCosting from "../Pages/Web/Costing/ProjectCosting/ViewProjectCosting";
import MainManPower from "../Pages/Web/Costing/ManPower/MainManPower";
import ManPower from "../Pages/Web/Costing/ManPower";
import EditManPower from "../Pages/Web/Costing/ManPower/EditManPower";
import ViewManPower from "../Pages/Web/Costing/ManPower/ViewManPower";
import MainCostings from "../Pages/Web/Costing/MainCosting";
import MainMaterial from "../Pages/Web/Costing/Material/MainMaterial";
import Material from "../Pages/Web/Costing/Material";
import CreateMaterial from "../Pages/Web/Costing/Material/CreateMaterial";
import EditMaterial from "../Pages/Web/Costing/Material/EditMaterial";
import ViewMaterial from "../Pages/Web/Costing/Material/ViewMaterial";
import MainOthers from "../Pages/Web/Costing/Others/MainOthers";
import Others from "../Pages/Web/Costing/Others";
import CreateOthers from "../Pages/Web/Costing/Others/CreateOthers";
import EditOthers from "../Pages/Web/Costing/Others/EditOthers";
import ViewOthers from "../Pages/Web/Costing/Others/ViewOthers";
import CreateManPower from "../Pages/Web/Costing/ManPower/CreateManPower";
import MainManHour from "../Pages/Web/Costing/ManHour/MainManHour";
import ManHourIndex from "../Pages/Web/Costing/ManHour";
import CreateManHour from "../Pages/Web/Costing/ManHour/CreateManHour";
import EditManHour from "../Pages/Web/Costing/ManHour/EditManHour";
import ViewManHour from "../Pages/Web/Costing/ManHour/ViewManHour";
import { Wrapper } from "../Components/Utils/roles";




const CostingRoutes = () => {
  return (
    <Route path="costing" component={<MainCostings/>}>
      <Route path="project" element={<MainProjectCosting />}>
        <Route index element={ < Wrapper accessKey={true}  Component={ProjectCosting} /> } />
        <Route path="create" element={ < Wrapper accessKey={true}  Component={CreateProjectCosting} /> } />
        <Route path="edit/:projectCostingEditId" element={ < Wrapper accessKey={true}  Component={EditProjectCosting} /> } />
        <Route path="view/:projectCostingViewId" element={ < Wrapper accessKey={true}  Component={ViewProjectCosting} /> } />
      </Route>

      <Route path="man-power" element={<MainManPower />}>
        <Route index element={ < Wrapper accessKey={"manpower_view"}  Component={ManPower} /> } />
        <Route path="create" element={ < Wrapper accessKey={"manpower_create"}  Component={CreateManPower} /> } />
        <Route path="edit/:manPowerEditId" element={ < Wrapper accessKey={"manpower_edit"}  Component={EditManPower} /> } />
        <Route path="view/:manPowerViewId" element={ < Wrapper accessKey={"manpower_view"}  Component={ViewManPower} /> } />
      </Route>

      <Route path="material" element={<MainMaterial />}>
        <Route index element={ < Wrapper accessKey={"material_view"}  Component={Material} /> } />
        <Route path="create" element={ < Wrapper accessKey={"material_create"}  Component={CreateMaterial} /> } />
        <Route path="edit/:materialEditId" element={ < Wrapper accessKey={"material_edit"}  Component={EditMaterial} /> } />
        <Route path="view/:materialViewId" element={ < Wrapper accessKey={"material_view"}  Component={ViewMaterial} /> } />
      </Route>
      
      <Route path="others" element={<MainOthers />}>
        <Route index element={ < Wrapper accessKey={true}  Component={Others} /> } />
        <Route path="create" element={ < Wrapper accessKey={true}  Component={CreateOthers} /> } />
        <Route path="edit/:otherEditId" element={ < Wrapper accessKey={true}  Component={EditOthers} /> } />
        <Route path="view/:otherViewId" element={ < Wrapper accessKey={true}  Component={ViewOthers} /> } />
      </Route>

      <Route path="man-hour" element={<MainManHour />}>
        <Route index element={ < Wrapper accessKey={true}  Component={ManHourIndex} /> } />
        <Route path="create" element={ < Wrapper accessKey={true}  Component={CreateManHour} /> } />
        <Route path="edit/:manHourEditId" element={ < Wrapper accessKey={true}  Component={EditManHour} /> } />
        <Route path="view/:manHourViewId" element={ < Wrapper accessKey={true}  Component={ViewManHour} /> } />
      </Route>
    </Route>
  );
};

export default CostingRoutes;
