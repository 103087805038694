import React, { useState } from 'react'
import ToggleUp from '../../../Components/Icons/ToggleUp';
import ToggleDown from '../../../Components/Icons/ToggleDown';
import { Link } from 'react-router-dom';
import Plus from '../../../Components/Icons/Plus';
import { Progress } from 'antd';
import ViewMoreTitle from '../../Web/Tasks/Components/viewMoreTitle';
import MobileTaskData from './TaskData';

function MobileTitleData({rowData,getData,projectId}) {
    const [isExpanded, setIsExpanded] = useState(false);
  

    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };


    const userAccess = JSON.parse(localStorage.getItem("userAccess"));
    let MyTaskCreate = userAccess
      ? userAccess.some(
          (permission) => permission.permissionName == "MyTask_create"
        )
      : false;

  return (
    <div className=" bg-slate-50 rounded my-2 px-2 py-3">
    <div className=" w-full flex gap-2 items-center">
      <div className=' w-[5%]'>
      { rowData?.taskDetails?.length>0 && <div className=' w-7 h-7 rounded-md cursor-pointer ' onClick={handleToggle}>{isExpanded ?<ToggleUp/>:<ToggleDown/>}</div>}
      </div>
      <div className=' w-[55%]'>
      <div className=' w-full flex gap-2 items-center'>
          {rowData.title_name||"-"}
        <div className=" w-[13px] h-[13px] flex justify-center items-center">
          {MyTaskCreate && <Link to="/tasks-plan/create" state={{data:rowData}} ><Plus color='#3267FF' /></Link>}
          </div>
          </div>
      </div>
      <div className=' w-[30%]'>
      <div className=' w-full flex gap-2 justify-end px-2 items-center'>
          <Progress
              type="circle"
              size={30}
              strokeWidth={12}
              format={(percent) => (
                <p
                  className={` ${
                    rowData?.titlePercentages?.percentage > 95
                      ? "text-[#87d068]"
                      : rowData?.titlePercentages?.percentage > 50
                      ? "text-[#108ee9]"
                      : "text-[#D2BD00]"
                  } text-xs font-semibold`}
                >
                  {percent}
                </p>
              )}
              percent={Math.floor(rowData?.titlePercentages?.percentage) || 0}
              strokeColor={
                rowData?.titlePercentages?.percentage > 95
                  ? {
                      "0%": "#87d068",
                      "100%": "#87d068",
                    }
                  : rowData?.titlePercentages?.percentage > 50
                  ? {
                      "0%": "#108ee9",
                      "100%": "#108ee9",
                    }
                  : {
                      "0%": "#D2BD00",
                      "100%": "#D2BD00",
                    }
              }
            />
          </div>
      </div>
      <div>
      <ViewMoreTitle getTitle={getData} projectId={projectId} data={rowData} />
      </div>
    </div>
    {isExpanded && rowData?.taskDetails.map((item,i)=>(
                <MobileTaskData taskData={item||""} key={i} getData={getData} projectId={projectId} />
              ))}
  </div>
  )
}

export default MobileTitleData;