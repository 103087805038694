import { useCurrency } from "../../Context/CurrencyContext"

function NuAmount({currency="",value=0}) {
    const {currencySymbol} =useCurrency()
    if(value){
        let finalValue = 0
        if(Number.isInteger(value)){
            finalValue = `${value.toLocaleString('en-IN', {maximumFractionDigits:2 })}.00`
        }else{
            finalValue = value.toLocaleString('en-IN', {maximumFractionDigits:2 })
        }
        return ` ${currency||currencySymbol||""} ${finalValue}`
    }else{
        return  `${currency||currencySymbol||""} 0.00`
    }
}

export default NuAmount;