import React from "react";

function ToggleDown({ color = "#3D348B", width = 9, height = 6 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`1 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.37378 1.60398L4.80869 5.0389L8.2436 1.60398"
        stroke="#3D348B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ToggleDown;
