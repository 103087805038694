export function TextBaseBold ({text,width="w-full",overflow}){
    return (
        <p className={` ${width} ${overflow?"truncate":''} text-mainColor-400 font-semibold text-[12px] lg:text-[12px] xl:text-[12px] `} >{text}</p>
    )
}

export function TextBold ({text}){
    return (
        <p className=" text-mainColor-400  line-clamp-2 font-semibold text-[14px] lg:text-[14px] xl:text-[14px]">{text}</p>
    )
}

export function TextNormal ({text}){
    return (
        <p className=" text-mainColor-400  font-medium text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}


export function TextSmallBold ({text}){
    return (
        <p className=" text-mainColor-400  font-semibold text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}

export function TextSmallNormal ({text}){
    return (
        <p className=" text-textColor-300  truncate text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}

export function TextSmallMedium ({text}){
    return (
        <p className=" text-[#171A21] font-medium  truncate text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}

export function TextTinyNormal ({text,color="text-textColor-200"}){
    return (
        <p className={` ${color} truncate text-[12px] lg:text-[12px] xl:text-[12px]`}>{text}</p>
    )
}

export function TextTinyMedium ({text}){
    return (
        <p className=" text-mainColor-400 font-medium  truncate text-[12px] lg:text-[12px] xl:text-[12px]">{text}</p>
    )
}