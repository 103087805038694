import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { NuInputNumber, NuInputText, NuSelect } from '../../../../../Components/Utils/NuInput'
import DeleteIcon from '../../../../../Components/Icons/Delete'
import ViewMore from '../../../../../Components/Icons/ViewMore'
import { Modal } from 'antd'
import { ExclamationCircleFilled } from "@ant-design/icons";
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons'
import axiosInstance from '../../../../../Services/axiosCommon'
import { useNumber } from '../../../../../Context/ProjectIdContext'
import { useNavigate, useParams } from 'react-router-dom'

function ViewManPower() {
    const { estimationViewId } = useParams();
    let estId = Number(estimationViewId)
    const [addActivityOpen, setAddActivityOpen] = useState(false);
    const [manPowerList,setManPowerList] = useState([]);
    const [activityList,setActivityList] = useState([])
    const [listOfJob,setListOfJob] = useState([])
      const [totalNumberOfDays,setTotalNumberOfDays] = useState(0);
      const [totalNumberAmount,setTotalNumberAmount] = useState(0);
      const [totalNumberOfHours,setTotalNumberOfHours] = useState(0);
      const [selectedValue,setSelectedValue] = useState({});
      const [ItemId,setItemId] = useState("");

    function getFunction(value){
        axiosInstance.get(`/estimation/materials/man-power/${value}`).then(res=>{
            console.log("test",res.data?.data);
            setActivityList(res.data?.data)  
        }).catch(err=>{
            console.log("error",err.response);
            
        })
      }

      useEffect(()=>{
        if(estimationViewId){
            console.log("Est ID",estimationViewId);
            
            getFunction(estimationViewId)
        }
      },[estimationViewId])


      function getRole(id){
        axiosInstance.get(`/estimation/man-power/${estId||estimationViewId}/${id}`).then(res=>{
            console.log("tttttt",res.data?.data);
            setManPowerList(res.data?.data);
            
        })
      }


      function OpenActivityRoles(value) {
        setItemId(value?.id)
        getRole(value?.id)
        setAddActivityOpen(true);
      }

      useMemo(()=>{
        let TempAmount = 0
        let TempDays = 0 
        let TempHour = 0
        manPowerList.forEach((item) => {
            TempAmount = TempAmount+item.amount
            TempDays = TempDays+item.days
            TempHour = TempHour+item.hours
        })
        setTotalNumberAmount(TempAmount);
        setTotalNumberOfDays(TempDays);
        setTotalNumberOfHours(TempHour);
      },[manPowerList])

  return (
    <>
    <div className='w-full h-[calc(100vh-340px)] overflow-y-auto'>
        <table className=" w-full border-collapse">
              <thead className="sticky top-0">
                <tr className="bg-[#1B2838]">
                <th className=" w-[5%] py-2 pl-1 text-left text-white text-xs font-medium">
                    SI.No
                  </th>
                  <th className=" w-[45%] py-2 pl-1 text-left text-white text-xs font-medium">
                    Activity
                  </th>
                  <th className=" w-[20%] py-2 text-left text-white text-xs font-medium">
                    No Of Days
                  </th>
                  <th className=" w-[20%] py-2 text-left text-white text-xs font-medium">
                    Amount
                  </th>
                  {/* <th className=" w-[10%] py-2 text-center text-white text-xs font-medium">
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {activityList && activityList?.map((list, index) => (
                  <tr
                    key={index}
                    className="w-full  odd:bg-white even:bg-slate-50"
                  >
                    <td className="w-[5%] pl-1 py-2 text-left text-black text-xs font-light">
                      {index + 1}
                    </td>
                    <td className="w-[45%] py-2">
                      <div className="flex justify-between items-center pl-1 pr-2">
                        <p className="text-left text-black text-xs font-light">
                          {list?.item}
                        </p>
                        <div
                          className="cursor-pointer"
                          onClick={() => OpenActivityRoles(list)}
                        >
                          <ViewMore />
                        </div>
                      </div>
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.totalDays || 0}
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.amount || 0}
                    </td>
                    {/* <td className="w-[10%] py-2 text-center">
                      <div className=" w-full flex justify-center items-center">
                        <button
                          onClick={() =>
                            // alert("testing")
                            handleRemoveManPowerItem(list)
                          }
                          type="button"
                          className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
        <Modal
        open={addActivityOpen}
        title={"Add Activity"}
        width={"75%"}
        className="my-modal"
        centered
        onCancel={() => setAddActivityOpen(false)}
        footer={[]}
      >
        <div className="w-full h-[40vh]  overflow-y-scroll mx-auto bg-white">
          <div className="w-full py-3 sticky top-0  bg-[#1B2838] flex justify-between rounded-lg px-6">

            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Job Role
            </p>
            <p className=" w-[10%] text-left text-white text-xs font-medium">
              Type
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Qty
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Days
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Hours
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Amount
            </p>
            {/* <p className=" w-[15%] text-center text-white text-xs font-medium">
              Action
            </p> */}
          </div>
          {manPowerList?.map((list, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
              >
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.role || ""}
                </p>
                <p className="w-[10%] text-left text-black text-xs font-light">
                  {list?.type == 1? "Rental":"Own" || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.quantity || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.days}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.hours}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.amount}
                </p>
                {/* <div className="w-[15%] text-center">
                  <button
                    // onClick={() => handleRemoveJobRolesItem(list?.job_role)}
                    type="button"
                    className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                  >
                    <DeleteIcon color="red" height={4} />
                  </button>
                </div> */}
              </div>
            );
          })}

        </div>
        <div className=" w-full flex justify-end ">
            <p className="w-[15%] text-center text-black text-xs font-medium">
             Total Day :  {totalNumberOfDays||0}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-medium">
            Total Hour : {totalNumberOfHours||0}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-medium">
            Total Amount : {totalNumberAmount||0}
            </p>
            <div className="w-[15%] h-4 text-center text-black text-xs font-light">
           
            </div>
          </div>
      </Modal>
        </>
  )
}

export default ViewManPower