import React, { useEffect, useState } from "react";
import axios from "../../../../Services/axiosCommon";
import {useLocation } from "react-router-dom";
import Pagination from "../../../../Components/Utils/Pagenation";
import { NuDatePicker, NuSelect } from "../../../../Components/Utils/NuInput";
import { Button, Modal, } from "antd";
import { useFormik } from 'formik';
import * as yup from "yup";
import { useNumber } from "../../../../Context/ProjectIdContext";
import CostingLayout from "../CostingLayout";
import ViewMore from "./ViewMore";
import NuAmount from "../../../../Components/Utils/NuAmount";
import { GetAccess } from "../../../../Components/Utils/roles";



function ProjectCosting() {

  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [totalTasks, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([])
  const [priority, setPriority] = useState([]);
  const [firstProject, setFirstProject] = useState("");
  const [isFilter,setIsFilter]= useState(false);
  const [notFirst,setNotFirst]=useState(false)
  const [filterData,setFilterData]= useState({
    SDF:"",
    EDT:"",
    status: "",
    priority: "",
  });
  const [afterFilter,setAfterFilter]= useState({})
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);







  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/mytask/byUser/members_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
        .then((res) => {
          setData(res.data?.data);
        setTotalProject(res.data?.total_records)

        })
        .catch((err) => console.log(err));
    } else {
      getTask(firstProject);
    }
  }

  function SearchPageDate(){
    axios
    .get(`/mytask/byUser/members_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,{params:{search:`${searchQuery}`}})
    .then((res) => {
      setData(res.data?.data);
      setTotalProject(res.data?.total_records)

    }).catch((err) => console.log(err))
  }



  function getTask() {
    axios
      .get(`/project_cost/?page=${pageIndex}&per_page=${dataCount}`)
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.totalRecords)

      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchQuery == "") {
      getTask();
    }
  }, [searchQuery]);


  useEffect(() => {
    // console.log("jj",projectNum);
    if (projectTask) {
      setFirstProject(projectTask);
      // console.log("gf", firstProject);
    }
  }, [projectTask]);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // mobileNo: yup
      //   .string()
      //   // .required("Mobile Number is required")
      //   .matches(
      //     /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      //     "Enter Valid Mobile Number"
      //   )
      //   .min(10, "Invalid Mobile Number")
      //   .max(10, "Invalid Mobile Number"),
      // email: yup.string().required("Email is required"),
    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axios
      .get(`/mytask/byUser/members_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,{params:{status:`${value.status}`,SDF:`${value.SDF}`,EDT:`${value.EDT}`,priority:`${value.priority}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });

  function FilterPage(){
    axios
      .get(`/mytask/byUser/members_tasks/${firstProject}?page=${pageIndex}&per_page=${dataCount}`,{params:{status:`${afterFilter.status}`,SDF:`${afterFilter.SDF}`,SDT:`${afterFilter.SDT}`,EDF:`${afterFilter.EDF}`,EDT:`${afterFilter.EDT}`,priority:`${afterFilter.priority}`}})
      .then((res) => {
        setData(res.data?.data);
        setTotalProject(res.data?.total_records)

      }).catch(err=>console.log(err))
  }


  function ClearFilter(){
    formik.resetForm({})
    getTask(firstProject);
    setIsFilter(false)
  }

  useEffect(()=>{
   if(isFilter){
    FilterPage();
   }else if(searchQuery !=""){
      SearchPageDate();
    }else{
    getTask(firstProject)
    }
  },[pageIndex,dataCount])

  console.log("zzzz",firstProject);

  return (
    <>
    <CostingLayout 
      setOpen={setOpen}
      setSearchQuery={setSearchQuery}
      SearchData={SearchData}
      isFilter={isFilter}
      setFilterOpen={setFilterOpen}
      ClearFilter={ClearFilter}
      CreateAccess={GetAccess()}
      ImportAccess={GetAccess()}
        >
            <div className=" w-full h-[calc(100vh-180px)] overflow-y-auto">
            <table
              className="table-auto w-full"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className=" w-full bg-[#2A475E]">
                <tr className="text-xs  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} font-normal ${header?.isLeft?"text-left":"text-center"} text-xs  text-white`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead> 
              <tbody className="">
                { data && data.map((item, i) => (
                  <tr className=" w-full " key={i}>
                    <td className="py-2  text-xs text-center font-normal text-mainColor-400">{item?.costId||"#"}</td>
                    <td className="py-2  text-xs text-left font-normal text-mainColor-400">{item?.project_name||"-"}</td>
                    <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                     <NuAmount value={item?.costAwarded||0} />
                    </td>
                    <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                     <NuAmount value={item?.budgetCost||0} />
                      </td>
                    <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                     <NuAmount value={item?.projectCost||0} />
                   </td>
                    <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                     <NuAmount value={item?.difference||0} />
                     </td>
                    <td className="py-2  text-xs text-left font-normal text-mainColor-400">
                      <div className=" w-full flex justify-center items-center">
                      <ViewMore
                        id={item?.costId}
                        getData={getTask}
                        data={item}
                       />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      
      <div className=" h-6 my-2">
        <Pagination
          total={totalTasks}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>
    </CostingLayout>
      <Modal
        open={filterOpen}
        title={"Filter My Tasks"}
        width={"40%"}
        className="my-modal"
        centered
        onCancel={()=>setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
          {/* <NuSelect
              label="Status"
              options={status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
              <NuSelect
              label="Priority"
              options={priority}
              formik={formik}
              placeholder="Choose"
              name="Priority"
              width=" w-1/2"
              // cceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
          {/* <NuDatePicker
              label="Start Date"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="SDF"
              width=" w-1/2"
            />
            <NuDatePicker
              label="End Date"
              // options={status}
              formik={formik}
              placeholder="Choose"
              name="EDT"
              width=" w-1/2"
            /> */}
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ProjectCosting;

const header = [
  {
    headone: "Id",
    width: "w-[3%]",
    // isLeft: true,
  }, 
  {
    headone: "Project",
    width: "w-[31%]",
    isLeft: true,
  },
  {
    headone: "Cost Awarded",
    width: "w-[13%]",
    isLeft: true,

  },
  {
    headone: "Budget Cost",
    width: "w-[13%]",
    isLeft: true,

  },
  {
    headone: "Project Cost",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Difference",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Action",
    width: "w-[5%]",
    // isLeft: true,
  },
];
