import React, { useEffect, useState } from "react";
import axios from "../../../../Services/axiosCommon";
import { Link } from "react-router-dom";
import CardView from "./cardView";
import Pagination from "../../../../Components/Utils/Pagenation";
import { CreateButton } from "../../../../Components/Utils/Buttons";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { SecondHeading } from "../../../../Components/Utils/Heading";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";
import ImportMembers from "./Import";
import axiosInstance from "../../../../Services/axiosCommon";
import ExportXls from "../../../../Components/Utils/ExportXls";
import CryptoJS from 'crypto-js';
import ContactsLayout from "../ContactsLayout";
import { GetAccess } from "../../../../Components/Utils/roles";

function Index() {
  // const [positionList, setpostionList] = useState([]);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalProject, setTotalProject] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [exportAllData,setExportAllData] =useState([])
  const [notFirst,setNotFirst]=useState(false);


  async function getMember() {
    await axios
      .get(`/User/?page=${pageIndex}&per_page=${perPage}&type=2`)
      .then(async (res) => {
        (await res?.data?.data?.userDetails) &&
          setData(res?.data?.data?.userDetails);
        setTotalRows(res.data?.data?.totalRecords);
        setTotalProject(res.data?.data?.totalRecords);
        let testData = res?.data?.data?.userDetails
        res?.data?.data?.userDetails && testData.map((i)=>{
          let key = i.encrypt_key;
          let values = i.encrypt_data;

          const decryptedKey = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(key) },
            key,
            { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
        ).toString(CryptoJS.enc.Utf8);

          const decryptedText = CryptoJS.AES.decrypt(
            { ciphertext: CryptoJS.enc.Base64.parse(values) },
            decryptedKey,
            { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
        ).toString(CryptoJS.enc.Utf8);
          console.log(decryptedText);
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // function memberPosition() {
  //   axios.get(`/Metadata/memberPosition`).then((res) => {
  //     let list = res.data?.data?.keyValues;
  //     let array = [];
  //     list &&
  //       list.forEach((i) => {
  //         let num = { value: i, label: i };
  //         array.push(num);
  //       });
  //     res.data?.data?.keyValues && setpostionList(array);
  //   });
  // }

  useEffect(() => {
    // memberPosition();
    // getMember();

    // getAllMembers()
    // downloadFile()
  }, []);

  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(
          `/Search/member/${searchQuery}?page=${pageIndex}&per_page=${perPage}&type=2`
        )
        .then((res) => {
          res.data?.data && setData(res.data?.data);
          setTotalRows(res.data?.total_records);
          setTotalProject(res.data?.total_records);
        })
        .catch((err) => console.log(err));
    } else {
      getMember();
    }
  }

  function SearchDataPage() {
    axios
      .get(
        `/Search/member/${searchQuery}?page=${pageIndex}&per_page=${perPage}`
      )
      .then((res) => {
        res.data?.data && setData(res.data?.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (searchQuery == "" && notFirst) {
      getMember();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      SearchDataPage();
    } else {
      getMember();
    }
  }, [pageIndex, perPage]);


  return (
    <>
    
    <ContactsLayout 
         setOpen={setOpen}
         setSearchQuery={setSearchQuery}
         SearchData={SearchData}
         CreateAccess={GetAccess("rental_manpower_create")}
         ImportAccess={GetAccess("rental_manpower_create")}
        >

<div className=" w-full mx-auto h-[calc(100vh-145px)]">
          <div className=" w-full flex justify-between items-center">
            <SecondHeading title={`Rental Manpower : ${totalRows || 0}`} />
          </div>
          <div className=" w-full h-full  overflow-hidden">
            <div className=" w-full h-full">
              <div className="  h-[calc(100vh-190px)] overflow-hidden">
                <div
                  className={`flex flex-wrap w-full ${
                    totalRows >= 10 ? "h-full" : "h-auto"
                  } overflow-y-scroll`}
                >
                  {data &&
                    data.map((item, idx) => {
                      return (
                        <CardView
                          width="w-full sm:w-1/2 lg:w-1/4 "
                          data={item}
                          // positionList={positionList}
                          getMember={getMember}
                          id={item?.userId}
                          key={idx}
                        />
                      );
                    })}
                </div>
              </div>
              <div className=" w-full h-6 my-2">
                {
                  <Pagination
                    total={totalProject}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    dataCount={perPage}
                    setDataCount={setPerPage}
                  />
                }
              </div>
            </div>
          </div>
        </div>
    </ContactsLayout>    
      <ImportMembers
        open={open}
        onCancel={() => setOpen(false)}
        getData={getMember}
        label="Add Title"
      />
    </>
  );
}

export default Index;