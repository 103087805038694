import React from "react";

function Project({ color = "white", width = 12, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 1H4C2.34315 1 1 2.34315 1 4V10C1 11.6569 2.34315 13 4 13H8C9.65685 13 11 11.6569 11 10V4.5M7.5 1H7.87868C8.2765 1 8.65803 1.15803 8.93934 1.43934L10.5607 3.06066C10.842 3.34196 11 3.7235 11 4.12132V4.5M7.5 1V3C7.5 3.82843 8.17157 4.5 9 4.5H11M3 6H6M3 8H8.75"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default Project;
