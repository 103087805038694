import React from "react";
import { useEffect, useState } from "react";
import {
  NuDatePicker,
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuSelect,
  NuTextArea,
  NuTimePicker,
} from "../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
// import Attachement from "./Attachement";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import { notification } from "antd";
import ViewFiles from "./FileView";
import Attachements from "./Attachements";
import NuDate from "../../../../Components/Utils/NuDate";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";

const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];
// export const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];

export default function EditBids() {
  let {value:Category} = useFetchMataData("/Metadata/category")
  let {value:Division} = useFetchMataData("/Metadata/division")
  let {value:BiddingStatus} = useFetchMataData("/Metadata/biddingstatus")
  const location = useLocation();
  const data = location.state?.data;

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const AttachementArr = data?.attachments ? data?.attachments: [];
  const [image, setImage] = useState([]);
  const [status, setStatus] = useState([]);
  const [createNew, setCreateNew] = useState({
    projectName:"",
    clientId:"",
    referenceNo:"",
    dueDate:"",
    submittedOn:"" ,
    submittedBy:null,
    submittedTo:null,
    comments:"",
    category:"",
    revision:"",
    bidValue:"",
    finalValue:"",
    biddingStatus:"",
    scopeOfWork:"",
    division:"",
  });


  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [proClient, setProClient] = useState([]);


  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    setImage(tempArr)
  }

  // const getStatus = () => {
  //   axios
  //     .get(`/Metadata/noteStatus`)
  //     .then((res) => {
  //       let data = res.data?.data?.keyValues;
  //       let tempData = [];
  //       data.forEach((item) => {
  //         let tempObject = { value: item, label: item };
  //         tempData.push(tempObject);
  //       });
  //       res.data?.data?.keyValues && setStatus(tempData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getClients = async () => {
    await axios
      .get("/DropDown/client")
      .then((response) => {
        let FinalData = [];
        let status = response.data?.data;
        status.forEach((item) => {
          let tempObject = { value: item.clientId, label: item.companyName };
          FinalData.push(tempObject);
        });
        response.data?.data && setProClient(FinalData);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    // getStatus();
    getClients()
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
        projectName: yup.string().required("Project Name is Required"),
        // clientId: yup.string().required("Client "),
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      let formData = new FormData();

      // formData.append("attachments", image.raw);
      if (removefilePathList) {
        let tempArr = [];
        let finalArr = removefilePathList.filter(
          (items) => !filePathList.includes(items)
        );
        finalArr && finalArr.map(fileName=>{
          tempArr.push(fileName)
        })
        console.log("ccc",tempArr);
        value.files_to_remove = tempArr;
      } else {
        value.files_to_remove = [];
      }
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", []);
      }
      formData.append("details", JSON.stringify(value));
      data
        ? axios
            .put(`/bidding/update_bidding/${data?.biddingId}`, formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage([]);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/bidding/create_bidding", formData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage([]);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });



  useEffect(() => {
    setCreateNew({
      projectName:data?.projectName||"",
      clientId:data?.clientId||"",
      referenceNo:data?.referenceNo||"",
      dueDate:data?.dueDate && data?.dueDate !=""  && data?.dueDate !=null && NuDate({value:data?.dueDate,format:"YYYY-MM-DD"}) ||"",
      submittedOn:data?.submittedOn && data?.submittedOn !=""  && data?.submittedOn !=null && NuDate({value:data?.submittedOn,format:"YYYY-MM-DD"})||"" ,
      submittedBy:data?.submittedBy||null,
      submittedTo:data?.submittedTo||null,
      comments:data?.comments||"",
      category:data?.category||"",
      revision:data?.revision||"",
      bidValue:data?.bidValue||"",
      finalValue:data?.finalValue||"",
      biddingStatus:data?.biddingStatus||"",
      scopeOfWork:data?.scopeOfWork||"",
      division:data?.division||"",
    })

    if (
      data?.attachments !== null &&
      data?.attachments !== "None" &&
      data?.attachments !== "[]" &&
      data?.attachments.length > 0
    ) {
      setFilePathList(data?.attachments);
      setRemoveFilePathList(data?.attachments);
    }
  },[data])


  function UpdateAttachmentList(item) {
    console.log("rrr", item);
    
    let filterList = filePathList.filter((itemList) => itemList !== item);
    setFilePathList(filterList);
  }

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/mainNotes">
                  <p className=" text-[12px] ">Bidding</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[85%] rounded-[6px] mt-2"
          style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Bidding" />
              {/* <BackButton /> */}
            </div>
            <div className="flex flex-wrap ">
             
            <NuInputText
                  label="Project Name"
                  isRequired={true}
                  placeholder="Project Name"
                  formik={formik}
                  name="projectName"
                  width="w-full md:w-1/2"
                />

                <NuInputText
                  label="Reference No"
                  isRequired={true}
                  placeholder="Reference No"
                  formik={formik}
                  name="referenceNo"
                  width="w-full md:w-1/4"
                />

                <NuInputNumber
                  label="Project Bid Value"
                  isRequired={true}
                  placeholder="Project Bid Value"
                  formik={formik}
                  name="bidValue"
                  width="w-full md:w-1/4"
                />


                <NuInputNumber
                  label="Final Bid Value"
                  isRequired={true}
                  placeholder="Project Bid Value"
                  formik={formik}
                  name="finalValue"
                  width="w-full md:w-1/4"
                />


                <NuSelect
                  label="Client"
                  options={proClient}
                  formik={formik}
                  placeholder="Choose"
                  name="clientId"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />


                <NuDatePicker
                  label="Bidding Due Date"
                  formik={formik}
                  name="dueDate"
                //   min={NuDate({
                //     value: Date.now(),
                //     format: "yyyy-MM-DD",
                //   })}
                  width=" w-full md:w-1/4"
                />

                <NuDatePicker
                  label="Submitted On"
                  formik={formik}
                  name="submittedOn"
                //   min={NuDate({
                //     value: Date.now(),
                //     format: "yyyy-MM-DD",
                //   })}
                  width=" w-full md:w-1/4"
                />

                <NuInputText
                  label="Revision"
                  formik={formik}
                  placeholder="Revision"
                  name="revision"
                  width="w-full md:w-1/4"
                  // isRequired={true}
                />

<NuSelect
                  label="Category"
                  options={Category||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="category"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />

                <NuSelect
                  label="Division"
                  options={Division||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="division"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />


                <NuSelect
                  label="Status"
                  options={BiddingStatus||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="biddingStatus"
                  width="w-full md:w-1/4"
                  isRequired={true}
                />

                <NuTextArea
                  label="Scope Of Work"
                  placeholder="Scope Of Work"
                  formik={formik}
                  name="scopeOfWork"
                  width="w-full md:w-1/2"
                />

                <NuTextArea
                  label="Comments"
                  placeholder="Title"
                  formik={formik}
                  name="comments"
                  width="w-full md:w-1/2"
                />



<div className=" w-1/2 flex gap-2 items-center">
                {AttachementArr.length >0  &&
                  filePathList.map((imgs, idx) => {
                    console.log("img",imgs);
                    return (
                      <div
                        className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                        key={idx}
                        style={{ border: "1px solid gray" }}
                      >
                        <div
                          className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
                          onClick={() => UpdateAttachmentList(imgs)}
                        >
                          x
                        </div>
                        <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden">
                        <ViewFiles data={imgs} key={idx} />
                        </div>
                      </div>
                    );
                  })}

                <Attachements
                  handleChange={handleImageChange}
                  preview={image || data?.image || ""}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
              </div>

            </div>


            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
