import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { NuInputNumber, NuInputText, NuSelect } from '../../../../../Components/Utils/NuInput'
import DeleteIcon from '../../../../../Components/Icons/Delete'
import ViewMore from '../../../../../Components/Icons/ViewMore'
import { Modal } from 'antd'
import { ExclamationCircleFilled } from "@ant-design/icons";
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons'
import axiosInstance from '../../../../../Services/axiosCommon'
import { useNumber } from '../../../../../Context/ProjectIdContext'
import { useNavigate, useParams } from 'react-router-dom'


const { confirm } = Modal;


function EditManPower() {
    const navigate = useNavigate()
    const {estimationId} =useNumber()
    const { estimationEditId } = useParams();
    let estId = Number(estimationEditId)
    const [activityList,setActivityList] = useState([])
    const [createNewManPower,setCreateNewManPower] = useState({
        materialDiscription:"",
        noOfDays:"",
        TotalAmount:"",
        manPowerjobRole:"",
        roletype:"",
        manPowerqty:"",
        manPowerdays:"",
        manPowerhours:"",
        manPoweramount:""

    })
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNewManPower,
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (value) => {
            let Formdata = {
                estimationId:estimationId||estId,
                itemId:ItemId,
                mp_items:manPowerList
            }
            axiosInstance.post(`/estimation/man-power`,Formdata).then(res=>{
                console.log("Testing",res.data);
                setAddActivityOpen(false);
                getFunction(estId)
            }).catch(err=>{
                console.log("Error");
                
            })
            
        }
    })

    function addManPower() {
        if (formik.values.materialDiscription) {
          let tempdata = {
            activity: formik.values.materialDiscription,
            noOfDays: 0,
            amount: 0,
            total: 0,
            users: [],
          };
          setActivityList([...activityList, tempdata]);
          formik.values.materialDiscription = "";
          console.log("test",activityList);
          
        }
      }


      async function handleRemoveManPowerItem(value) {
        confirm({
          title: `Do you want to Delete ${value}?`,
          centered: true,
          icon: <ExclamationCircleFilled />,
          content: ``,
          onOk() {
            if (activityList.some((item) => item.activity === value)) {
              // formik.values.materialTotalAmount = formik.values.materialTotalAmount - amount
              let arrayValue = activityList.filter(
                (item) => item.activity !== value
              );
              setActivityList(arrayValue);
            }
          },
          onCancel() {},
        });
      }


      const [tempListofJobRole, setTempListofJobRole] = useState({});
      const [jobRoleList, setJobRoleList] = useState([]);
      const [addActivityOpen, setAddActivityOpen] = useState(false);
      const [manPowerList,setManPowerList] = useState([]);
      const [totalNumberOfDays,setTotalNumberOfDays] = useState(0);
      const [totalNumberAmount,setTotalNumberAmount] = useState(0);
      const [totalNumberOfHours,setTotalNumberOfHours] = useState(0);
      const [selectedValue,setSelectedValue] = useState({});
      const [ItemId,setItemId] = useState("");

      const [listOfJob,setListOfJob] = useState([])

      function OpenActivityRoles(value) {
        setItemId(value?.id)
        getRole(value?.id)
        setAddActivityOpen(true);
      }


      function EditJobRoles() {
        navigate(`/bidding/estimation/edit/profit-margin/${estId}`)
        // setAddActivityOpen(false);
        // let tempData = tempListofJobRole;
        // console.log("Tem", tempData);
        
        // let value = activityList.map((item) => {
        //   if (item.activity == tempData.activity) {
        //     return { ...item,noOfDays:totalNumberOfDays,amount:totalNumberAmount,users: manPowerList };
        //   }
        //   return item;
        // });
        // setActivityList(value);
        // setJobRoleList([]);
        // setManPowerList([])
        // console.log("test value", value);
      }


      function getRole(id){
        axiosInstance.get(`/estimation/man-power/${estId||estimationId}/${id}`).then(res=>{
            console.log("tttttt",res.data?.data);
            setManPowerList(res.data?.data);
            
        })
      }



      const addJobRole = () => {

        const tempValue = {
            role: formik.values.manPowerjobRole|| "",
            type: selectedValue?.type || "",
            quantity:formik.values.manPowerqty|| "",
            days:formik.values.manPowerdays||"",
            hours: formik.values.manPowerdays * selectedValue?.working_hours ||"",
            amount: formik.values.manPowerdays * (selectedValue?.working_hours * selectedValue?.amount_per_hour)|| ""
        };
        setManPowerList([...manPowerList, tempValue]);





      
        formik.values.manPowerjobRole = ""
        formik.values.roletype = ""
        formik.values.manPowerqty=""
        formik.values.manPowerdays=""
        formik.values.manPowerhours = ""
        formik.values.manPoweramount = ""
      };


      useMemo(()=>{
        let TempAmount = 0
        let TempDays = 0 
        let TempHour = 0
        manPowerList.forEach((item) => {
            TempAmount = TempAmount+item.amount
            TempDays = TempDays+item.days
            TempHour = TempHour+item.hours
        })
        setTotalNumberAmount(TempAmount);
        setTotalNumberOfDays(TempDays);
        setTotalNumberOfHours(TempHour);
      },[manPowerList])



      function getFunction(value){
        axiosInstance.get(`/estimation/materials/man-power/${value}`).then(res=>{
            console.log("test",res.data?.data);
            setActivityList(res.data?.data)  
        }).catch(err=>{
            console.log("error",err.response);
            
        })
      }



      useEffect(()=>{
        if(estimationEditId){
            console.log("Est ID",estimationEditId);
            
            getFunction(estimationEditId)
        }
      },[estimationEditId])


      async function getNotes() {
        await axiosInstance
          .get(`/manpower-role/`)
          .then(async (res) => {
            (await res?.data?.data) && setListOfJob(res?.data?.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      useEffect(()=>{
          getNotes();
      },[])


      useMemo(()=>{
        if(formik.values.manPowerjobRole){
            let tempData = listOfJob.find(item => item.job_role === formik.values.manPowerjobRole)
            console.log("testing",tempData);
            setSelectedValue(tempData)
        }
      },[formik.values.manPowerjobRole])

  return (
    <div className=' w-full h-[calc(100vh-160px)] overflow-hidden' >
        {/* <form onSubmit={formik.handleSubmit}> */}
        <div className=' w-full flex gap-2 items-center'>
        <NuInputText
              label="Activity"
              isRequired={true}
              placeholder="Discription"
              formik={formik}
              name="materialDiscription"
              width="w-full md:w-1/2 lg:w-[70%]"
            />
              <button
              type="button"
              className="w-12 mt-6 p-1 my-3 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "

            //   className="w-14 my-3 p-1 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
              onClick={addManPower}
            >
              +
            </button>
        </div>
        <div className='w-full h-[calc(100vh-340px)] overflow-y-auto'>
        <table className=" w-full border-collapse">
              <thead className="sticky top-0">
                <tr className="bg-[#1B2838]">
                <th className=" w-[5%] py-2 pl-1 text-left text-white text-xs font-medium">
                    SI.No
                  </th>
                  <th className=" w-[45%] py-2 pl-1 text-left text-white text-xs font-medium">
                    Activity
                  </th>
                  <th className=" w-[20%] py-2 text-left text-white text-xs font-medium">
                    No Of Days
                  </th>
                  <th className=" w-[20%] py-2 text-left text-white text-xs font-medium">
                    Amount
                  </th>
                  <th className=" w-[10%] py-2 text-center text-white text-xs font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {activityList && activityList?.map((list, index) => (
                  <tr
                    key={index}
                    className="w-full  odd:bg-white even:bg-slate-50"
                  >
                    <td className="w-[5%] pl-1 py-2 text-left text-black text-xs font-light">
                      {index + 1}
                    </td>
                    <td className="w-[45%] py-2">
                      <div className="flex justify-between items-center pl-1 pr-2">
                        <p className="text-left text-black text-xs font-light">
                          {list?.item}
                        </p>
                        <div
                          className="cursor-pointer"
                          onClick={() => OpenActivityRoles(list)}
                        >
                          <ViewMore />
                        </div>
                      </div>
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.totalDays || 0}
                    </td>
                    <td className="w-[20%] py-2 text-left text-black text-xs font-light">
                      {list?.amount || 0}
                    </td>
                    <td className="w-[10%] py-2 text-center">
                      <div className=" w-full flex justify-center items-center">
                        <button
                          onClick={() =>
                            // alert("testing")
                            handleRemoveManPowerItem(list)
                          }
                          type="button"
                          className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                        >
                          <DeleteIcon color="red" height={4} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
        <div className="flex justify-end item-end mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
          <CancelButton handleClick={() => setAddActivityOpen(false)} />
            {/* <SubmitButton/> */}
          <button className='bg-[#119DEE] px-5 py-[9px] cursor-pointer  outline-none border-none text-white rounded-md flex items-center' onClick={EditJobRoles} >Add</button>
        </div>
        {/* </form> */}



        <Modal
        open={addActivityOpen}
        title={"Add Activity"}
        width={"75%"}
        className="my-modal"
        centered
        onCancel={() => setAddActivityOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit} >
        <div className=" w-full flex justify-between">
        <NuSelect
            label="Job Role"
            options={listOfJob && listOfJob.map(item => {return  {label:item?.job_role,value:item?.job_role}})}
            isRequired={true}
            placeholder="Discription"
            formik={formik}
            name="manPowerjobRole"
            width="w-full md:w-1/2 lg:w-1/3"
          />
{/* 
          <NuSelect
            label="Type"
            options={[
              {value: 0, label:"Own"},
              {value: 1, label:"Rental"}
            ]}
            isRequired={true}
            placeholder="Type"
            formik={formik}
            name="roletype"
            width="w-full md:w-1/2 lg:w-1/3"
          /> */}
          <NuInputNumber
            label="Quantity"
            isRequired={true}
            placeholder="Quantity"
            formik={formik}
            name="manPowerqty"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
            label="Days"
            isRequired={true}
            placeholder="No.Of Days"
            formik={formik}
            name="manPowerdays"
            width="w-full md:w-1/2 lg:w-1/3"
          />
          {/* <NuInputNumber
            label="Hours"
            isRequired={true}
            placeholder="Total Price"
            formik={formik}
            name="manPowerhours"
            width="w-full md:w-1/2 lg:w-1/3"
          /> */}
          {/* <NuInputNumber
            label="Amount"
            isRequired={true}
            placeholder="Unit Price"
            formik={formik}
            name="manPoweramount"
            width="w-full md:w-1/2 lg:w-1/3"
          /> */}
          <button
            type="button"
            className="w-14 mt-5 p-1 text-center h-10 cursor-pointer bg-[#1B2838] border border-solid border-white  text-white font-semibold rounded-lg "
            onClick={addJobRole}
          >
            +
          </button>
        </div>
        <div className="w-full h-[40vh]  overflow-y-scroll mx-auto bg-white">
          <div className="w-full py-3 sticky top-0  bg-[#1B2838] flex justify-between rounded-lg px-6">

            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Job Role
            </p>
            <p className=" w-[10%] text-left text-white text-xs font-medium">
              Type
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Qty
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Days
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Hours
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Amount
            </p>
            <p className=" w-[15%] text-center text-white text-xs font-medium">
              Action
            </p>
          </div>
          {manPowerList?.map((list, index) => {
            return (
              <div
                key={index}
                className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-50"
              >
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.role || ""}
                </p>
                <p className="w-[10%] text-left text-black text-xs font-light">
                  {list?.type == 1? "Rental":"Own" || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.quantity || ""}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.days}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.hours}
                </p>
                <p className="w-[15%] text-center text-black text-xs font-light">
                  {list?.amount}
                </p>
                <div className="w-[15%] text-center">
                  <button
                    // onClick={() => handleRemoveJobRolesItem(list?.job_role)}
                    type="button"
                    className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                  >
                    <DeleteIcon color="red" height={4} />
                  </button>
                </div>
              </div>
            );
          })}

        </div>
        <div className=" w-full flex justify-end ">
            <p className="w-[15%] text-center text-black text-xs font-medium">
             Total Day :  {totalNumberOfDays||0}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-medium">
            Total Hour : {totalNumberOfHours||0}
            </p>
            <p className="w-[15%] text-center text-black text-xs font-medium">
            Total Amount : {totalNumberAmount||0}
            </p>
            <div className="w-[15%] h-4 text-center text-black text-xs font-light">
           
            </div>
          </div>
        <div className="flex justify-end item-end mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
          <CancelButton handleClick={() => setAddActivityOpen(false)} />
            <SubmitButton />
          {/* <button className='bg-[#119DEE] px-5 py-[9px] cursor-pointer  outline-none border-none text-white rounded-md flex items-center' onClick={EditJobRoles} >Add</button> */}
        </div>
        </form>
      </Modal>

    </div>
  )
}

export default EditManPower;