import { Modal, notification } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react'
import * as yup from "yup";
import axios from "../../../../../Services/axiosCommon";
import { useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleFilled } from "@ant-design/icons";
import { CancelButton, SubmitButton } from '../../../../../Components/Utils/Buttons';
import NuAmount from '../../../../../Components/Utils/NuAmount';
import { NuInputNumber, NuSelect } from '../../../../../Components/Utils/NuInput';
import { useNumber } from '../../../../../Context/ProjectIdContext';
import axiosInstance from '../../../../../Services/axiosCommon';




const { confirm } = Modal;


function ViewProfitMargin({ data }) {
  const {estimationId} =useNumber()
  const { estimationViewId } = useParams();
  let estId = Number(estimationViewId)
  console.log("esti",estimationId);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [materialPrice,setMaterialPrice] = useState(0);
  const [manPowerPrice,setManPowerPrice] = useState(0);
  const [dataPro,setDataPro] = useState([]);
  console.log("cxz",materialPrice,manPowerPrice);
  const [createNew, setCreateNew] = useState({
    materialTotal: "",
    materialVatPer: "",
    materialVatAmount: "",
    mpVatPer: "",
    mpVatAmount: "",
    mpTotal: "",
    withoutOPV: "",
    OHPer: "",
    OHAmount: "",
    OHTotal: "",
    profitPer: "",
    profitAmount: "",
    profitTotal: "",
    withoutVatAmount: "",
    vatAmount: "",
    vatPer: "",
    vatTotal: "",
    total: ""
  });
      const getAmount = () =>{
          axiosInstance.get(`/estimation/get-amount/${estId||estimationId}`)
          .then((res)=>{
            console.log("find",res?.data?.data);
            res?.data?.data?.materialPrice && setMaterialPrice(res?.data?.data?.materialPrice)
            res?.data?.data?.manPowerPrice && setManPowerPrice(res?.data?.data?.manPowerPrice)
          })
          .catch((error)=>{
            console.log(error);
          })
      }
      
      const getMarginProfit = () =>{
        axiosInstance.get(`/estimation/margin-profit/${estimationViewId}`)
        .then((res)=>{
            console.log("res",res?.data?.data);
            res?.data?.data && setDataPro(res?.data?.data)
        })
        .catch((error)=>{
            console.log(error);
        })
      }

      useEffect(()=>{
         dataPro &&
           setCreateNew({
            materialTotal: dataPro?.materialTotal || "",
            materialVatPer: dataPro?.materialVatPer || "",
            materialVatAmount: dataPro?.materialVatAmount || "",
            mpVatPer: dataPro?.mpVatPer || "",
            mpVatAmount: dataPro?.mpVatAmount || "",
            mpTotal: dataPro?.mpTotal || "",
            withoutOPV: dataPro?.withoutOPV || "",
            OHPer: dataPro?.OHPer || "",
            OHAmount: dataPro?.OHAmount || "",
            OHTotal: dataPro?.OHTotal || "",
            profitPer: dataPro?.profitPer || "",
            profitAmount: dataPro?.profitAmount || "",
            profitTotal: dataPro?.profitTotal || "",
            withoutVatAmount: dataPro?.withoutVatAmount || "",
            vatAmount: dataPro?.vatAmount || "",
            vatPer: dataPro?.vatPer || "",
            vatTotal: dataPro?.vatTotal || "",
            total: dataPro?.total || ""
           })
      },[dataPro])

      useEffect(()=>{
        getAmount();
        getMarginProfit();
      },[estimationId])

  const [overHeadAmount,setOverHeadAmount] = useState(0)
  const [profitAmount,setProfitAmount] = useState(0)
  const [overHeadTotal,setOverHeadTotal] = useState(0)
  const [profitTotal,setProfitTotal] = useState(0)
  const [vatAmount,setVatAmount] = useState(0)
  const [vatTotal,setVatTotal] = useState(0)
  const [materialPercentageTotal,setMaterialPercentageTotal] = useState(0);
  const [materialTotalAmount,setMaterialTotalAmount] = useState(0);
  const [manPowerPercentageTotal,setManPowerPercentageTotal] = useState(0);
  const [manPowerTotalAmount,setManPowerTotalAmount] = useState(0);
  console.log("ccc",materialPrice);

  const withoutOverallProVat = parseInt(materialTotalAmount, 10) + parseInt(manPowerTotalAmount,10)
  
  const withoutVatAmount = parseInt(overHeadTotal, 10) + parseInt(profitTotal,10)
    

      const percentageOptions = Array.from({ length: 100 }, (v, i) => ({
        value: i + 1,
        label: `${i + 1}%`,
      }));
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: true,
        validationSchema: yup.object({
          
        }),
        onSubmit: async (value) => {
          setIsLoading(true);
          const profitMargin = {
            ...value,
            materialTotal: materialTotalAmount,
            materialVatAmount: materialPercentageTotal,
            mpVatAmount: manPowerPercentageTotal,
            mpTotal: materialTotalAmount,
            withoutOPV: withoutOverallProVat,
            OHAmount: overHeadAmount,
            OHTotal: overHeadTotal,
            profitAmount: profitAmount,
            profitTotal: profitTotal,
            withoutVatAmount: withoutVatAmount,
            vatAmount: vatAmount,
            vatTotal: vatTotal,
            total: vatTotal,
          }; 
            axios
                .put(`/estimation/margin-profit/${estId||estimationId}`, profitMargin)
                .then((res) => {
                  if (res.status == 201 || res.data.status == true) {
                    setIsLoading(false);
                    setImage([]);
                    api.success({
                      message: `Add successfully`,
                      description: res?.data?.msg || " created",
                      placement: "bottomRight",
                    });
                    navigate(`/bidding/estimation/edit/terms-conditions/${estId}`);
                  } else {
                    setIsLoading(false);
                    api.error({
                      message: `Unsuccessfully`,
                      description: res?.data?.msg || "creation failed",
                      placement: "bottomRight",
                    });
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  api.error({
                    message: `Unsuccessfully`,
                    description: err?.response?.data?.msg || "creation failed",
                    placement: "bottomRight",
                  });
                });
        },
      });

      useEffect(() => {
        Number(setMaterialPercentageTotal((formik.values.materialVatPer / 100) * materialPrice));
       }, [formik.values.materialVatPer, materialPrice]);
       useEffect(() => {
         Number(setMaterialTotalAmount(parseInt(materialPercentageTotal,10) + parseInt(materialPrice,10)))
        }, [materialPercentageTotal, materialPrice]);
     
       useEffect(() => {
         setManPowerPercentageTotal((formik.values.mpVatPer / 100) * manPowerPrice);
       }, [formik.values.mpVatPer, manPowerPrice]);
       useEffect(() => {
         setManPowerTotalAmount(parseInt(manPowerPercentageTotal,10) + parseInt(manPowerPrice,10))
       }, [manPowerPercentageTotal, manPowerPrice]);

       useEffect(() => {
        Number(setOverHeadAmount((formik.values.OHPer / 100) * withoutOverallProVat));
       }, [formik.values.OHPer, withoutOverallProVat]);
    
       useEffect(() => {
        setOverHeadTotal(parseInt(overHeadAmount,10) + parseInt(withoutOverallProVat,10))
       }, [overHeadAmount, withoutOverallProVat]);
    
       useEffect(() => {
        Number(setProfitAmount((formik.values.profitPer / 100) * withoutOverallProVat));
       }, [formik.values.profitPer, withoutOverallProVat]);
    
       useEffect(() => {
        setProfitTotal(parseInt(profitAmount,10) + parseInt(withoutOverallProVat,10))
       }, [profitAmount, withoutOverallProVat]);

       useEffect(() => {
        Number(setVatAmount((formik.values.vatPer / 100) * withoutVatAmount));
       }, [formik.values.vatPer, withoutVatAmount]);
    
       useEffect(() => {
        setVatTotal(parseInt(vatAmount,10) + parseInt(withoutVatAmount,10))
       }, [vatAmount, withoutVatAmount]);

  return (
    <form onSubmit={formik.handleSubmit}>
    <div className='w-full h-[calc(100vh-180px)] overflow-hidden'>
  <div className='w-full h-[calc(100vh-280px)] overflow-hidden'>
    <div className='w-full h-full'>
      <table className="w-full border-collapse">
        <thead className="sticky top-0 z-[999]">
          <tr className='bg-[#1B2838]'>
            <th className="w-[20%]"></th>
            <th className="w-[20%]"></th>
            <th className="w-[20%] py-2 pl-1 text-left text-white text-xs font-medium">Percentage</th>
            <th className="w-[20%] py-2 pl-1 text-left text-white text-xs font-medium">Amount</th>
            <th className="w-[20%] py-2 pl-1 text-left text-white text-xs font-medium">Total</th>
          </tr>
        </thead>
      </table>
      <div className="w-full h-full overflow-y-auto pb-10">
      <table className="w-full border-collapse">
          <tbody>
            <tr className="w-full">
              <td className="w-[20%]"></td>
              <td className="w-[20%]">
                <p className="text-xs text-gray-800 font-medium py-5">Material :</p>
              </td>
              <td className="w-[20%]">
                {/* <NuInputNumber
                  label=""
                  formik={formik}
                  placeholder="Percentage"
                  name="materialVatPer"
                  width="w-full md:w-3/4"
                /> */}
                {`${dataPro?.materialVatPer}%`}
              </td>
              <td className="w-[20%]">
                <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                 <NuAmount value={materialPercentageTotal||formik.values.materialVatAmount}/>
                  {/* <NuInputNumber
                  label=""
                  value={materialPercentageTotal}
                  formik={formik}
                  placeholder="Amount"
                  name="materialVatAmount"
                  width="w-full"
                  disable={true}
                /> */}
                </div>
              </td>
              <td className="w-[20%]">
                <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={materialTotalAmount||formik.values.materialTotal}/>
                  {/* <NuInputNumber
                  label=""
                  value={materialTotalAmount}
                  formik={formik}
                  placeholder="Amount"
                  name="materialTotal"
                  width="w-full"
                  disable={true}
                /> */}
                </div>
              </td>
            </tr>
            <tr className="w-full">
              <td className="w-[20%]"></td>
              <td className="w-[20%]">
                <p className="text-xs text-gray-800 font-medium py-5">Manpower :</p>
              </td>
              <td className="w-[20%]">
                
                {/* <NuInputNumber
                  label=""
                  formik={formik}
                  placeholder="Percentage"
                  name="mpVatPer"
                  width="w-full md:w-3/4"
                /> */}
                {`${dataPro?.mpVatPer}%`}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={manPowerPercentageTotal||formik.values.mpVatAmount}/></div>
                {/* <NuInputNumber
                  label=""
                  value={manPowerPercentageTotal}
                  formik={formik}
                  placeholder="Amount"
                  name="mpVatAmount"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={manPowerTotalAmount||formik.values.mpTotal}/></div>
                {/* <NuInputNumber
                  label=""
                  value={manPowerTotalAmount}
                  formik={formik}
                  placeholder="Total"
                  name="mpTotal"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
            </tr>
            <tr className="w-full">
              <td colSpan={4} className="w-[80%]">
                <p className="text-sm text-gray-800 font-medium py-5">
                  Project Value without Overall Profit and VAT :
                </p>
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={withoutOverallProVat||formik.values.withoutOPV}/></div>
                {/* <NuInputNumber
                  label=""
                  value={withoutOverallProVat}
                  formik={formik}
                  placeholder="Total"
                  name="withoutOPV"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
            </tr>
            <tr className="w-full">
              <td className="w-[20%]"></td>
              <td className="w-[20%]">
                <p className="text-xs text-gray-800 font-medium py-5">Overhead :</p>
              </td>
              <td className="w-[20%]">
                {/* <NuInputNumber
                  label=""
                  formik={formik}
                  placeholder="Percentage"
                  name="OHPer"
                  width="w-full md:w-3/4"
                /> */}
                {`${dataPro?.OHPer}%`}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={overHeadAmount||formik.values.OHAmount}/></div>
                {/* <NuInputNumber
                  label=""
                  value={overHeadAmount}
                  formik={formik}
                  placeholder="Amount"
                  name="OHAmount"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={overHeadTotal||formik.values.OHTotal}/></div>
                {/* <NuInputNumber
                  label=""
                  value={overHeadTotal}
                  formik={formik}
                  placeholder="Total"
                  name="OHTotal"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
            </tr>
            <tr className="w-full">
              <td className="w-[20%]"></td>
              <td className="w-[20%]">
                <p className="text-xs text-gray-800 font-medium py-5">Profit :</p>
              </td>
              <td className="w-[20%]">
                {/* <NuInputNumber
                  label=""
                  formik={formik}
                  placeholder="Percentage"
                  name="profitPer"
                  width="w-full md:w-3/4"
                /> */}
                {`${dataPro?.profitPer}%`}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={profitAmount||formik.values.profitAmount}/></div>
                {/* <NuInputNumber
                  label=""
                  value={profitAmount}
                  formik={formik}
                  placeholder="Amount"
                  name="profitAmount"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={profitTotal||formik.values.profitTotal}/></div>
                {/* <NuInputNumber
                  label=""
                  value={profitTotal}
                  formik={formik}
                  placeholder="Total"
                  name="profitTotal"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
            </tr>
            <tr className="w-full">
              <td colSpan={4} className="w-[80%]">
                <p className="text-sm text-gray-800 font-medium py-5">
                  Total Estimated value including Profit (VAT not included) :
                </p>
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={withoutVatAmount||formik.values.withoutVatAmount}/></div>
                {/* <NuInputNumber
                  label=""
                  value={withoutVatAmount}
                  formik={formik}
                  placeholder="Total"
                  name="withoutVatAmount"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
            </tr>
            <tr className="w-full">
              <td className="w-[20%]"></td>
              <td className="w-[20%]">
                <p className="text-xs text-gray-800 font-medium py-5">VAT :</p>
              </td>
              <td className="w-[20%]">
                {/* <NuSelect
                  label=""
                  options={percentageOptions}
                  formik={formik}
                  placeholder="Choose"
                  name="vatPer"
                  width="w-full md:w-3/4"
                /> */}
                {`${dataPro?.vatPer}%`}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={vatAmount||formik.values.vatAmount}/></div>
                {/* <NuInputNumber
                  label=""
                  value={vatAmount}
                  formik={formik}
                  placeholder="Amount"
                  name="vatAmount"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={vatTotal||formik.values.vatTotal}/></div>
                {/* <NuInputNumber
                  label=""
                  value={vatTotal}
                  formik={formik}
                  placeholder="Total"
                  name="vatTotal"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
            </tr>
            <tr className="w-full">
              <td colSpan={4} className="w-[80%]">
                <p className="text-sm text-gray-800 font-medium py-5">
                  Total Amount (VAT included) :
                </p>
              </td>
              <td className="w-[20%]">
              <div
                  className="w-[97%] h-8 rounded-md px-2 flex justify-end items-center text-xs"
                  style={{ border: "1px solid #cbd5e1" }}
                >
                   <NuAmount value={vatTotal||formik.values.total}/></div>
                {/* <NuInputNumber
                  label=""
                  value={vatAmount}
                  formik={formik}
                  placeholder="Total"
                  name="total"
                  width="w-full"
                  disable={true}
                /> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</form>
  )
}

export default ViewProfitMargin;