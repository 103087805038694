import React from 'react'
import MainActivity from '../Pages/Web/Item/Activity/MainActivity';
import MainCondition from '../Pages/Web/Item/TeamandCondition/MainCondition';
import IndexActivity from '../Pages/Web/Item/Activity/Index';
import EditActivity from '../Pages/Web/Item/Activity/EditActivity';
import ViewActivity from '../Pages/Web/Item/Activity/ViewActivity';
import IndexTeamCondition from '../Pages/Web/Item/TeamandCondition/Index';
import { Route } from 'react-router-dom';
import MainBidding from '../Pages/Web/Bidding/MainBidding';
import CreateCondition from '../Pages/Web/Item/TeamandCondition/CreateCondition';
import EditCondition from '../Pages/Web/Item/TeamandCondition/EditCondition';
import ViewCondition from '../Pages/Web/Item/TeamandCondition/ViewCondition';
import MainItem from '../Pages/Web/Item/MainItem';
import { Wrapper } from '../Components/Utils/roles';

function ItemRoutes() {
    return (
        <Route path="item" element={<MainItem />}>
          <Route path="activity" element={<MainActivity />}>
            <Route index element={ < Wrapper accessKey={"activity_item_view"}  Component={IndexActivity} /> } />
            {/* <Route path="create" element={<CreateActivity />} /> */}
            {/* <Route path="edit/:activityEditId" element={<EditActivity />} /> */}
            <Route path="view/:activityViewId" element={ < Wrapper accessKey={"activity_item_view"}  Component={ViewActivity} />} />
          </Route>
          <Route path="teamandcondition" element={<MainCondition />}>
            <Route index element={ < Wrapper accessKey={"terms_and_condition_view"}  Component={IndexTeamCondition} /> } />
            <Route path="create" element={ < Wrapper accessKey={"terms_and_condition_create"}  Component={CreateCondition} /> } />
            <Route path="edit/:teamandconditionEditId" element={ < Wrapper accessKey={"terms_and_condition_edit"}  Component={EditCondition} /> } />
            <Route path="view/:teamandconditionViewId" element={ < Wrapper accessKey={"terms_and_condition_view"}  Component={ViewCondition} /> } />
          </Route>
        </Route>
      );
}

export default ItemRoutes