import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { Wrapper } from '../Components/Utils/roles'


const MainNotes =lazy(()=>import('../Pages/Web/Notes/MainNotes')) 
const Notes =lazy(()=>import('../Pages/Web/Notes')) 
const NoteCreate =lazy(()=>import('../Pages/Web/Notes/CreateNote')) 
const NoteEdit =lazy(()=>import('../Pages/Web/Notes/EditNote'))
const NotesView =lazy(()=>import('../Pages/Web/Notes/ViewNotes'))

function NoteRoutes() {
  return (
    <Route path="mainNotes" element={<MainNotes />}>
    <Route index element={< Wrapper accessKey={true}  Component={Notes} />} />
    <Route path="CreateNotes" element={< Wrapper accessKey={true}  Component={NoteCreate} />} />
    <Route path="editNotes/:notesId" element={< Wrapper accessKey={true}  Component={NoteEdit} />} />
    <Route path="viewNotes/:notesId" element={< Wrapper accessKey={true}  Component={NotesView} />} />
  </Route>
  )
}

export default NoteRoutes