import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../Services/axiosCommon';

function ViewTermsAndCondition() {
    const { estimationViewId } = useParams();
    let estId = Number(estimationViewId)
    const [termsAndConditionList,setTermsAndConditionList] = useState([]);
    useEffect(()=>{
        getTermsAndConditionList(estId)
      },[estId])

      function getTermsAndConditionList(id){
        axiosInstance.get(`/estimation/terms-and-conditions/${id}`).then(res=>{
            setTermsAndConditionList(res.data.data);
        }).catch((err)=>{
            console.log(err);
        })
      }
  return (
    <div className="w-full h-[calc(100vh-340px)] overflow-y-auto">
        <table className=" w-full border-collapse">
            <thead className="sticky top-0">
              <tr className="bg-[#1B2838]">
               <th className=" w-[5%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Sl.NO
                </th>
                <th className=" w-[10%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Item Code
                </th>
                <th className=" w-[80%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Description
                </th>
                {/* <th className=" w-[5%]  py-2 text-center text-white text-xs font-medium">
                  Action
                </th> */}
              </tr>
            </thead>
            <tbody>
              {termsAndConditionList?.map((list, index) => (
                <tr
                  key={index}
                  className="w-full  odd:bg-white even:bg-slate-50"
                >
                  <td className="w-[5%] pl-1 py-2 text-left text-black text-xs font-light">
                    {index + 1}
                  </td>
                  <td className="w-[10%] pl-1 py-2 text-left text-black text-xs font-light">
                    {list?.item_code || "-"}
                  </td>
                  <td className="w-[80%] pl-1 py-2 text-left text-black text-xs font-light">
                    {list?.description || "-"}
                  </td>
                  {/* <td className="w-[5%] ">
                    <div className=" w-full flex justify-center items-center">
                      <button
                        onClick={() => handleDelete(index,list?.termsAndCondition)}
                        type="button"
                        className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                      >
                        <DeleteIcon color="red" height={4} />
                      </button>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
  )
}

export default ViewTermsAndCondition