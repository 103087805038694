import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { Wrapper } from '../Components/Utils/roles'

const MainProject  =lazy(()=>import("../Pages/Web/Project/projectMain")) 
const AllProject =lazy(()=>import("../Pages/Web/Project")) 
const CreateProject =lazy(()=>import("../Pages/Web/Project/createProject"))
const EditProject =lazy(()=>import("../Pages/Web/Project/editProject"))
const ViewProject =lazy(()=>import("../Pages/Web/Project/ProjectView"))

const ProjectRoutes = () => {
  return (
    <Route path="projects" element={<MainProject />}>
    <Route index element={< Wrapper accessKey={"project_view"}  Component={AllProject} />} />
    <Route path="create" element={< Wrapper accessKey={"project_create"}  Component={CreateProject} />} />
    <Route path="edit/:projectEditId" element={< Wrapper accessKey={"project_edit"}  Component={EditProject} />} />
    <Route path="view/:projId" element={< Wrapper accessKey={"project_view"}  Component={ViewProject} />} />
  </Route>
  )
}

export default ProjectRoutes