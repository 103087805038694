import React, { useEffect, useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { NuInputNumber, NuInputNumberEsti, NuInputText, NuSelect, NuSelectEsti, NuSelectwithSearch } from "../../../../../Components/Utils/NuInput";
import { CancelButton, SubmitButton } from "../../../../../Components/Utils/Buttons";
import NuAmount from "../../../../../Components/Utils/NuAmount";
import DeleteIcon from "../../../../../Components/Icons/Delete";
import * as yup from 'yup';
import { useFormik } from "formik";
import axios from "../../../../../Services/axiosCommon";
import useFetchMataData from "../../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../../Components/Hooks/useDropDown";
import axiosInstance from "../../../../../Services/axiosCommon";
import { useNumber } from "../../../../../Context/ProjectIdContext";

const { confirm } = Modal;

function MaterialActivity({ data, projectId }) {
  const {estimationId,updateEstimation,number} = useNumber()
  const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const [materialList, setMaterialList] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [createNew, setCreateNew] = useState({
    estimationId: "",
    items: [],
    materialTotalPrice: "",
    projectId: ""
  })

  // Handle input changes for the material list
  const handleMaterialChange = (index, field, value) => {
    console.log('Before:', materialList);
    setMaterialList((prevMaterials) => {
      const updatedMaterials = [...prevMaterials];
      updatedMaterials[index] = {
        ...updatedMaterials[index],
        [field]: value,
      };
      const quantity = field === "quantity" ? value : updatedMaterials[index].quantity;
      const unitPrice = field === "unitPrice" ? value : updatedMaterials[index].unitPrice;
      updatedMaterials[index].totalPrice = quantity * unitPrice;
      return updatedMaterials;
    });
    // console.log('After:', updatedMaterials);
    // setMaterialList(updatedMaterials);
  };

  const [totalAmount, setTotalAmount] = useState(0);
  const [activity, setActivity] = useState([]);

  async function getActivity() {
      axiosInstance.get(`/Activity/drop-down`).then(res => {
          // console.log('daataa',res.data?.data);
          let data = res.data.data;
          console.log(data,'dddd');
          let tempData = [];
          data.forEach((item) => {
              let tempObject = { value: item.value, label: item.label };
              tempData.push(tempObject);
          });
          res.data.data && setActivity(tempData);
      }).catch(err => {
          console.log(err, "err");
      })
  }
  useEffect(() => {
    const calculatedTotal = materialList.reduce(
      (acc, item) => acc + (item.quantity * item.unitPrice || 0),
      0
    );
    setTotalAmount(calculatedTotal);
  }, [materialList]);

  useEffect(()=>{
    getActivity();
  },[])

  const AddMaterial = () => {
    const tempValue = {
      item: formik.values.item || "",
      unit: "",
      quantity: "",
      unitPrice: "",
      totalPrice: "",
    };
    setMaterialList([...materialList, tempValue]);
  
    // Clear the form fields after adding the material
    formik.setFieldValue("item", "");
  };

  const handleRemoveMaterialItem = (index) => {
    confirm({
      title: `Do you want to delete this item?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const updatedMaterials = materialList.filter((_, i) => i !== index);
        setMaterialList(updatedMaterials);
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
      // projectName: yup.string().required("Project Name is Required"),
      // clientId: yup.string().required("Client "),
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      const activityData = {
        ...value,
        projectId: number,
        items: materialList,
        materialTotalPrice: totalAmount
      }

      // data
      //   ? axios
      //       .put(`/bidding/update_bidding/${data?.biddingId}`, formData)
      //       .then((res) => {
      //         if (res.status == 201 || res.data.status == true) {
      //           setIsLoading(false);
      //           setImage({ preview: "", raw: "" });
      //           api.success({
      //             message: `Add successfully`,
      //             description: res?.data?.msg || " created",
      //             placement: "bottomRight",
      //           });
      //           navigate(-1);
      //         } else {
      //           setIsLoading(false);
      //           api.error({
      //             message: `Unsuccessfully`,
      //             description: res?.data?.msg || "creation failed",
      //             placement: "bottomRight",
      //           });
      //         }
      //       })
      //       .catch((err) => {
      //         setIsLoading(false);
      //         api.error({
      //           message: `Unsuccessfully`,
      //           description: err?.response?.data?.msg || "creation failed",
      //           placement: "bottomRight",
      //         });
      //       })
      //   : 
        axios
            .post("/estimation/material", activityData)
            .then((res) => {
              if (res.status == 201 || res.data.status == true) {
                setIsLoading(false);
                setImage([]);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                console.log("est id",res?.data?.estId);
                
                updateEstimation(res?.data?.estId)
                navigate(`/bidding/estimation/create/manpower/${res?.data?.estId}`);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });
   console.log("finding",materialList);
  return (
    <div className="w-full h-[calc(100vh-160px)] overflow-hidden">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex gap-2 items-center">
          <NuSelectwithSearch
            label="Description"
            options={activity}
            isRequired={true}
            placeholder="Description"
            formik={formik}
            name="item"
            width="w-full md:w-1/2 lg:w-[70%]"
          />
          <button
            type="button"
            className="w-12 mt-6 p-1 my-3 text-center h-8 cursor-pointer bg-[#1B2838] border border-solid border-white text-white font-semibold rounded-lg"
            onClick={AddMaterial}
          >
            +
          </button>
        </div>

        <div className="w-full h-[calc(100vh-340px)] overflow-hidden">
          <div className="w-full h-full">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 z-[999]">
              <tr className="bg-[#1B2838]">
                <th className="w-[5%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Sl.No
                </th>
                <th className="w-[54%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Description
                </th>
                <th className="w-[8%] py-2 text-center text-white text-xs font-medium">
                  Unit
                </th>
                <th className="w-[8%] py-2 text-center text-white text-xs font-medium">
                  Quantity
                </th>
                <th className="w-[10%] py-2 text-center text-white text-xs font-medium">
                  Unit Price
                </th>
                <th className="w-[10%] py-2 text-center text-white text-xs font-medium">
                  Amount
                </th>
                <th className="w-[5%] py-2 text-center text-white text-xs font-medium">
                  Action
                </th>
              </tr>
            </thead>
           </table>
           <div className="w-full h-full overflow-y-auto pb-5">
           <table className="w-full border-collapse">
            <tbody className="">
              {materialList?.map((list, index) => (
                <tr
                  key={index}
                  className="w-full odd:bg-white even:bg-slate-50"
                >
                  <td className="w-[5%] pl-1 py-2 text-left text-black text-xs font-light">
                    {index + 1}
                  </td>
                  <td className="w-[54%] pl-1 py-2 text-left text-black text-xs font-light">
                    <p className="text-left text-black text-xs font-light">
                      {list?.item}
                    </p>
                  </td>
                  <td className="w-[8%] py-2 text-left text-black text-xs font-light">
                    <NuSelectEsti
                      options={Unit || []}
                      placeholder="Choose"
                      value={list?.unit}
                      width="w-full"
                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "unit",
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className="w-[8%] py-2 text-left text-black text-xs font-light">
                    <NuInputNumberEsti
                      placeholder="Quantity"
                      value={list.quantity}
                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "quantity",
                          e.target.value
                        )
                      }
                      width="w-full"
                      // formik={formik}
                    />
                  </td>
                  <td className="w-[10%] py-2 text-left text-black text-xs font-light">
                    <NuInputNumberEsti
                      placeholder="Unit Price"
                      value={list.unitPrice}
                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "unitPrice",
                          e.target.value
                        )
                      }
                      width="w-full"
                      // formik={formik}
                    />
                  </td>
                  <td className="w-[10%] py-2 text-left text-black text-xs font-light">
                    <NuInputNumberEsti
                      placeholder="Total Price"
                      value={
                        list.quantity * list.unitPrice || 0
                      }
                      disable={true}
                      width="w-full"                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "totalPrice",
                          e.target.value
                        )
                      }
                      // formik={formik}
                    />
                  </td>
                  <td className="w-[5%]">
                    <div className="w-full flex justify-center items-center">
                      <button
                        type="button"
                        onClick={() => handleRemoveMaterialItem(index)}
                        className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                      >
                        <DeleteIcon color="red" height={4} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          </div>
        </div>
        <div className="w-full h-8">
          <table className="w-full">
            <tfoot className="w-full bg-white">
              <tr className="flex justify-end items-center">
                <td className="w-[10%] py-1 text-right text-xs text-gray-800 font-medium">
                  Total Amount:
                </td>
                <td className="w-[15%] py-1 text-right text-[13px] text-gray-800 font-medium">
                  <NuAmount
                    value={totalAmount}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="w-full flex gap-2 justify-end items-center">
          <CancelButton handleClick={() => navigate(-1)} />
          <SubmitButton isLoading={isLoading} />
        </div>
      </form>
    </div>
  );
}

export default MaterialActivity;
