import { Layout } from "antd";
import { useContext, createContext, useState, useEffect } from "react"
import { Link } from "react-router-dom";
import Settings from "../Icons/Settings";
import CircleLeftArrow from "../Icons/CircleLeftArrow";
import CircleRightArrow from "../Icons/CircleRightArrow";
import Logo from '../../assets/Icons/NuTaskLetter.png'


// import Settings from "../../Components/Icons/Settings";

const SidebarContext = createContext()



const Submenu = ({ subItems }) => {


  const [topPosition, setTopPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
 
  useEffect(() => {
    const screenHeight = window.innerHeight;
    const submenuHeight = subItems.length * 75; // Adjust this value based on the actual submenu item height
    if (screenHeight < submenuHeight) {
      console.log(screenHeight - submenuHeight);
      setTopPosition(screenHeight - submenuHeight);
    }
    setIsVisible(true);
  }, [subItems]);

  return(
  
    <div   style={{ top: `${topPosition}px` }}
    //  className={`absolute rounded-md border border-[#048178] border-solid border-opacity-20 overflow-hidden left-full bg-[#E6F2F2] w-48 shadow-lg p-2 ${isVisible ? 'transition-opacity duration-1000 opacity-100' : 'opacity-0'} ease-in-out`}
    className={`
    absolute left-full  rounded-md
    bg-[#119DEE]/90 text-white  text-left
    invisible opacity-20 -translate-x-3 transition-all
    group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 w-52 text-sm
`}
     >
      {subItems.map((subItem, subIndex) => (
        <Link
          key={subIndex}
          to={`${subItem.link}`}
          className={`flex justify-start text-xs px-3 py-[10px] hover:bg-[#119DEE]/90 text-white bg-[#1B2838] hover:text-white`}
        >
          {/* <i className={`fas fa-${subItem.icon} text-lg mr-2`}></i> */}
          <p className={` text-xs text-left `}>{subItem.name}</p>
        </Link>
      ))}
    </div>
  );
};


export default function Sidebar({ children }) {
  const { Sider } = Layout;

  const [expanded, setExpanded] = useState(true)
 
  

  function getExpand(){
    let locExp = localStorage.getItem('expand')
    if (locExp) {
      setExpanded(JSON.parse(locExp));
    }else{
      localStorage.setItem('expand', true)
      setExpanded(true)
    }
  }

  useEffect(() => {
    getExpand()
  },[])


  function toggleExpanded(e){
    setExpanded(e)
    localStorage.setItem('expand',e)
  }
  
  return (
    <Sider
    breakpoint="lg"
    collapsedWidth="0"
    width={expanded?"10%":"5%"}
    style={{
      background: "#1B2838",
    //   overflow: "hidden",
      borderRight: "1px solid #E5E8ED",
      zIndex:999
    }}
    // collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
    trigger={null}
  >
    <aside className="h-full z-[9999]">
      <nav className="h-full flex flex-col bg-baseColor-primary border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <img
            src={Logo}
            className={`overflow-hidden transition-all ${
              expanded ? "w-32" : "w-0"
            }`}
            alt=""
          />
          <button
            onClick={() => toggleExpanded(!expanded)}
            className={` ${expanded?"":"w-full"} bg-transparent outline-none border-none cursor-pointer`}
          >
            {expanded ? <CircleLeftArrow/> : <CircleRightArrow />}
          </button>
        </div>
        <div className=" w-full h-[calc(100vh-120px)] overflow-y-auto">
        <SidebarContext.Provider value={{ expanded }}>
          <ul className={`flex-1 ${expanded ? "px-0" : "px-3"}`} >{children}</ul>
        </SidebarContext.Provider>
        </div>
        <div className="flex p-3">
        <Link className="flex justify-between items-center" to="/settings">
          <div className="p-2 bg-[#119DEE]/90 flex justify-center items-center rounded-lg">
          <Settings/>
          
          </div>
          </Link>
          <Link className="flex justify-between items-center" to="/settings">

          <div
            className={`
              flex justify-between items-center
               overflow-hidden transition-all ${expanded ? "w-28 xl:w-32 ml-3" : "w-0"}
          `}
          >
          <div className="leading-4">
              <h4 className="font-semibold  text-xs 2xl:text-sm text-white">Settings</h4>
            </div>
          
          </div>
          </Link>
        </div>
      </nav>
    </aside>
    </Sider>
  )
}

export function SidebarItem({ icon, text, active, alert,hasSubMenu,subItem,link,color,access }) {
  const { expanded } = useContext(SidebarContext)
  
  
  
  return (
    <>
    {
        access && (
            <li
            className={`
              relative flex items-center justify-center px-3
              font-medium cursor-pointer
              transition-colors group
              h-9
              ${
                active ==true
                  ? `bg-[#119DEE]/90 text-white ${color="#FFAD00"}`
                  : `hover:bg-[#119DEE]/90 text-white hover:text-white ${color="white"}`
              }
              ${
                expanded ? "rounded-none my-0 py-2 " : "rounded-md my-1 py-1"
              }
          `}
          >
            {
              hasSubMenu ? <span className=" w-4 flex justify-center items-center text-xs ">
              <Link>
              {icon}
              </Link>  
              </span>
              :
              <Link to={link}>
                <span className=" w-4 justify-center items-center  text-xs ">
              {icon}
              </span>
              </Link> 
            }
            
            <span
              className={`overflow-hidden transition-all text-xs  ${
                expanded ? `w-28 xl:w-32 ml-3 ` : "w-0"
              }`}
            >
             {hasSubMenu ? <Link className=" text-white hover:text-white">{text}</Link> : <Link to={link} className=" text-white hover:text-white">{text}</Link> }  
            </span>
            {alert && (
              <div
                className={`absolute right-2 w-2 h-2 rounded bg-indigo-400 ${
                  expanded ? "" : "top-2"
                }`}
              />
            )}
            {hasSubMenu && (
                  <Submenu subItems={subItem||[]} />
            )}
          </li>
        )
    }
    </>
  )
}