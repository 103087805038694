import React,{lazy} from "react";
import { Route } from "react-router-dom";
import MainFinance from "../Pages/Web/PettyCash/MainPettyCash";
import MainReceived from "../Pages/Web/PettyCash/Received/MainReceived";
import ReceivedIndex from "../Pages/Web/PettyCash/Received/ReceivedIndex";
import CreateReceived from "../Pages/Web/PettyCash/Received/CreateReceived";
import EditReceived from "../Pages/Web/PettyCash/Received/EditReceived";
import ViewReceived from "../Pages/Web/PettyCash/Received/ViewReceived";
import MainIssued from "../Pages/Web/PettyCash/Issued/MainIssued";
import IssuedIndex from "../Pages/Web/PettyCash/Issued/IssuedIndex";
import CreateIssued from "../Pages/Web/PettyCash/Issued/CreateIssued";
import EditIssued from "../Pages/Web/PettyCash/Issued/EditIssued";
import ViewIssued from "../Pages/Web/PettyCash/Issued/ViewIssued";
import { Wrapper } from "../Components/Utils/roles";


function PettyCashRoutes() {
    return (
      <Route path="petty-cash" element={<MainFinance />}>
        <Route path="received" element={<MainReceived />}>
          <Route index element={< Wrapper accessKey={true}  Component={ReceivedIndex} />} />
          <Route path="create" element={< Wrapper accessKey={true}  Component={CreateReceived} />} />
          <Route path="edit/:receivedEditId" element={< Wrapper accessKey={true}  Component={EditReceived} />} />
          <Route path="view/:receivedViewId" element={< Wrapper accessKey={true}  Component={ViewReceived} />} />
        </Route>
        <Route path="issued" element={<MainIssued />}>
          <Route index element={< Wrapper accessKey={true}  Component={IssuedIndex} />} />
          <Route path="create" element={< Wrapper accessKey={true}  Component={CreateIssued} />} />
          <Route path="edit/:issuedEditId" element={< Wrapper accessKey={true}  Component={EditIssued} />} />
          <Route path="view/:issuedViewId" element={< Wrapper accessKey={true}  Component={ViewIssued} />} />
        </Route>
      </Route>
    );
  }
  
  export default PettyCashRoutes;