import { Outlet } from "react-router-dom"
const MainTasksPlan = () =>{

    return(
        <div className=" w-full h-full">
         <Outlet/>
        </div>
    )
}


export default MainTasksPlan;