import AccessDenied from "./AccessDenied";
import DecryptData from "./Decrypt";


export function GetAccess(accessText){

    if(GetRole()){
        return true
    }else{
        const userAccess = localStorage.getItem("SsEcCaReSuOrPnU")?DecryptData("SsEcCaReSuOrPnU"):[]
        console.log("Test " ,userAccess);
        
        const access = userAccess?userAccess.some((permission)=>permission.permissionName == accessText ):true;
        return access;
    }
    
}




export function GetRole(){
    const userData = localStorage.getItem("SlIaTeDrEsUoRpNu")?DecryptData("SlIaTeDrEsUoRpNu"):[];
    const role = userData?.role;
    if(role ==="scroll_admin"||role==="admin"){
        return true
    }else{
        return false
    }
}


export function RendorPage(accessKey,Component){
if( (GetRole()||GetAccess( accessKey)) == true){
    return <Component/>
}else{
    return <AccessDenied/>
}
}


export function Wrapper({accessKey,Component}){
    return RendorPage(accessKey, Component);
  }