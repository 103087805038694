import { Modal, notification } from "antd";
import {  useState } from "react";
import { useFormik } from "formik";
import axiosInstance from "../../../../Services/axiosCommon";
import { NuInputfile } from "../../../../Components/Utils/NuInput";


const ImportAllTask = ({ open, getData, onCancel,firstProject }) => {
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState({ preview: "", raw: "" });


  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: "",
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (value,{resetForm}) => {
      // formik.resetForm({ values: "" });
      let formData  = new FormData();
      formData.append('file',image.raw)
      axiosInstance.post(`/title/import/${firstProject}`, formData)
      .then((res) => {
          console.log("res",res.data.status);
          if (res.data.status) {
            getData(firstProject);
            setImage({ preview: "", raw: "" })
            resetForm();
            api.success({
              message: `Title Update successfully`,
              description: res?.data?.msg || "Added In The Title",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            api.error({
              message: `Title Upadate failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          api.error({
            message: `failed`,
            description: err?.response?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title={ "Import Task"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
          <NuInputfile label='Upload' placeholder='Select File' handleChange={handleChangeImg}  name='cmt' width='w-full' />
          <button
              type="submit"
              // htmlType="submit"
              // size="large"
              className="w-11/12 bg-[#119DEE] py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
            >
              Import
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ImportAllTask;
