import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { Wrapper } from '../Components/Utils/roles';

const User = lazy(()=>import('../Pages/User/index'));
const UserProfile  = lazy(()=>import('../Pages/User/userProfile'))
const EditProfile  = lazy(()=>import('../Pages/User/editProfile'))
const ChangePassword  = lazy(()=>import('../Pages/User/changePassword'))
const ProfileNotificationSettings = lazy(()=>import('../Pages/User/notication'))

function UserDetailsRoutes() {
  return (
    <Route path="user-details" element={<User />}>
    <Route index element={< Wrapper accessKey={true}  Component={UserProfile} />} />
    <Route path="edit" element={< Wrapper accessKey={true}  Component={EditProfile} />} />
    <Route path="password-change" element={< Wrapper accessKey={true}  Component={ChangePassword} />} />
    <Route path="notifications" element={< Wrapper accessKey={true}  Component={ProfileNotificationSettings} />} />
  </Route>
  )
}

export default UserDetailsRoutes