import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useNumber } from '../../../../../Context/ProjectIdContext';
import axiosInstance from '../../../../../Services/axiosCommon';
import NuAmount from '../../../../../Components/Utils/NuAmount';

function ViewMaterialActivity() {
    const {updateEstimation,estimationId,number} = useNumber()
  const { estimationViewId } = useParams();
  let estID = Number(estimationViewId);
//   const {value:Unit} = useFetchMataData(`/Metadata/unit`)
  const [materialList, setMaterialList] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
//   const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
    function GetAllMet(id){
        axiosInstance.get(`/estimation/materials/${id}`).then(res=>{
          console.log("dddd",res.data?.data);
          setMaterialList(res.data.data);
          
        }).catch(err=>{
          console.log(err);
          
        })
       }
    
       useEffect(() => {
        GetAllMet(estimationViewId)
       },[estimationViewId])
       useEffect(() => {
        const calculatedTotal = materialList.reduce(
          (acc, item) => acc + (item.quantity * item.unitPrice || 0),
          0
        );
        setTotalAmount(calculatedTotal);
      }, [materialList]);
  return (
    <>
      <div className="w-full h-[calc(100vh-340px)] overflow-hidden">
          <div className="w-full h-full">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 z-[999]">
              <tr className="bg-[#1B2838]">
                <th className="w-[5%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Sl.No
                </th>
                <th className="w-[54%] py-2 text-left pl-1 text-white text-xs font-medium">
                  Description
                </th>
                <th className="w-[8%] py-2 text-center text-white text-xs font-medium">
                  Unit
                </th>
                <th className="w-[8%] py-2 text-center text-white text-xs font-medium">
                  Quantity
                </th>
                <th className="w-[10%] py-2 text-center text-white text-xs font-medium">
                  Unit Price
                </th>
                <th className="w-[10%] py-2 text-center text-white text-xs font-medium">
                  Amount
                </th>
                {/* <th className="w-[5%] py-2 text-center text-white text-xs font-medium">
                  Action
                </th> */}
              </tr>
            </thead>
           </table>
           <div className="w-full h-full overflow-y-auto pb-5">
           <table className="w-full border-collapse">
            <tbody className="">
              {materialList?.map((list, index) => (
                <tr
                  key={index}
                  className="w-full odd:bg-white even:bg-slate-50"
                >
                  <td className="w-[5%] pl-1 py-2 text-left text-black text-xs font-light">
                    {index + 1}
                  </td>
                  <td className="w-[54%] pl-1 py-2 text-left text-black text-xs font-light">
                    <p className="text-left text-black text-xs font-light">
                      {list?.item}
                    </p>
                  </td>
                  <td className="w-[8%] py-2 text-center text-black text-xs font-light">
                    {/* <NuSelectEsti
                      options={Unit || []}
                      placeholder="Choose"
                      value={list?.unit}
                      width="w-full"
                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "unit",
                          e.target.value
                        )
                      }
                    /> */}
                    {list?.unit}
                  </td>
                  <td className="w-[8%] py-2 text-center text-black text-xs font-light">
                    {/* <NuInputNumberEsti
                      placeholder="Quantity"
                      value={list.quantity}
                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "quantity",
                          e.target.value
                        )
                      }
                      width="w-full"
                      // formik={formik}
                    /> */}
                    {list?.quantity}
                  </td>
                  <td className="w-[10%] py-2 text-center text-black text-xs font-light">
                    {/* <NuInputNumberEsti
                      placeholder="Unit Price"
                      value={list.unitPrice}
                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "unitPrice",
                          e.target.value
                        )
                      }
                      width="w-full"
                      // formik={formik}
                    /> */}
                    {list?.unitPrice}
                  </td>
                  <td className="w-[10%] py-2 text-center text-black text-xs font-light">
                    {/* <NuInputNumberEsti
                      placeholder="Total Price"
                      value={
                        list.quantity * list.unitPrice || 0
                      }
                      disable={true}
                      width="w-full"                      handleChange={(e) =>
                        handleMaterialChange(
                          index,
                          "totalPrice",
                          e.target.value
                        )
                      }
                      // formik={formik}
                    /> */}
                    {list.quantity * list.unitPrice || 0}
                  </td>
                  {/* <td className="w-[5%]">
                    <div className="w-full flex justify-center items-center">
                      <button
                        type="button"
                        onClick={() => handleRemoveMaterialItem(index)}
                        className="text-red-500 cursor-pointer bg-white ml-4 border-none"
                      >
                        <DeleteIcon color="red" height={4} />
                      </button>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          </div>
        </div>
        <div className="w-full h-8">
          <table className="w-full">
            <tfoot className="w-full bg-white">
              <tr className="flex justify-end items-center">
                <td className="w-[10%] py-1 text-right text-xs text-gray-800 font-medium">
                  Total Amount:
                </td>
                <td className="w-[15%] py-1 text-right text-[13px] text-gray-800 font-medium">
                  <NuAmount
                    value={totalAmount}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
    </>
  )
}

export default ViewMaterialActivity