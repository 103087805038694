import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { Link, useLocation } from 'react-router-dom'
import Dasboard from '../../../../Components/Icons/Dasboard'
import { BackButton } from '../../../../Components/Utils/Buttons'
import { Tab, Tabs } from '../../../../Components/Layouts/Tabs'
import MaterialActivity from './Components/MaterialActivity'
import Manpower from './Components/Manpower'
import ProfitMargin from './Components/ProfitMargin'
import TearmsAndCondition from './Components/TearmsAndCondition'
import EstimateAttachments from './Components/Attachments'
import axiosInstance from '../../../../Services/axiosCommon'
import { useNumber } from '../../../../Context/ProjectIdContext'
import { Select } from 'antd'
import ManPowerTesting from './Components/ManPowerTesting'

function CreateEstimationNew() {
  const { number, updateNumber } = useNumber();
  const [firstProject, setFirstProject] = useState("");
  const location = useLocation();
  const projectTask = location?.state?.projectTask || "";
  const [projectList, setProjectList] = useState([]);
  async function getProject() {
    await axiosInstance
      .get(`/Team/getUserProjectId`)
      .then(async (res) => {
        if (number != 0) {
          setFirstProject(number);
        } else if (projectTask) {
          console.log("DD", projectTask);
          setFirstProject(projectTask);
          updateNumber(projectTask);
        } else {
          res?.data?.data[0] && setFirstProject(res?.data?.data[0]?.ProjectId);
          updateNumber(res?.data?.data[0]?.ProjectId);
          console.log("dd", res?.data?.data[0]?.ProjectId);
        }
        let list = res?.data?.data || [];
        let finalData = [];
        (await res?.data?.data) &&
          list.map((item, idx) => {
            finalData.push({
              value: item.ProjectId,
              label: item.ProjectName,
            });
          });
        setProjectList(finalData);

        // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
        // console.log('====',res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleProjectChange(e) {
    setFirstProject(e);
    updateNumber(e);
  }

  console.log("pro",firstProject);

  useEffect(()=>{
     getProject();
  },[])
  
  return (
    <div className="w-full h-full mx-auto py-2 px-4 ">
        <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/bidding/estimation">
                  <p className=" text-[12px] ">Estimation</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
        </div>
        <div className=" w-full md:w-3/12 flex gap-2 items-center mb-3">
            <div className="hidden md:block font-medium text-xs">Project </div>
            <Select
              style={{ width: "100%" }}
              options={projectList}
              value={projectList.find((i) => i.value == firstProject) || ""}
              disabled={projectList.length >= 2 ? false : true}
              // allowClear={formik.values[name]?true:false}
              // name={name}
              // placeholder={placeholder}
              onChange={handleProjectChange}
            />
          </div>
        <div
        className=" w-full mx-auto h-[calc(100vh-160px)] rounded-[6px] mt-2 overflow-hidden"
        style={{ border: "1px solid #C5C5C5" }}
      >
    <Tabs>
      <Tab component={<MaterialActivity projectId={firstProject} />} active>Material / Activity</Tab>
      <Tab component={<ManPowerTesting/>} >Manpower</Tab>
      <Tab component={<ProfitMargin/>}>Profit Margin</Tab>
      <Tab component={<TearmsAndCondition projectId={firstProject} />}>Terms & Conditions</Tab>
      <Tab component={<EstimateAttachments projectId={firstProject} />}>Attachments</Tab>
    </Tabs>
    </div>
    </div>
  )
}

export default CreateEstimationNew