import React from "react";
import { useEffect, useState } from "react";
import {
  NuDatePicker,
  NuInputNumber,
  NuInputText,
  NuLabel,
  NuSelect,
  NuTextArea,
} from "../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";
import NuDate from "../../../../Components/Utils/NuDate";
import * as yup from "yup";
import { notification } from "antd";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";

export default function EditReceived() {
  const { value: Status } = useFetchMataData(`/Metadata/noteStatus`);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const [createNew, setCreateNew] = useState({
    projectId: "",
    date: "",
    purpose:"",
    requestedAmount: "",
    approvedAmount:"",
    comments: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
    //   title: yup.string().required("Title is Required"),
    //   status: yup.string().required("Please Select Status"),
    }),
    onSubmit: async (value) => {
      setIsLoading(true);

      data
        ? axios
            .put(`/petty_cash/${data?.pettyCashId}`, value)
            .then((res) => {
              if (res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/petty_cash/", value)
            .then((res) => {
              if (res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });

  async function getProject() {
    await axios
      .get(`/Team/getUserProjectId`)
      .then(async (res) => {
        let list = res?.data?.data || [];
        let finalData = [];
        (await res?.data?.data) &&
          list.map((item, idx) => {
            finalData.push({
              value: item.ProjectId,
              label: item.ProjectName,
            });
          });
        setProjectList(finalData);

        // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
        // console.log('====',res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getProject();
  }, []);


  useEffect(() => {
    setCreateNew({
      projectId:data?.projectId||"",
      purpose:data?.purpose||"",
      requestedAmount:data?.requestedAmount||"",
      approvedAmount:data?.approvedAmount||"",
      date:(data?.date !="" && NuDate({ value:data?.date, format: "YYYY-MM-DD" }) )||"",
      comments:data?.comments||""
    })
  },[data])

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/petty-cash/received">
                  <p className=" text-[12px] ">Petty Cash</p>
                </Link>
              ),
            },
            {
              title: (
                <Link to="/petty-cash/received">
                  <p className=" text-[12px] ">Received</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Edit</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[85%] rounded-[6px] mt-2"
          style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Patty Cash" />
              {/* <BackButton /> */}
            </div>
            <div className="flex flex-wrap justify-start">
              <NuSelect
                label="Project"
                options={projectList || []}
                formik={formik}
                placeholder="Choose"
                name="projectId"
                width="w-full md:w-1/3"
                isRequired={true}
                disabled={true}
              />
              <NuInputNumber
                label="Requested Amount"
                formik={formik}
                placeholder="Requested Amount"
                name="requestedAmount"
                width="w-full md:w-1/3"
                isRequired={true}
              />

              <NuInputNumber
                label="Approved Amount"
                formik={formik}
                placeholder="Approved Amount"
                name="approvedAmount"
                width="w-full md:w-1/3"
                isRequired={true}
              />
                <NuInputText
                 label="Purpose"
                 formik={formik}
                 placeholder="purpose"
                 name="purpose"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />
              <NuDatePicker
                label="Date"
                formik={formik}
                name="date"
                width=" w-full md:w-1/3"
              />
              <NuTextArea
                label="Comments"
                placeholder="Comments"
                formik={formik}
                name="comments"
                width="w-full md:w-2/3"
              />
            </div>

            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
