import React from "react";

function Mail({ color = "#7D77B1", width = 17, height = 14 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`1 0 ${height} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.26937 4.44027L7.39437 6.94027C7.68997 7.17695 8.05736 7.3059 8.43603 7.3059C8.81471 7.3059 9.1821 7.17695 9.4777 6.94027L12.6027 4.44027M15.936 11.1069V2.77361C15.936 2.33158 15.7604 1.90766 15.4479 1.5951C15.1353 1.28254 14.7114 1.10694 14.2694 1.10694H2.6027C2.16067 1.10694 1.73675 1.28254 1.42419 1.5951C1.11163 1.90766 0.936035 2.33158 0.936035 2.77361V11.1069C0.936035 11.549 1.11163 11.9729 1.42419 12.2855C1.73675 12.598 2.16067 12.7736 2.6027 12.7736H14.2694C14.7114 12.7736 15.1353 12.598 15.4479 12.2855C15.7604 11.9729 15.936 11.549 15.936 11.1069Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // <svg xmlns="http://www.w3.org/2000/svg"
    //  width={width} height={height} 
    //  viewBox={`1 0 ${height} ${height}`}
    //  fill="none"
    //  >
    // <path d="M4.26961 4.62501L7.39461 7.12501C7.69022 7.36168 8.0576 7.49063 8.43628 7.49063C8.81495 7.49063 9.18234 7.36168 9.47795 7.12501L12.6029 4.62501M15.9363 11.2917V2.95834C15.9363 2.51631 15.7607 2.09239 15.4481 1.77983C15.1356 1.46727 14.7116 1.29167 14.2696 1.29167H2.60295C2.16092 1.29167 1.737 1.46727 1.42443 1.77983C1.11187 2.09239 0.936279 2.51631 0.936279 2.95834V11.2917C0.936279 11.7337 1.11187 12.1576 1.42443 12.4702C1.737 12.7827 2.16092 12.9583 2.60295 12.9583H14.2696C14.7116 12.9583 15.1356 12.7827 15.4481 12.4702C15.7607 12.1576 15.9363 11.7337 15.9363 11.2917Z"
    //  stroke={color} stroke-width="1.5" 
    //  stroke-linecap="round" 
    //  stroke-linejoin="round"/>
    // </svg>
  );
}

export default Mail;
