import React from "react";
import { ReactComponent as AccessDeniedSvg } from "../../assets/Images/accessDenied.svg";
import { BackButton } from "./Buttons";
import { NavLink } from "react-router-dom";

function AccessDenied() {
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center mt-4 mx-auto text-center">
        <div className="w-[50%]">
          <AccessDeniedSvg />
        </div>
        <h1 className=" text-lg font-medium my-4">
          Access Denied , You don't have permission to access this module
        </h1>
        <div className=" flex items-center gap-5">
          <NavLink to="/dashboard">
            <button
              className=" bg-[#e8505b] px-2 text-white py-1 rounded-md"
              style={{ border: "1px solid #e8505b" }}
            >
              Home
            </button>
          </NavLink>
          <BackButton />
        </div>
      </div>
    </>
  );
}

export default AccessDenied;
