import React, { lazy } from "react";
import { Route } from "react-router-dom";
import MainBidding from "../Pages/Web/Bidding/MainBidding";
import MainBids from "../Pages/Web/Bidding/Bids/MainBids";
import BidsIndex from "../Pages/Web/Bidding/Bids/BidsIndex";
import CreateBids from "../Pages/Web/Bidding/Bids/CreateBids";
import EditBids from "../Pages/Web/Bidding/Bids/EditBids";
import ViewBids from "../Pages/Web/Bidding/Bids/ViewBids";
import MainEstimation from "../Pages/Web/Bidding/Estimation/MainEstimation";
import EstimationIndex from "../Pages/Web/Bidding/Estimation/EstimationIndex";
import CreateEstimation from "../Pages/Web/Bidding/Estimation/CreateEstimation";
import EditEstimation from "../Pages/Web/Bidding/Estimation/EditEstimation";
import ViewEstimation from "../Pages/Web/Bidding/Estimation/ViewEstimation";
import CreateEstimationNew from "../Pages/Web/Bidding/Estimation/CreateEstimationNew";
import EditEstimationNew from "../Pages/Web/Bidding/Estimation/EditEstimationNew";
import EditEstimationLayout from "../Pages/Web/Bidding/Estimation/EditLayout";
import EditManPower from "../Pages/Web/Bidding/Estimation/EditComponents/ManPower";
import EditMaterialActivity from "../Pages/Web/Bidding/Estimation/EditComponents/MaterialActivity";
import EditProfitMargin from "../Pages/Web/Bidding/Estimation/EditComponents/ProfitMargin";
import EditTermsAndCondition from "../Pages/Web/Bidding/Estimation/EditComponents/TermsAndCondition";
import EditEstimateAttachments from "../Pages/Web/Bidding/Estimation/EditComponents/Attachments";
import CreateEstimationLayout from "../Pages/Web/Bidding/Estimation/CreateLayout";
import MaterialActivity from "../Pages/Web/Bidding/Estimation/Components/MaterialActivity";
import ManPowerTesting from "../Pages/Web/Bidding/Estimation/Components/ManPowerTesting";
import ProfitMargin from "../Pages/Web/Bidding/Estimation/Components/ProfitMargin";
import TearmsAndCondition from "../Pages/Web/Bidding/Estimation/Components/TearmsAndCondition";
import EstimateAttachments from "../Pages/Web/Bidding/Estimation/Components/Attachments";
import ViewEstimationLayout from "../Pages/Web/Bidding/Estimation/ViewEstimationLayout";
import ViewMaterialActivity from "../Pages/Web/Bidding/Estimation/ViewComponents/MaterialActivity";
import ViewProfitMargin from "../Pages/Web/Bidding/Estimation/ViewComponents/ViewProfitAndMargin";
import ViewAttachments from "../Pages/Web/Bidding/Estimation/ViewComponents/ViewAttachments";
import ViewTermsAndCondition from "../Pages/Web/Bidding/Estimation/ViewComponents/ViewTermsAndCondition";
import ViewManPower from "../Pages/Web/Bidding/Estimation/ViewComponents/ViewManPower";
import { Wrapper } from "../Components/Utils/roles";

function BiddingRoutes() {
  return (
    <Route path="bidding" element={ <MainBidding />}>
      <Route path="bids" element={  <MainBids />}>
        <Route index element={ < Wrapper accessKey={"bids_view"}  Component={BidsIndex} /> } />
        <Route path="create" element={ < Wrapper accessKey={"bids_create"}  Component={CreateBids} />} />
        <Route path="edit/:bidEditId" element={ < Wrapper accessKey={"bids_edit"}  Component={EditBids} /> } />
        <Route path="view/:bidViewId" element={< Wrapper accessKey={"bids_view"}  Component={ViewBids} /> } />
      </Route>
      <Route path="estimation" element={<MainEstimation />}>
        <Route index element={ < Wrapper accessKey={"estimation_view"}  Component={EstimationIndex} /> } />
        <Route path="create" element={ < Wrapper accessKey={"estimation_create"}  Component={CreateEstimationLayout} /> } >
         <Route index element={<MaterialActivity/>} />
         <Route path="manpower/:estimationCreateId" element={<ManPowerTesting/>} />
        <Route path="profit-margin/:estimationCreateId" element={<ProfitMargin/>} />
        <Route path="terms-conditions/:estimationCreateId" element={<TearmsAndCondition/>} />
        <Route path="attachments/:estimationCreateId" element={<EstimateAttachments/>} />
        </Route>
        <Route path="edit" element={ < Wrapper accessKey={"estimation_edit"}  Component={EditEstimationLayout} /> } >
        <Route path="material/:estimationEditId" element={<EditMaterialActivity/>} />
        <Route path="manpower/:estimationEditId" element={<EditManPower/>} />
        <Route path="profit-margin/:estimationEditId" element={<EditProfitMargin/>} />
        <Route path="terms-conditions/:estimationEditId" element={<EditTermsAndCondition/>} />
        <Route path="attachments/:estimationEditId" element={<EditEstimateAttachments/>} />
        </Route>
        <Route path="view" element={ < Wrapper accessKey={"estimation_view"}  Component={ViewEstimationLayout} /> } >
        <Route path="material/:estimationViewId" element={<ViewMaterialActivity/>} />
        <Route path="manpower/:estimationViewId" element={<ViewManPower/>} />
        <Route path="profit-margin/:estimationViewId" element={<ViewProfitMargin/>} />
        <Route path="terms-conditions/:estimationViewId" element={<ViewTermsAndCondition/>} />
        <Route path="attachments/:estimationViewId" element={<ViewAttachments/>} /> 
        </Route>
      </Route>
    </Route>
  );
}


export default BiddingRoutes;
