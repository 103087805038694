import React, { useState } from "react";
import ExpandableSubTask from "./SubtaskData";
import ToggleUp from "../../../../Components/Icons/ToggleUp";
import ToggleDown from "../../../../Components/Icons/ToggleDown";
import ViewMoreTask from "./viewMoreTaskPlan";
import NuDate from "../../../../Components/Utils/NuDate";
import Plus from "../../../../Components/Icons/Plus";
import { Link } from "react-router-dom";
import { Progress } from "antd";
import CreatePercentage from "./createTaskPlanPercentage";
import axiosInstance from "../../../../Services/axiosCommon";
import CreateToday from "./createTodayComplete";

const ExpandableTask = ({ taskData, getData, projectId,taskList,taskSelect,subTaskList,subTaskSelect }) => {
  const [isTaskExpanded, setIsTaskExpanded] = useState(JSON.parse(sessionStorage.getItem(`${projectId}-task-${taskData?.taskId}`))||false);
  const [percentOpen, setPercentOpen] = useState(false);

  const handleToggle = () => {
    setIsTaskExpanded(!isTaskExpanded);
    sessionStorage.setItem(`${projectId}-task-${taskData?.taskId}`,!isTaskExpanded);
  };

  function DateDiff(createDate, expiryDate) {
    createDate = new Date(createDate);
    expiryDate = new Date(expiryDate);
    let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays == 0 ? diffDays + 1 : diffDays + 1;
  }

  const userAccess = JSON.parse(localStorage.getItem("userAccess"));
  let MySubTaskCreate = userAccess
    ? userAccess.some(
        (permission) => permission.permissionName == "MySub_task_create"
      )
    : false;

function updateRead(id){
  axiosInstance.put(`Task/isRead/${id}`,{
    isRead:1
  }).then(res=>{

  }).catch(err=>{
    console.log("err");
  })
}

  return (
    <>
    {/* bg-[#f8f8fb] */}
      <tr className={`w-[100%] bg-white `} style={{borderBottom:'1px solid #c7dfe0'}}>
        <td className="  w-[3%]    text-center overflow-hidden">
          <div className=" w-full h-full   flex justify-center items-center">
            <div className=" w-[33.33%]  h-10 flex justify-start items-center">
            {
            taskData?.isRead == false && <div className=" w-1 h-9 bg-[#1292DC]  shadow"></div>
             }
            </div>
            <div className=" w-[33.33%]  h-10 flex justify-center items-center">
            <input 
            type="checkbox" 
            checked={taskList.some(id=>id===taskData?.taskId)}
            onChange={()=>taskSelect(taskData?.taskId)}
            />
            </div>
            <div className=" w-[33.33%] h-10 flex justify-end items-center">
            {/* {taskData?.subTaskDetails && taskData?.subTaskDetails.length > 0 && (
            <div className=" w-3 h-6 rounded-md cursor-pointer " onClick={handleToggle}>
              {isTaskExpanded ? <ToggleUp /> : <ToggleDown />}
            </div>
          )} */}
            </div>

          </div>
        </td>
        <td className=' w-[4%]  py-2 text-xs text-left'>{
        taskData?.refNo ||""
        }</td>

        <td className=" w-[26%]  py-2 text-xs text-left font-normal text-mainColor-400">
          <Link
            className="text-xs text-left font-normal text-mainColor-400 line-clamp-2"
            to={`/tasks/view/${taskData?.taskId}`}
            state={{projectId:projectId}}
            onClick={()=>updateRead(taskData?.taskId)}
          >
            {taskData?.taskName || "-"}
          </Link>
        </td>
        <td className="  w-[15%]   py-2 text-xs h-7 overflow-hidden text-left font-normal text-mainColor-400 ">
          {
                      taskData.assignedTo && taskData.assignedTo.map((i,idx)=>{
                        return (
                          <p key={idx} className="w-full">
                          {`${i?.firstName||""} ${i?.lastName||""}`}
                        </p>
                        )
                      })
          }
        </td>
        {/* <td className=" w-[10%] py-2 text-sm text-center font-normal text-mainColor-400">
          {taskData?.taskName || "-"}
        </td> */}

        <td className="  w-[8%] py-2 text-xs text-left font-normal text-mainColor-400">
          {taskData?.startDate != "None" || taskData?.startDate != " " ? (
            <NuDate value={taskData?.startDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </td>
        <td className="  w-[8%]  py-2 text-xs text-left font-normal text-mainColor-400">
          {taskData?.endDate != "None" || taskData?.endDate != " " ? (
            <NuDate value={taskData?.endDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </td>
        <td className="  w-[8%]   py-2 text-xs text-left font-normal text-mainColor-400">
          {((taskData?.startDate != "None" || taskData?.startDate != " ") &&
            (taskData?.endDate != "None" || taskData?.endDate != " ") &&
            DateDiff(taskData?.startDate, taskData?.endDate))  ||
            "0"}
                      {((taskData?.startDate != "None" || taskData?.startDate != " ") &&
            (taskData?.endDate != "None" || taskData?.endDate != " ") &&
            DateDiff(taskData?.startDate, taskData?.endDate)>=2?" days":" day" )}
        </td>
        <td className=" w-[8%]   py-2 text-xs text-left font-normal text-mainColor-400">
          {taskData?.priority || "-"}
        </td>
        <td className="  w-[8%] py-2 text-xs text-left font-normal text-mainColor-400">
          {taskData?.taskStatus || "-"}
        </td>
        <td className="  w-[7%] py-2 text-xs text-center font-normal text-mainColor-400">
          <div className= {`w-full flex justify-center  items-center  ${taskData?.subTaskDetails && taskData?.subTaskDetails.length > 0 ? '':"cursor-pointer"}`}>
            <Progress
              onClick={() => {
                taskData?.subTaskDetails && taskData?.subTaskDetails.length > 0 ? setPercentOpen(false):setPercentOpen(true);
              }}
              type="circle"
              size={30}
              strokeWidth={12}
              format={(percent) => (
                <p
                  className={` ${
                    taskData?.taskCompletion > 95
                      ? "text-[#87d068]"
                      : taskData?.taskCompletion > 50
                      ? "text-[#108ee9]"
                      : "text-[#D2BD00]"
                  } text-xs font-semibold`}
                >
                  {percent}
                </p>
              )}
              percent={Math.floor(taskData?.taskCompletion) || 0}
              strokeColor={
                taskData?.taskCompletion > 95
                  ? {
                      "0%": "#87d068",
                      "100%": "#87d068",
                    }
                  : taskData?.taskCompletion > 50
                  ? {
                      "0%": "#108ee9",
                      "100%": "#108ee9",
                    }
                  : {
                      "0%": "#D2BD00",
                      "100%": "#D2BD00",
                    }
              }
            />
          </div>
        </td>
        <td className="  w-[5%] px-3 py-0">
        <div className=' w-full h-full flex items-center '>
            <div className=' w-[48%] h-10 flex items-center'>
            <ViewMoreTask data={taskData} id={taskData?.taskId} projectId={projectId} getClients={getData} />
            </div>
            <div className=' w-[48%] h-10 flex items-center'>
            {/* {MySubTaskCreate && <Link to="/tasks/subtask/create" state={{ id: taskData?.taskId,projectId:projectId }}>
              <Plus color="#3267FF" />
            </Link>} */}
            </div>
          </div>
          
        </td>
      </tr>
      {/* {isTaskExpanded &&
        taskData?.subTaskDetails.map((item, i) => (
          <ExpandableSubTask subTaskData={item || ""} key={i} getData={getData} projectId={projectId} subTaskList={subTaskList} subTaskSelect={subTaskSelect} />
        ))} */}

        {
          taskData?.isInstEnabled == true ? 
          
          <CreateToday
          projectId={projectId}
          open={percentOpen}
          onCancel={() => {
            setPercentOpen(false);
          }}
          taskId={taskData?.taskId}
          getData={getData}
          list={[]}
          data={taskData?.taskCompletion}
        />
          
          :
          <CreatePercentage
          projectId={projectId}
          open={percentOpen}
          onCancel={() => {
            setPercentOpen(false);
          }}
          taskId={taskData?.taskId}
          getData={getData}
          list={[]}
          data={taskData?.taskCompletion}
        />
        }

    </>
  );
};

export default ExpandableTask;
