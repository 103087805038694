import React, { lazy } from "react";
import { Route } from "react-router-dom";
import MainPayments from "../Pages/Web/Payments/MainPayments";
import MainInvoiceIn from "../Pages/Web/Payments/InvoiceIn/MainInvoiceIn";
import InvoiceInIndex from "../Pages/Web/Payments/InvoiceIn/InvoiceInIndex";
import CreateInvoiceIn from "../Pages/Web/Payments/InvoiceIn/CreateInvoiceIn";
import EditInvoiceIn from "../Pages/Web/Payments/InvoiceIn/EditInvoiceIn";
import ViewInvoiceIn from "../Pages/Web/Payments/InvoiceIn/ViewInvoiceIn";
import MainInvoiceOut from "../Pages/Web/Payments/InvoiceOut/MainInvoiceOut";
import InvoiceOutIndex from "../Pages/Web/Payments/InvoiceOut/InvoiceOutIndex";
import CreateInvoiceOut from "../Pages/Web/Payments/InvoiceOut/CreateInvoiceOut";
import EditInvoiceOut from "../Pages/Web/Payments/InvoiceOut/EditInvoiceOut";
import ViewInvoiceOut from "../Pages/Web/Payments/InvoiceOut/ViewInvoiceOut";
import MainPattyCash from "../Pages/Web/Payments/PattyCash/MainPattyCash";
import PattyCashIndex from "../Pages/Web/Payments/PattyCash/PattyCashIndex";
import CreatePattyCash from "../Pages/Web/Payments/PattyCash/CreatePattyCash";
import EditPattyCash from "../Pages/Web/Payments/PattyCash/EditPattyCash";
import ViewPattyCash from "../Pages/Web/Payments/PattyCash/ViewPattyCash";
import { Wrapper } from "../Components/Utils/roles";

function PaymentsRoutes() {
  return (
    <Route path="payments" element={<MainPayments />}>
      <Route path="invoice-in" element={<MainInvoiceIn />}>
        <Route index element={< Wrapper accessKey={true}  Component={InvoiceInIndex} /> } />
        <Route path="create" element={ < Wrapper accessKey={true}  Component={CreateInvoiceIn} /> } />
        <Route path="edit/:invoiceInEditId" element={ < Wrapper accessKey={true}  Component={EditInvoiceIn} /> } />
        <Route path="view/:invoiceInViewId" element={ < Wrapper accessKey={true}  Component={ViewInvoiceIn} /> } />
      </Route>
      <Route path="invoice-out" element={<MainInvoiceOut />}>
        <Route index element={ < Wrapper accessKey={true}  Component={InvoiceOutIndex} /> } />
        <Route path="create" element={ < Wrapper accessKey={true}  Component={CreateInvoiceOut} /> } />
        <Route path="edit/:invoiceOutEditId" element={ < Wrapper accessKey={true}  Component={EditInvoiceOut} /> } />
        <Route path="view/:invoiceOutViewId" element={ < Wrapper accessKey={true}  Component={ViewInvoiceOut} /> } />
      </Route>
      <Route path="petty-cash" element={<MainPattyCash />}>
        <Route index element={ < Wrapper accessKey={true}  Component={PattyCashIndex} /> } />
        <Route path="create" element={ < Wrapper accessKey={true}  Component={CreatePattyCash} /> } />
        <Route path="edit/:pattyCashEditId" element={ < Wrapper accessKey={true}  Component={EditPattyCash} /> } />
        <Route path="view/:pattyCashViewId" element={ < Wrapper accessKey={true}  Component={ViewPattyCash} />} />
      </Route>
    </Route>
  );
}

export default PaymentsRoutes;
