import React from "react";

function Vendor({ color = "white", width = 13, height = 12 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1326 0.73592C11.0256 0.558634 10.8748 0.41189 10.6947 0.309843C10.5146 0.207796 10.3112 0.153888 10.1041 0.15332H3.58335C3.16455 0.15332 2.77035 0.37652 2.55495 0.73592L0.92955 3.44432C0.873304 3.53757 0.843633 3.64442 0.84375 3.75332C0.846147 4.33245 1.05945 4.89087 1.44375 5.32412V10.3533C1.44375 11.0151 1.98195 11.5533 2.64375 11.5533H11.0437C11.7055 11.5533 12.2437 11.0151 12.2437 10.3533V5.32412C12.6281 4.89087 12.8414 4.33245 12.8437 3.75332C12.8439 3.64442 12.8142 3.53757 12.7579 3.44432L11.1326 0.73592ZM11.6341 3.90392C11.5971 4.19361 11.4559 4.45991 11.2368 4.65303C11.0177 4.84616 10.7358 4.95291 10.4437 4.95332C9.78195 4.95332 9.24375 4.41512 9.24375 3.75332C9.24375 3.71252 9.22875 3.67652 9.22035 3.63812L9.23235 3.63572L8.77575 1.35332H10.1041L11.6341 3.90392ZM6.13515 1.35332H7.55175L8.03955 3.79232C8.01855 4.43552 7.49175 4.95332 6.84375 4.95332C6.19575 4.95332 5.66895 4.43552 5.64795 3.79232L6.13515 1.35332ZM3.58335 1.35332H4.91175L4.45575 3.63572L4.46775 3.63812C4.45875 3.67652 4.44375 3.71252 4.44375 3.75332C4.44375 4.41512 3.90555 4.95332 3.24375 4.95332C2.9517 4.95291 2.66979 4.84616 2.45072 4.65303C2.23164 4.45991 2.09038 4.19361 2.05335 3.90392L3.58335 1.35332ZM5.64375 10.3533V8.55332H8.04375V10.3533H5.64375ZM9.24375 10.3533V8.55332C9.24375 7.89152 8.70555 7.35332 8.04375 7.35332H5.64375C4.98195 7.35332 4.44375 7.89152 4.44375 8.55332V10.3533H2.64375V6.06812C2.83635 6.11792 3.03495 6.15332 3.24375 6.15332C3.58444 6.1535 3.92124 6.08096 4.23165 5.94055C4.54206 5.80014 4.81893 5.5951 5.04375 5.33912C5.48355 5.83832 6.12795 6.15332 6.84375 6.15332C7.55955 6.15332 8.20395 5.83832 8.64375 5.33912C8.86857 5.5951 9.14544 5.80014 9.45585 5.94055C9.76626 6.08096 10.1031 6.1535 10.4437 6.15332C10.6525 6.15332 10.8511 6.11792 11.0437 6.06812V10.3533H9.24375Z"
        fill={color}
      />
    </svg>
  );
}

export default Vendor;
