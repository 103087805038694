import React from "react";
import MobileTitleData from "./TitleData";

function MobileTaskPlan({ Data, getData, projectList, projectId }) {
  return (
    <div className=" w-full  h-[70vh] overflow-y-auto">
      {Data &&
        Data.map((item, idx) => {
          return (
            <MobileTitleData rowData={item} key={idx} getData={getData} projectList={projectList} projectId={projectId} />
          );
        })}
    </div>
  );
}

export default MobileTaskPlan;
