import { useState, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import {notification } from 'antd';

import axios from '../../../../Services/axiosCommon'
import {
    NuDatePicker,
    NuTimePicker,
    NuInputNumber,
    NuInputText,
    NuLabel,
    NuSelect,
    NuTextArea,
    NuMultiSelect
  } from '../../../../Components/Utils/NuInput';
  import * as yup from 'yup'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";

const EditSubTask= ({}) =>{
  const location = useLocation();
  const id =location.state.data;
  const projectId = location.state.projectId
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState({ preview: '', raw: '' });
  const [members,setMembers] =useState([]);
  const [membersAll,setMembersAll] =useState([]);
  const [team,setTeam] = useState([]);
  const [isLoadingAllmembers,setLoadingAllmembers] = useState(false);
  const [isLoadingMembers,setLoadingMembers] = useState(false);
  const [isLoadingTeams,setLoadingTeams] = useState(false);
  const [priority,setPriority] = useState([]);
  const [severity,setSeverity] = useState([]);
  const [taskStatus,setTaskStatus] = useState([]);
  const [data,setData] = useState([]);
  const [createNew, setCreateNew] = useState({
    taskId: '',
    subTaskName: '',
    assignedTo: [],
    copyTo: [],
    priority: "",
    severity: "",
    subTaskStatus: "",
    startDate:"",
    endDate: "",
    reminderDate: "",
    reminderTime: "",
    attachment: "",
    subTaskDesc: "",
    reAssignedTo:[],
    subTaskTeam:"",

  })

  const getMembers =(id)=>{
    // console.log('getmember');
    axios.get(`/Team/getUserByTeamId/${id}`).then((response)=>{
     console.log("hhh",response.data.data)
     let FinalData = []
     let status = response.data.data;
     status.forEach((item) => {
      console.log("ddd",item.memberId);
       let tempObject = { value: item.userId, label: `${item.firstName} ${item.lastName}` };
       FinalData.push(tempObject);
     });
     response.data.data && setMembers(FinalData);
     setLoadingMembers(true)

  
    }).catch((error)=>{
     setLoadingMembers(true)
      console.log(error);
    });
  };


  const getMembersAll =()=>{
    // console.log('getmember');
    axios.get(`/Dashboard/teamMembers/${projectId}`).then((response)=>{
     console.log("hhh",response.data.data)
     let FinalData = []
     let status = response.data.data;
     status.forEach((item) => {
      console.log("ddd",item.memberId);
       let tempObject = { value: item.memberId, label: item.name };
       FinalData.push(tempObject);
     });
     response.data.data && setMembersAll(FinalData);
     setLoadingAllmembers(true)

  
    }).catch((error)=>{
     setLoadingAllmembers(true)
      console.log(error);
    });
  };


  const getTeam =()=>{
    // console.log('getmember');
    axios.get(`/Team/byProjectId/${projectId}`).then((response)=>{
    //  console.log("hhh",response.data.data)
     let FinalData = []
     let status = response.data?.data?.teamsList;
     status.forEach((item) => {
      // console.log("ddd",item.memberId);
       let tempObject = { value: item.teamId, label: item.teamName };
       FinalData.push(tempObject);
     });
     response.data.data && setTeam(FinalData);
     setLoadingTeams(true)

  
    }).catch((error)=>{
     setLoadingTeams(true)
      console.log(error);
    });
  };

  const getTaskPriroty = async ()=>{
    await axios.get('/Metadata/taskpriority').then((response)=>{
     console.log(response.data?.data?.keyValues)
     let FinalData = []
     let status = response.data?.data?.keyValues;
     status.forEach((item) => {
       let tempObject = { value: item, label: item };
       FinalData.push(tempObject);
     });
     response.data?.data && setPriority(FinalData);
 
    }).catch((error)=>{console.log(error);})
  }

  const getTaskSeverity = async ()=>{
    await axios.get('/Metadata/taskseverity').then((response)=>{
     console.log(response.data?.data?.keyValues)
     let FinalData = []
     let status = response.data?.data?.keyValues;
     status.forEach((item) => {
       let tempObject = { value: item, label: item };
       FinalData.push(tempObject);
     });
     response.data?.data && setSeverity(FinalData);
 
    }).catch((error)=>{console.log(error);})
  }

  const getTaskStatus = async ()=>{
    await axios.get('/Metadata/taskstatus').then((response)=>{
     console.log(response.data?.data?.keyValues)
     let FinalData = []
     let status = response.data?.data?.keyValues;
     status.forEach((item) => {
       let tempObject = { value: item, label: item };
       FinalData.push(tempObject);
     });
     response.data?.data && setTaskStatus(FinalData);
 
    }).catch((error)=>{console.log(error);})
  }

  function getData(){
    axios.get(`SubTask/${id}`).then((response)=>{
      console.log('aaaaaa',response.data.data);
      let data = response.data.data;
      response.data.data && setCreateNew({
        taskId: data?.taskId,
        subTaskName: data?.subTaskName,
        subTaskTeam: data?.subTaskTeam,
        assignedTo: data?.assignedDetails?.AssignedTo?.map((i)=>{
          return(
            i?.userId
          )
        }),
        copyTo: data?.assignedDetails?.CopyTo?.map((i,k)=>{
          return(
            i?.userId
          )
        }),
        priority: data?.priority,
        severity: data?.severity,
        subTaskStatus: data?.subTaskStatus,
        startDate: data?.startDate,
        endDate: data?.endDate,
        reminderDate: data?.reminderDate,
        reminderTime: data?.reminderTime,
        attachment: data?.attachment,
        subTaskDesc: data?.subTaskDesc,
        reAssignedTo: data?.reAssignedTo
      })
    })
  }


  useEffect(()=>{
    getData();
    getTeam()
    // getMembers();
    getMembersAll();
    getTaskPriroty();
    getTaskSeverity();
    getTaskStatus();
  },[]);
  
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          subTaskName: yup
            .string()
            .required("Task Name is Required"),
          // assignedTo: yup.array()
          //  .of(yup.string()
          //  .required("AssignedTo is Required")),
          // copyTo: yup.array()
          //  .of(yup.string()
          //  .required("AssignedTo is Required")),
          startDate: yup
           .date()
           .required("Start Date is Required"),
          endDate: yup
           .date()
           .required("Due Date is Required"),
          subTaskTeam:yup.string().required("Please Select Team")
          // reminderDate: yup
          //  .string()
          //  .required("reminderDate is Required"),
          // reminderTime: yup
          //  .string()
          //  .required("reminderTime is Required"),
        }),
        onSubmit: async (value,{resetForm}) => {
          setIsLoading(true)
          let formData = new FormData();
          if(value.reminderDate=="None"){
            value.reminderDate=""
          } 
          if(value.reminderTime=="None"){
            value.reminderTime=""
          } 
          // value.taskId = id;
          value.reAssignedTo =[]

           formData.append('subTaskData', JSON.stringify(value));
           formData.append('attachment', image.raw?image.raw:'');
          data
            ? axios
                .put(`/SubTask/${id}`, formData)
                .then((res) => {
                  if(res.status === 201||res.status === 200) {
                    setIsLoading(false)
                  navigate(-1)
                  // getData();
                  api.success({
                    message: `SubTask Updated successfully`,
                    // description:'',
                    placement: 'bottomRight',
                  });
                } else{
                  setIsLoading(false)
                  api.error({
                      message: `SubTask Update failed`,
                      description:res.data.msg||'Network error',
                      placement: 'bottomRight',
                    });
              }
                })
                .catch((err) => {
                  setIsLoading(false)
                  api.error({
                    message: `SubTask Update failed`,
                    description:err.response.data.msg||'Network error',
                    placement: 'bottomRight',
                  });
                })
            : 
            axios
                .post('/SubTask/', formData)
                .then((res) => {
                  if(res.status === 200||res.data.status === true) {
                  setIsLoading(false)
                  console.log(res);
                  resetForm();
                  api.success({
                    message: `SubTask  Created successfully`,
                    // description:'',
                    placement: 'bottomRight',
                  });
                } else{
                  setIsLoading(false)
                  api.error({
                      message: `SubTask Created failed`,
                      description:res.data.msg||'Network error',
                      placement: 'bottomRight',
                    });
              }
                })
                .catch((error) => {
                  setIsLoading(false)
                  api.error({
                    message: `SubTask  Created failed`,
                    description: error.data.msg || 'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
                });
        },
      });
      function diff_weeks(dt2, dt1) 
      {
     
       var diff =(dt2.getTime() - dt1.getTime()) / 1000;
       diff /= (60 * 60 * 24 * 7);
      //  let result = Math.abs(Math.round(diff));
       return  Math.abs(Math.round(diff));
       
      }
      function DateDiff(createDate,expiryDate){
        // createDate = new Date(createDate);
        // expiryDate = new Date(expiryDate);
       let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
       let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
       return diffDays==0?diffDays+1:diffDays+1;
     }
          useMemo(()=>{
            if(formik.values.startDate&&formik.values.endDate){
             let startDate = new Date(formik.values.startDate);
             let endDate = new Date(formik.values.endDate);
             formik.values.noOFWeeks= diff_weeks(startDate,endDate);
             formik.values.noOFDays =DateDiff(startDate,endDate);
             
            }
          },[formik.values.startDate,formik.values.endDate])

          useMemo(()=>{
            getMembers(formik.values.subTaskTeam)
            // formik.values.assignedTo = []
          },[formik.values.subTaskTeam])
      return(
        <div className='px-4 py-2'>
        {contextHolder}
        <BreadCrumb items={[{title:(
          <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
        )},{title:(<p className=' text-[12px] '>Sub Tasks</p>)},{title:(<p className=' text-[12px] '>Create</p>)}]} />
              {
        (isLoadingAllmembers == true && isLoadingMembers == true && isLoadingTeams) ? (
          <div className='w-full h-[85vh] overflow-y-scroll '>
          <form onSubmit={formik.handleSubmit}>
            <div className=' w-full flex flex-wrap rounded-lg' style={{ border: "1px solid #ECEBF3" }}>
              <div className=' w-full'>
                <NuLabel label='Sub Task Details' />
              </div>
            <NuInputText isRequired={true} label='Sub Task Name' placeholder='Sub Task Name' formik={formik} name='subTaskName' width=' w-full md:w-6/12' />
            <NuSelect
              label='Team'
              options={team}
              formik={formik}
              placeholder='Choose'
              name='subTaskTeam'
              width='w-full md:w-3/12'
              isRequired={true}
            />
            <NuMultiSelect
                label='Assignee'
                options={members}
                formik={formik}
                placeholder='Choose'
                name='assignedTo'
                width=' w-full md:w-3/12'
              />
              <NuMultiSelect
                label='Copy To'
                options={membersAll}
                formik={formik}
                placeholder='Choose'
                name='copyTo'
                width=' w-full md:w-3/12'
              />
              <NuDatePicker isRequired={true} label='Start Date' formik={formik} name='startDate' width=' w-full md:w-1/4'/>
              <NuDatePicker isRequired={true} label='Due Date' formik={formik} name='endDate' width=' w-full md:w-1/4'/>
              <NuSelect
                label='Priority'
                options={priority}
                formik={formik}
                placeholder='Choose'
                name='priority'
                width=' w-full md:w-3/12'
              />
              {/* <NuSelect
                label='Severity'
                options={severity}
                formik={formik}
                placeholder='Choose'
                name='severity'
                width=' w-full md:w-3/12'
              /> */}
  
  
              <NuInputNumber label='No.Week' placeholder='0' disable={true} formik={formik} name='noOFWeeks' width=' w-full md:w-3/12' />
              <NuInputNumber label='No.Days' placeholder='0' disable={true} formik={formik} name='noOFDays' width=' w-full md:w-3/12' />
              <NuSelect
                label='Task Status'
                options={taskStatus}
                formik={formik}
                placeholder='Choose'
                name='subTaskStatus'
                width=' w-full md:w-3/12'
              />
            {/* <NuDatePicker label='Revised End Date' formik={formik} name='revisedEndDate' width=' w-full md:w-3/12'/> */}

            {/* <NuTextArea label='Comment' placeholder='Comment' formik={formik} name='reasonforreviseddate' width=' w-full md:w-6/12'/> */}
            <NuTextArea label="Description" placeholder="Enter Description" formik={formik} name="subTaskDesc" width=' w-full' />

            </div>
            
            {/* <div className='w-full flex flex-wrap rounded-lg my-2 'style={{ border: "1px solid #ECEBF3" }}>
              <div className=' w-full'>
            <NuLabel label='Reminder' />
              </div>
              <NuDatePicker label='Date' formik={formik} name='reminderDate' width=' w-full md:w-1/4'/>
              <NuTimePicker label='Time' formik={formik} name='reminderTime' width=' w-full md:w-1/4'/>
            </div> */}
            <div className='flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 mr-4'>
                <CancelButton handleClick={()=>navigate(-1)}/>
                <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
        ) : (<div className="W-full h-full flex justify-center items-center">Loading...</div>)
      }

      </div>
      )
}

export default EditSubTask;



