import React from "react";
import { useEffect, useState } from "react";
import {
  NuDatePicker,
  NuInputNumber,
  NuLabel,
  NuSelect,
  NuTextArea,
} from "../../../../Components/Utils/NuInput";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../../Services/axiosCommon";
import {
  BackButton,
  CancelButton,
  SubmitButton,
} from "../../../../Components/Utils/Buttons";
// import Attachement from "./Attachement";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../../Components/Icons/Dasboard";
import * as yup from "yup";
import { notification } from "antd";
import NuDate from "../../../../Components/Utils/NuDate";



export default function CreateMaterialExpense({ data }) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [projectList,setProjectList] =useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    materialId:"",
    date: NuDate({value:new Date(),format:"YYYY-MM-DD"}) ,
    amount:0,
    comments:""
  });





  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object({
      // manPowerId: yup.string().required("Man Power is Required"),
      // plannedHours: yup.string().required("Planned Hours is Required"),
    }),
    onSubmit: async (value) => {
      setIsLoading(true);
      alert("sss")
      data
        ? axios
            .put(`/material_expense/${data?.id}`, value)
            .then((res) => {
              if (res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            })
        : axios
            .post("/material_expense/", value)
            .then((res) => {
              if (res.data.status == true) {
                setIsLoading(false);
                api.success({
                  message: `Add successfully`,
                  description: res?.data?.msg || " created",
                  placement: "bottomRight",
                });
                navigate(-1);
              } else {
                setIsLoading(false);
                api.error({
                  message: `Unsuccessfully`,
                  description: res?.data?.msg || "creation failed",
                  placement: "bottomRight",
                });
              }
            })
            .catch((err) => {
              setIsLoading(false);
              api.error({
                message: `Unsuccessfully`,
                description: err?.response?.data?.msg || "creation failed",
                placement: "bottomRight",
              });
            });
    },
  });


  async function getProject() {
    await axios
      .get(`/DropDown/getAllManPower`)
      .then(async (res) => {
        console.log("ddd", res?.data);
        let list = res?.data?.data || [];
        let finalData = [];
        (await res?.data?.data) &&
          list.map((item, idx) => {
            finalData.push({
              value: item.id,
              label: item.name,
            });
          });
        setProjectList(finalData);

        // await res?.data?.Project && setTotalProject(res?.data?.totalProject);
        // console.log('====',res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getProject()
  },[])

  return (
    <div className="w-full mx-auto py-2 px-4 ">
      {contextHolder}
      <div className="md:flex justify-between w-full">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dasboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            {
              title: (
                <Link to="/mainNotes">
                  <p className=" text-[12px] ">Material Expense</p>
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Create</p> },
          ]}
        />

        <div className=" flex gap-2 items-center mr-6">
          <BackButton />
        </div>
      </div>
      <div>
        <div
          className=" w-full mx-auto h-[85%] rounded-[6px] mt-2"
          style={{ border: "1px solid #C5C5C5" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full flex justify-between items-center">
              <NuLabel label="Material Expense" />
              {/* <BackButton /> */}
            </div>
            <div className="flex flex-wrap justify-between">

                <NuSelect
                  label="Material"
                  options={projectList||[]}
                  formik={formik}
                  placeholder="Choose"
                  name="materialId"
                  width="w-full md:w-1/3"
                  isRequired={true}
                />
                <NuDatePicker
                 label="Date"
                 formik={formik}
                 placeholder="Planned Hours"
                 name="date"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />
                <NuInputNumber
                 label="Amount"
                 formik={formik}
                 placeholder="Today Hours"
                 name="amount"
                 width="w-full md:w-1/3"
                 isRequired={true}
                />      

                <NuTextArea
                  label="Comments"
                  placeholder="Comments"
                  formik={formik}
                  name="comments"
                  width="w-full md:w-1/2"
                />



            </div>
            <div className="flex justify-end item-end float-right mx-auto md:mx-0 gap-2 pb-4 mt-6 md:mr-4">
              <CancelButton handleClick={() => navigate(-1)} />
              <SubmitButton isLoading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
