import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ViewMoreSubTask from '../../Web/Tasks/Components/viewMoreSubtask'
import NuDate from '../../../Components/Utils/NuDate'
import CreateSubPercentage from '../../Web/Tasks/Components/createSubTaskPlanPersentage'
import { Progress } from 'antd'

function MobileSubTaskData({ subTaskData,projectId,getData }) {
  const [subPercentOpen,setSubPercentOpen]=useState(false)

  return (
    <div className='py-1' style={{borderTop:'.5px solid gray'}}>
    <div className=' w-full flex px-1 bg-slate-50 items-center justify-between'>
        <div className=' w-[5%] text-center '>
         <span className='px-2 py-[1px] font-semibold my-1  bg-slate-300'>
          {
            subTaskData?.subTaskNumber||""
          }
         </span>
         </div>
        <div className=' w-[70%] px-1'>
        <Link
            className="text-sm text-left font-normal text-mainColor-400 line-clamp-1"
            to={`/tasks/subtask/view/${subTaskData?.subTaskId}`}
            state={{projectId:projectId}}
          >
            {subTaskData?.subTaskName||"-"}
          </Link>
        </div>
        <div className=''>
        <ViewMoreSubTask id={subTaskData?.subTaskId} data={subTaskData} projectId={projectId}/>
        </div>
      </div>
      <div className=' w-full flex px-1 py-1 items-center text-xs justify-between'>
        <div>
            <p className=' font-semibold'>Start Date</p>
            {subTaskData?.startDate != "None" || subTaskData?.startDate != " " ? (
            <NuDate value={subTaskData?.startDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </div>
        <div>
            <p className=' font-semibold'>Due Date</p>
            {subTaskData?.endDate != "None" || subTaskData?.endDate != " " ? (
            <NuDate value={subTaskData?.endDate} format="DD-MMM-YYYY" />
          ) : (
            "-"
          )}
        </div>
        <div>
            <p className=' font-semibold'>Priority</p>
            {subTaskData?.priority || "-"}
        </div>
        <div className=' w-[10%]'>
        <Progress
        onClick={()=>{setSubPercentOpen(true)}}
                type="circle"
                size={30}
                strokeWidth={12}
                format={(percent) => <p className={` ${subTaskData?.subTaskCompletions>95?'text-[#87d068]':subTaskData?.subTaskCompletions>50?'text-[#108ee9]':'text-[#D2BD00]'} text-xs font-semibold`}>{percent}</p>}
                percent={Math.floor(subTaskData?.subTaskCompletions) || 0}
                strokeColor={ subTaskData?.subTaskCompletions>95? {
                    '0%': '#87d068',
                    '100%': '#87d068',
                  }:subTaskData?.subTaskCompletions>50? {
                    '0%': '#108ee9',
                    '100%': '#108ee9',
                  }:{
                    '0%': '#D2BD00',
                    '100%': '#D2BD00',
                  }}
              />
        </div>
      </div>
      <CreateSubPercentage
              projectId={projectId}
              open={subPercentOpen}
              onCancel={() => {
                setSubPercentOpen(false);
              }}
              taskId={subTaskData?.subTaskId}
              getData={getData}
              list={[]}
              data={subTaskData?.subTaskCompletions}
      />
    </div>
  )
}

export default MobileSubTaskData