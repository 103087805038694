import FinanceMetaData from "./Finance";

const SettingsPayments = () => {
  return (
    <div className=' w-full '>
      <div className=' w-full flex justify-between items-center'>
      <h3 className=" text-sm text-mainColor-400 font-semibold">Finance</h3>

        {/* <NormalHeading title={'Task'} /> */}
        {/* <BackButton /> */}
      </div>

      <div className='w-full flex flex-wrap justify-center md:justify-start lg:justify-between items-center'>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
               <FinanceMetaData/>
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
          {/* <TaskPriority /> */}
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
          {/* <TaskSeverity /> */}
        </div>
        <div className=' w-full md:w-1/2 lg:w-1/3'>
          {/* <ProjectCompleted /> */}
        </div>
      </div>
    </div>
  );
};

export default SettingsPayments;
