import ViewMore from "./viewMore";
import Mail from "../../../../Components/Icons/Mail";
import { TextSmallNormal } from "../../../../Components/Utils/Text";
import Phone from "../../../../Components/Icons/Phone";
import { SmallsubHeading } from "../../../../Components/Utils/Heading";
import { Link } from "react-router-dom";

const CardView = ({ width, data, getMember, id }) => {
  let { firstName, lastName, position, email, mobileNo, imageUrl,country_code } = data;
  return (
    <div className={`${width}   p-3`} >
      <div className=" w-full shadow-lg rounded-xl p-3 overflow-hidden" style={{border:'1px solid #d0d0d0'}}>
        <div className=" flex justify-between items-center">
          <div className=" flex items-center  w-full overflow-hidden gap-3">
            <div className=" w-9 h-9 rounded-full overflow-hidden capitalize text-white font-medium flex justify-center items-center bg-[#2A475E]">
              {imageUrl && imageUrl != "" ? (
                <img width="100%" src={imageUrl} alt="#" />
              ) : (
                `${(firstName && firstName.charAt(0)) || "-"}`
              )}
            </div>
            <div className=" w-[75%]">
              <Link to={`/contacts/members/profile/${id}`}>
                <p className=" text-xs font-semibold truncate w-[99%] text-gray-800">
                  {`${firstName || ""} ${lastName || ""}`}{" "}
                </p>
              </Link>
              <SmallsubHeading title={position || "-"} />
            </div>
          </div>
          <ViewMore
            // positionTestList={positionList}
            data={data}
            getMember={getMember}
            id={id}
          />
        </div>
        <div className=" w-full p-2 mt-2 bg-violet-50 rounded">
          <div className=" flex gap-3 items-center my-2">
            <div className="">
              <Mail />
            </div>
            <TextSmallNormal text={email || "-"} />
          </div>
          <div className=" flex gap-3 items-center my-2">
            <Phone />
            <TextSmallNormal text={ `+ ${country_code||""} ${mobileNo}` || "-"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardView;
