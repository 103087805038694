import { Modal, notification } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import axiosInstance from "../../../../Services/axiosCommon";
import { NuInputfile } from "../../../../Components/Utils/NuInput";


const ImportActivity = ({ open, onCancel,getData}) => {
  const [api, contextHolder] = notification.useNotification();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [isLoading, setIsLoading] = useState(false);



  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: "",
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true)
      let formData = new FormData();
      // formik.resetForm({ values: "" });
      formData.append("file", image.raw);
      axiosInstance
        .post("/Activity/import",formData)
        .then((res) => {
          console.log(res);
          if (res.status === 201 || res.data.status == true) {
            setIsLoading(false);
            getData();
            resetForm();
            api.success({
              message: `Import successfully`,
              description: res?.data?.msg || "Added in the list",
              placement: "bottomRight",
            });
            onCancel();
          } else {
            setIsLoading(false);
            api.error({
              message: `Import failed`,
              description: res?.data?.msg || "Network error",
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          api.error({
            message: `Import failed`,
            description: err?.data?.msg || "Sever error (or) Network error",
            placement: "bottomRight",
          });
        });
    },
  });


  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title={"Import Activity"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuInputfile
              label="file"
              placeholder="Title Name"
              handleChange={handleChangeImg}
              name="title_name"
              width="w-full"
              acceptType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          <button
              type="submit"
              className="w-11/12 bg-[#119DEE] py-3 rounded-lg cursor-pointer text-sm outline-none border-none hover:text-white focus:text-white text-white  mx-auto"
            >
              Import
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ImportActivity;
