import { Link } from "react-router-dom";
import { Modal, notification } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axiosInstance from "../../../../Services/axiosCommon";
import More from "../../../../Components/Icons/More";
import { GetAccess } from "../../../../Components/Utils/roles";

function ViewMore({ data, getMember, id }) {
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();

  const handleDelete = async (id, removevalue) => {
    confirm({
      title: `Do you Want to delete ${removevalue}?`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: `The ${removevalue} will be removed from the Rental Manpower List`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        axiosInstance
          .delete(`/User/${id}`)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              getMember();
              api.success({
                message: `Rental Manpower  Deleted successfully`,
                // description:'',
                placement: "bottomRight",
              });
            } else {
              api.error({
                message: `Rental Manpower Deleted failed`,
                description: response.data.msg || "Network error",
                placement: "bottomRight",
              });
            }
          })
          .catch((error) => {
            api.error({
              message: `Rental Manpower  Deleted failed`,
              description: error.data.msg || "Sever error (or) Network error",
              placement: "bottomRight",
            });
          });
      },
      onCancel() {},
    });
  };

  return (
    <>
      {contextHolder}
      <div className="text-[#344767]  relative dropdown inline-block z-50">
        <div className=" cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center">
          <span className="pt-1">
            <span className="pt-1">
              <More/>
            </span>
          </span>
        </div>
        <ul className="dropdown-menuOne bg-red-400  text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg">
          {GetAccess("rental_manpower_edit") && (
            <li className=" w-[100px] bg-black block m-0">
              <Link
                className="bg-white hover:bg-gray-100 py-2 px-2 text-black hover:text-black block whitespace-no-wrap text-xs font-medium"
                to={`/contacts/rental-man/edit/${id}`}
                state={{ data: data }}
              >
                Edit
              </Link>
            </li>
          )}
          {GetAccess("rental_manpower_delete") && (
            <li className="">
              <span
                className="bg-white cursor-pointer hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium"
                onClick={() => {
                  handleDelete(
                    data.userId,
                    `${data.firstName} ${data.lastName}`
                  );
                }}
              >
                Delete
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default ViewMore;
