import React, { useEffect, useState } from "react";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { Link } from "react-router-dom";
import { CreateButton } from "../../../../Components/Utils/Buttons";
import Dasboard from "../../../../Components/Icons/Dasboard";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import axios from "../../../../Services/axiosCommon";
import { Button, Modal } from "antd";
import { useFormik } from "formik";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import Pagination from "../../../../Components/Utils/Pagenation";
import ViewMore from "./ViewMore";
import NuDate from "../../../../Components/Utils/NuDate";
import NuAmount from "../../../../Components/Utils/NuAmount";
import PaymentsLayout from "../PaymentsLayouts";
import { NuSelect } from "../../../../Components/Utils/NuInput";
import * as yup from 'yup'
import { GetAccess } from "../../../../Components/Utils/roles";

export default function InvoiceOutIndex(id) {
  const { value: Status } = useFetchMataData(`/Metadata/noteStatus`);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [notFirst,setNotFirst]=useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [filterData, setFilterData] = useState({
    status: "",
  });
  const [afterFilter,setAfterFilter]= useState({})

  async function getNotes() {
    await axios
      .get(`/payments/?page=${pageIndex}&per_page=${dataCount}&type=outgoing`)
      .then(async (res) => {
        (await res?.data?.data) && setData(res?.data?.data);
        setTotalRows(res.data?.total_records);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }


  const [open, setOpen] = useState(false);
  useEffect(() => {
    getNotes();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterData,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({

    }),
    onSubmit: async (value, { resetForm }) => {
      setAfterFilter(value)
      setIsFilter(true)
      axios
      .get(`/payments/?page=${pageIndex}&per_page=${dataCount}&type=outgoing`,{params:{status:`${value.status}`}})
      .then((res) => {
        setFilterOpen(false)
        setData(res.data?.data);
        setTotalRows(res.data?.total_records)

      })
      .catch((error) => {
        console.log(error);
      });
    },
  });

  function SearchData(e) {
    setNotFirst(true)
    e.preventDefault();
    if (searchQuery != "") {
      axios
        .get(`/Search/notes/${searchQuery}`)
        .then((res) => {
          res.data?.data && setData(res.data?.data);
        })
        .catch((err) => console.log(err));
    } else {
      getNotes();
    }
  }

  useEffect(() => {
    if (searchQuery == "") {
      getNotes();
    }
  }, [searchQuery]);


  useEffect(() => {
      getNotes();
  }, [pageIndex,dataCount]);


  function clearFilter() {
    getNotes();
    setIsFilter(false);
    formik.resetForm();
  }

  return (
    <>
       <PaymentsLayout       
       setOpen={setOpen}
       setSearchQuery={setSearchQuery}
       SearchData={SearchData}
       isFilter={isFilter}
       setFilterOpen={setFilterOpen}
       ClearFilter={clearFilter}
       CreateAccess={GetAccess("incoming_create")}
       ImportAccess={GetAccess("incoming_create")}
       >

<div className=" w-full h-[calc(100vh-180px)] overflow-y-auto">
            <table
              className="table-auto w-full"
              style={{ border: "none", borderCollapse: "collapse" }}
            >
              <thead className=" w-full bg-[#2A475E]">
                <tr className="text-xs  font-medium  py-3">
                  {header.map((header, i) => (
                    <th
                      className={`py-2 ${header.width} px-1 font-normal ${header?.isLeft?"text-left":"text-center"} text-xs text-white`}
                      key={i}
                      style={{ border: "none", borderCollapse: "collapse" }}
                    >
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead> 
              <tbody className="">
                {data && data.map((item, i) => (
                                <tr className=" w-full " key={i}>
                                <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">{item?.serial_number||"#"}</td>
                                <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/payments/invoice-out/view/${id}`} state={{data:item}}>  {item?.project_name||"-"}</Link>
                               </td>
                                {/* <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.category||"-"}</td> */}
                                <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                <Link className='hover:text-baseColor-primary text-mainColor-400'  to={`/payments/invoice-out/view/${id}`} state={{data:item}}> {item?.invoice_no||"-"}</Link>
                                </td>
                                <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.date !="" && item?.date !="None" && <NuDate value={item?.date} format="DD-MMM-YYYY" /> ||"-"}</td>
                                <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                <NuAmount value={item?.amount||0} />
                                
                                 {/* {item?.amount||"0.0"} */}
                                </td>
                                <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                <NuAmount value={item?.receivedAmount||0} />

                                 {/* {item?.receivedAmount||"0.0"} */}
                                </td>
                                <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">
                                <NuAmount value={item?.amount - item?.receivedAmount||0} />

                                 {/* {`${item?.amount - item?.receivedAmount}`||"0.0"} */}
                                 </td>
                                <td className="py-2 px-1  text-xs text-left font-normal text-mainColor-400">{item?.status||"-"}</td>
                                <td className="py-2 px-1  text-xs text-center font-normal text-mainColor-400">
                                  <div className=" w-full flex justify-center items-center">
                                  <ViewMore
                                    id={item?.paymentId}
                                    getData={getNotes}
                                    data={item}
                                   />
                                  </div>
                                </td>
                              </tr>
                ))}
              </tbody>
            </table>
          </div>
      
      <div className=" h-6 my-2">
        <Pagination
          total={totalRows}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      </div>

       </PaymentsLayout>
       <Modal
        open={filterOpen}
        title={"Filter Payments"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={() => setFilterOpen(false)}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuSelect
              label="Status"
              options={Status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width=" w-full"
            />
          <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white  mx-auto"
            >
              Filter 
            </Button>
          </div>
        </form>
      </Modal>

    </>
  );
}

const header = [
  {
    headone: "ID",
    width: "w-[3%]",
    // isLeft: true,
  }, 
  {
    headone: "Project",
    width: "w-[15%]",
    isLeft: true,
  },
  // {
  //   headone: "Invoice Type",
  //   width: "w-[10%]",
  //   isLeft: true,
  // },
  // {
  //   headone: "Cost Per Hour",
  //   width: "w-[8%]",
  // },
  {
    headone: "Invoice #",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Date",
    width: "w-[8%]",
    isLeft: true,

  },
  {
    headone: "Total",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Paid Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Balance Amount",
    width: "w-[10%]",
    isLeft: true,

  },
  {
    headone: "Status",
    width: "w-[10%]",
    isLeft: true,
  },
  {
    headone: "Action",
    width: "w-[5%]",
  },
];