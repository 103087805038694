import React, { useEffect, useState } from 'react'
import Video from "../../../../../Components/Icons/Video";
import Doc from "../../../../../Components/Icons/Doc";
import Xls from "../../../../../Components/Icons/Xls";
import Txt from "../../../../../Components/Icons/Txt";
import Pdf from "../../../../../Components/Icons/Pdf";
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../Services/axiosCommon';

function ViewAttachments() {
    const { estimationViewId } = useParams();
    let estId = Number(estimationViewId)
    const [attachments, setAttachments] = useState([])

    useEffect(() => {
      getAttachments(estId)
    },[estId])

    


    function getAttachments(id){
      axiosInstance.get(`/estimation/attachments/${id}`,).then((res) => {
          setAttachments(res.data.data);
      }).catch((err) => {
          console.log("error",err);
          
      })
    }
  const videotype = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];

  return (
    <div className='mt-2 w-full h-[calc(94vh-240px)] flex justify-start gap-3 flex-wrap'>
            {
                attachments &&
                attachments.map((file, idx) => {
                    console.log("ss", file);
                    let Ext = file.split(".").reverse()[0];
                    return (
                      <div
                        className=" w-20 h-20 my-2 rounded overflow-hidden"
                        key={idx}
                      >
                        {videotype.includes(Ext) ? <Video /> : null}
      
                        {Ext == ("docx" || "doc" || "txt") ? (
                          <Doc />
                        ) : Ext == ("xlsx" || "xls") ? (
                          <Xls />
                        ) : Ext == "txt" ? (
                          <Txt />
                        ) : Ext == "pdf" ? (
                          <Pdf />
                        ) : Ext == "pptx" ? (
                          <>No file</>
                        ) : (
                          <img
                            src={file}
                            alt="#"
                            width="100%"
                            className="object-contain"
                          />
                        )}
                      </div>
                    );
                  })}
            
        {/* {image &&
            image.map((file, idx) => {
              console.log("ss", file);
              let Ext = file.split(".").reverse()[0];
              return (
                <div
                  className=" w-20 h-20 my-2 rounded overflow-hidden"
                  key={idx}
                >
                  {videotype.includes(Ext) ? <Video /> : null}

                  {Ext == ("docx" || "doc" || "txt") ? (
                    <Doc />
                  ) : Ext == ("xlsx" || "xls") ? (
                    <Xls />
                  ) : Ext == "txt" ? (
                    <Txt />
                  ) : Ext == "pdf" ? (
                    <Pdf />
                  ) : Ext == "pptx" ? (
                    <>No file</>
                  ) : (
                    <img
                      src={file}
                      alt="#"
                      width="100%"
                      className="object-contain"
                    />
                  )}
                </div>
              );
            })} */}
        </div>
  )
}

export default ViewAttachments