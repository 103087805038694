import { Link, Outlet, useLocation } from "react-router-dom"
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dasboard from "../../../Components/Icons/Dasboard";
import { useEffect, useState } from "react";
import SearchBar from "../../../Components/Utils/SearchBar";
import { CreateButton } from "../../../Components/Utils/Buttons";
import { Select } from "antd";
import { GetAccess } from "../../../Components/Utils/roles";


const ContactsLayout = (
    {
        SearchData=() => {},
        setSearchQuery=()=>{},
        CreateAccess= false,
        ImportAccess= false,
        setOpen=()=>{},
        children,
        handleSearch=()=>{},
        searchBy=""
    }
) =>{
    let {pathname} = useLocation()
    const [module,setModule] = useState("")
    
    
    let searchList = [
        { value: "all", label: "All" },
        { value: "services", label: "Services" },
        { value: "products", label: "Products" },
      ];

    useEffect(() =>{
        if(pathname == "/contacts/members"){
            setModule("Employees")
        }else if(pathname == "/contacts/rental-man"){
            setModule("Rental Manpower")
        }else if(pathname == "/contacts/clients"){
            setModule("Client")
        }else if(pathname == "/contacts/vendor"){
            setModule("Vendor")
        }else if(pathname == "/contacts/sub-con"){
            setModule("Sub-Contractor")
        }
    },[pathname])

    let LinkItem = [
        {
            label:"Employees",
            link:"/contacts/members",
            access:GetAccess("employees_view"),
        },
        {
            label:"Rental Manpower",
            link:"/contacts/rental-man",
            access:GetAccess("rental_manpower_view"),
        },
        {
            label:"Vendors",
            link:"/contacts/vendor",
            access:GetAccess("vendor_view"),
        },
        {
            label:"Clients",
            link:"/contacts/clients",
            access:GetAccess("client_view"),
        },
        {
            label:"Sub-Contractor",
            link:"/contacts/sub-con",
            access:GetAccess("sub_contractor_view"),
        },
    ]
    
    return(
        <div className=" w-full h-full py-2 px-4 ">
            <div className="">
            <div className="md:flex justify-between w-full">
            <BreadCrumb items={[{title:(
             <Link to='/dashboard'><Dasboard color='gray' width={10} height={10} /> </Link> 
            )},{title:(<p className=' text-[12px] '>Contact</p>)},
            {title:(<p className=' text-[12px] '>{module}</p>)}
            ]} />

            <div className=" flex justify-end items-center gap-3 mr-6">
            <form className="flex items-center gap-3 mx-0" onSubmit={SearchData}>
                {
                    module  && ( module == "Vendor" || module == "Sub-Contractor") && (
                        <Select
                        style={{ width: "105px", fontSize: "12px" }}
                        options={searchList}
                        value={searchList.find((i) => i.value == searchBy) || ""}
                        onChange={handleSearch}
                      />
                    )
                }
              <SearchBar handleChange={(e) => setSearchQuery(e.target.value)} />
              </form>
              {ImportAccess && <CreateButton label="Import" handleClick={()=>setOpen(true)}    />}
              {CreateAccess && <Link to={`${pathname}/create`}><CreateButton label={module}/></Link>}
                  {/* <BackButton/> */}
                </div>
            </div>
           
            <div className=" w-full h-8 flex items-center gap-x-4 ">
            {
                LinkItem && LinkItem.map((item, i) => {
                    return(
                        <>
                         {
                            item.access && (
                                <Link to={item?.link} style={item?.link == pathname ?{borderBottom:"4px solid #119DEE"}:{borderBottom:"4px solid transparent"}} className={` px-0 pb-1 text-[13px] font-medium text-mainColor-400 hover:text-mainColor-400 ${item?.link == pathname ?"border-b-4 border-red":""}   `}  key={item?.link} >{item?.label||""}</Link>
                            )
                         }
                        </>
                    )
                })
            }
        </div>
           
            <div className=" w-full h-[calc(100vh-140px)]">
               {children} 
            </div>
            </div>
        </div>
    )
}


export default ContactsLayout;