
import { Link } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal ,notification} from "antd";
import More from '../../../../Components/Icons/More';
import { GetAccess } from '../../../../Components/Utils/roles';

const ViewMore = ({ data, getData,id }) => {
  const { confirm } = Modal;
  const [api, contextHolder] = notification.useNotification();
  const handleDelete = async(id,removevalue) =>{
    confirm({
        title: `Do you Want to delete ${removevalue}?`,
        centered:true,
        icon: <ExclamationCircleFilled />,
        content: `The ${removevalue} will be removed from the Bid List`,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          axiosInstance.delete(`/bidding/delete_bidding/${id}`).then((res) => {
            if(res.status === 201||res.status === 200) {
                getData();
                api.success({
                  message: `Bid has deleted successfully`,
                  description:res?.data?.msg||' ',
                  placement: 'bottomRight',
                });
            } else{
                api.error({
                    message: `Bid has delete failed`,
                    description:res?.data?.msg||'Network error',
                    placement: 'bottomRight',
                  });
            }
            })
            .catch((error) => {
                api.error({
                    message: `Bid has delete failed`,
                    description:error?.response?.data?.msg||'Sever error (or) Network error',
                    placement: 'bottomRight',
                  });
            });
        },
        onCancel() {
        },
      });
}


  return (
    <>
    {contextHolder}
      <div className='text-[#344767]  relative dropdown inline-block z-50'>
        <div className=' cursor-pointer underline-offset-8 text-xs mx-auto font-semibold flex items-center'>
          <span className=' w-7 h-7 bg-gray-50 flex flex-col items-center justify-center rounded-full'>
            <More/>
          </span>
        </div>
        <ul className='dropdown-menuOne   text-[#344767] text-left  rounded-lg absolute -left-[100px] -top-4 overflow-hidden hidden  z-50 shadow-lg'>
        <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              // onClick={() => setOpen(true)}
            >
              <Link className=' text-black hover:text-black' to={`/bidding/bids/view/${id}`} state={{ data: data }} >View</Link>
            </span>
          </li>
         {GetAccess("bids_edit") && <li className=' w-[100px] bg-black block m-0'>
            <span
              className='bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              // onClick={() => setOpen(true)}
            >
              <Link className=' text-black hover:text-black' to={`/bidding/bids/edit/${id}`} state={{ data: data }} >Edit</Link>
            </span>
          </li>} 
          {
            GetAccess("bids_delete") &&
            <li className=''>
            <a
              className='bg-white hover:bg-gray-100 text-black hover:text-black py-2 px-2 block whitespace-no-wrap text-xs font-medium'
              // to='/members'
              href="#"
               onClick={() => {
                handleDelete(data.biddingId,data?.projName);
              }}
            >
              Delete
            </a>
          </li>
          }
        </ul>
      </div>
    </>
  );
};

export default ViewMore;

