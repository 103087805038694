import { Button, Modal, notification } from "antd";
import {  useMemo, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
// import axiosInstance from "../../../Services/axiosCommon";
import { NuDatePicker, NuInputfile, NuTimePicker } from "../../../../Components/Utils/NuInput";
import axiosInstance from "../../../../Services/axiosCommon";
import { useNumber } from '../../../../Context/ProjectIdContext';


const SetReminderSubTask = ({ open, getData, onCancel, subdata,firstProject }) => {

    // console.log("data",data);
  const { number } = useNumber();
    
    console.log("sub data",subdata);
  const [api, contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    taskId: '',
    subTaskName: '',
    assignedTo: [],
    copyTo: [],
    priority: "",
    severity: "",
    subTaskStatus: "",
    startDate:"",
    endDate: "",
    reminderDate: "",
    reminderTime: "",
    attachment: [],
    subTaskDesc: "",
    reAssignedTo:[],
    subTaskTeam:"",

  })

  useState(()=>{
    subdata &&
    setCreateNew({
        taskId: subdata?.taskId,
        subTaskName: subdata?.subTaskName,
        subTaskTeam: subdata?.subTaskTeamId,
        assignedTo: subdata?.assignedTo?.map((i)=>{
          return(
            i?.userId
          )
        }),
        copyTo: subdata?.copyTo?.map((i,k)=>{
          return(
            i?.userId
          )
        }),
        priority: subdata?.priority,
        severity: subdata?.severity,
        subTaskStatus: subdata?.subTaskStatus,
        startDate: subdata?.startDate,
        endDate: subdata?.endDate,
        reminderDate: subdata?.reminderDate,
        reminderTime: subdata?.reminderTime,
        attachment: subdata?.attachment||[],
        subTaskDesc: subdata?.subTaskDesc,
        reAssignedTo: [],
      })
  },[])



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      reminderDate: yup
       .string()
       .required("reminderDate is Required"),
      reminderTime: yup
       .string()
       .required("reminderTime is Required"),
    }),
    onSubmit: async (value,{resetForm}) => {
    //   setIsLoading(true);
    //   value.projectId = projectId;
    //   value.titleId = titleId;
      let formData = new FormData();
      // value.taskTeam=[value.taskTeam]
      if(value.reminderTime=="None"){
        value.reminderTime=""
      } 

      value.projectId = firstProject
      
    //   let asTo = []

       
    //   asTo.push(value.reAssignedTo)

    //    if(data){
    //     value.reAssignedTo = asTo;
    //    }else{
    //     delete value.reAssignedTo;
    //    }

      formData.append('subTaskData', JSON.stringify(value));
      formData.append('attachment', '');
      
        // console.log("finding",formData);
        
        axiosInstance
            .put(`/SubTask/${subdata?.subTaskId }`, formData)
            .then((res) => {
              if(res.status === 201||res.status === 200) {
              console.log(res);
              getData(number);
              onCancel();
              api.success({
                message: `Reminder time  Updated successfully`,
                // description:'',
                placement: 'bottomRight',
              });
            //   navigate(-1);
            } else{
            //   setIsLoading(false);
              api.error({
                  message: `Reminder time Update failed`,
                  description:res.data.msg|'Network error',
                  placement: 'bottomRight',
                });
          } 
            })
            .catch((err) => {
            //   setIsLoading(false);
              console.log(err);
            })
    },
  });

  function diff_weeks(dt2, dt1) 
  {
 
   var diff =(dt2.getTime() - dt1.getTime()) / 1000;
   diff /= (60 * 60 * 24 * 7);
  //  let result = Math.abs(Math.round(diff));
   return  Math.abs(Math.round(diff));
   
  }
  function DateDiff(createDate,expiryDate){
    // createDate = new Date(createDate);
    // expiryDate = new Date(expiryDate);
   let timeDiff = Math.abs(expiryDate.getTime() - createDate.getTime());
   let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
   return diffDays==0?diffDays+1:diffDays+1;
 }
      useMemo(()=>{
        if(formik.values.startDate&&formik.values.endDate){
         let startDate = new Date(formik.values.startDate);
         let endDate = new Date(formik.values.endDate);
         formik.values.noOFWeeks= diff_weeks(startDate,endDate);
         formik.values.noOFDays =DateDiff(startDate,endDate);
         
        }
      },[formik.values.startDate,formik.values.endDate])
  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title={ "Set Reminder"}
        width={"25%"}
        className="my-modal"
        centered
        onCancel={onCancel}
        footer={[]}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className=" w-full flex flex-wrap">
            <NuDatePicker isRequired={true} label='Date' formik={formik} name='reminderDate' width='w-full '/>
            <NuTimePicker isRequired={true} label='Time' formik={formik} name='reminderTime' width='w-full '/>
            <Button
              htmlType="submit"
              size="large"
              className="w-11/12 bg-[#119DEE] text-white text-xs  mx-auto"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SetReminderSubTask;
